import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Row, Col, Button } from "react-bootstrap";
import error500 from "../../images/errors/error500.png";
import ContactUs from "./ContactUs";
import {
    currentBranchIdVar,
    currentCompanyIdVar,
    userUsernameVar
} from '@me-team/host/main/globalVar/state';

const Page500: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const userId = useReactiveVar(userUsernameVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const basePath = process.env.REACT_APP_POS_URL;

    const handleButtonRefrech = () => {
        window.location.reload();
    };

    const handleButtonOnMain = () => {
        userId
            ? window.location.href = `${basePath}/branch/company/${currentCompanyId}/${currentBranchId}`
            : window.location.href = basePath
    };

    return (
        <>
            <Col xs={12} lg={7} xl={6} xxl={5} className="mb-5 pb-lg-5 mx-auto">
                <div className="d-flex justify-content-center align-items-center py-4">
                    <img src={error500} width="357" height="240" loading="lazy" className="img-fluid" alt="500 Error"/>
                </div>
                <h2 className="text-capitalize fw-bold text-center mb-3">{t("Server error")}</h2>
                <div className="mx-auto mx-lg-0 mb-5">
                    <p className="text-grey text-center mb-0 me-lg-4">{t("Something went wrong... But we are already working on a solution to the error. Try reloading the web page.")}</p>
                </div>
                <Row className="col-xxl-11 pb-lg-5 mx-auto">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                        <Button
                            onClick={handleButtonRefrech}
                            variant="success"
                            className="fs-7 text-white fw-bold rounded-3 w-100">{t("Refresh Page")}</Button>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Button
                            onClick={handleButtonOnMain}
                            variant="outline-success"
                            className="fs-7 text-capitalize fw-normal rounded-3 w-100">{t("Main menu")}</Button>
                    </Col>
                </Row>
            </Col>
            <ContactUs/>
        </>
    )
}

export default Page500;
