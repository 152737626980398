import { useGetBranchesQuery } from '@me-team/host/main/branches/graphql/branches.hooks';

interface BranchesFilter {
    branchTypeId?: string;
    name?: string;
}

export const useBranches = (currentPage: number, itemsPerPage: number, filter: BranchesFilter, currentCompanyId: string, currentBranchEmployeeId: number | undefined) => {
    const { data, loading, error, refetch } = useGetBranchesQuery({
        skip: !currentCompanyId || !currentBranchEmployeeId,
        variables: {
            itemsPerPage,
            page: currentPage,
            branchTypeId: filter.branchTypeId ? Number(filter.branchTypeId) : null,
            name: filter.name ? filter.name : null,
            companyId: +currentCompanyId,
            employeeId: +currentBranchEmployeeId,
        },
        onError: (error) => {
            console.error(error);
        }
    });

    return { data, loading, error, refetch };
};
