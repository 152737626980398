import React, {useState} from 'react';
import {CustomModal} from '../../../index';
import {Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {EmployeeProxy} from '@me-team/host/main/graphql/types';
import TableRow from './TableRow';
import {SelectedEmployee} from '../types';
import EmptyList from '../../../EmptyList/EmptyList';

interface AddEmployeeFromAnotherBranchModalProps {
  isShowAddEmployeeFromAnotherBranchModal: boolean;
  onCloseAddEmployeeFromAnotherBranch: () => void;
  employeesWithoutAccessCurrentBranch: EmployeeProxy[];
  onSelectedEmployeeToAdd: (selectedEmployee: SelectedEmployee) => void;
  toggleAddAccessRightsModal: () => void;
}

const AddEmployeeFromAnotherBranchModal: React.FC<AddEmployeeFromAnotherBranchModalProps> = ({
                                                                                               isShowAddEmployeeFromAnotherBranchModal,
                                                                                               onCloseAddEmployeeFromAnotherBranch,
                                                                                               employeesWithoutAccessCurrentBranch,
                                                                                               onSelectedEmployeeToAdd,
                                                                                               toggleAddAccessRightsModal
                                                                                             }) => {
  const {t} = useTranslation();
  const isEmployees = employeesWithoutAccessCurrentBranch?.length > 0;
  const [selectedEmployee, setSelectedEmployee] = useState<SelectedEmployee>(null);
  const [error, setError] = useState<string>('');

  const handleSelect = (employee: EmployeeProxy) => {
    setSelectedEmployee(prevEmployee =>
      prevEmployee?.id === employee.id ? null : {
        id: employee.id,
        name: employee.name,
        surname: employee.surname
      }
    );
    setError('');
  };

  const handleAddEmployee = () => {
    if (!selectedEmployee) {
      setError(t('You must select an employee to add'));
      return;
    }
    onSelectedEmployeeToAdd(selectedEmployee)
    toggleAddAccessRightsModal()
    setSelectedEmployee(null)
    onCloseAddEmployeeFromAnotherBranch()
  }

  return (
    <CustomModal
      show={isShowAddEmployeeFromAnotherBranchModal}
      handleClose={onCloseAddEmployeeFromAnotherBranch}
      title={
        <Container>
          <Row>
            <h5 className='m-0 p-0 fw-normal'>{t('Add an employee from another branch')}</h5>
          </Row>
        </Container>
      }
      body={
        <Container>
          <div className='px-6'>
            <Row className="fw-bold bg-violet text-dark fs-7 rounded-1">
              <Col xs={6}>
                <span className='d-flex p-2 ps-1 fw-normal'>{t('Employee')}</span>
              </Col>
              <Col xs={6}>
                <span className='d-flex p-2 fw-normal'>{t('Branch')}</span>
              </Col>
            </Row>
          </div>
          <div className='overflow-auto overflow-x-hidden px-6' style={{maxHeight: '540px'}}>
            {isEmployees ? employeesWithoutAccessCurrentBranch.map((employee) => {
              return <TableRow
                key={employee.id}
                employee={employee}
                selectedEmployeeId={selectedEmployee?.id}
                onSelect={handleSelect}
              />
            }) : <EmptyList title={t('No employees found to add')}/>}
          </div>
          {error && (
            <Row>
              <Col>
                <span className='fs-7 text-danger mt-2'>{error}</span>
              </Col>
            </Row>
          )}
        </Container>
      }
      buttons={isEmployees ? [
        {
          text: t('cancel'),
          onClick: onCloseAddEmployeeFromAnotherBranch,
          variant: 'outline-primary',
          className: 'w-100 fw-normal',
        },
        {
          text: t('add'),
          onClick: handleAddEmployee,
          variant: 'primary',
          className: 'w-100 fw-normal',
        },
      ] : []}>
    </CustomModal>
  );
};

export default AddEmployeeFromAnotherBranchModal;