import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, TableHeader } from '@services/ui-components';
import { useTranslation } from 'react-i18next';
import { Col, Card, ListGroup } from "react-bootstrap";
import { useReactiveVar } from "@apollo/client";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {getSections} from "./constans";
import { useGetBranchQuery } from '@me-team/host/main/branches/graphql/branches.hooks';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/main/globalVar/state';


const MainMenu = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const params = useParams();

    const basePath = process.env.REACT_APP_POS_URL;
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const { data: branchData, loading, error } = useGetBranchQuery({
        variables: { id: Number(params.id), companyId: +currentCompanyId },
        skip: !currentCompanyId || !params.id,
        context: {
            errorType: 'global',
        },
        onError:(error) => {
            console.error('Error getting branch query', error);
        }
    });

    const currentBranchName = branchData?.user?.company?.branchesPaginated?.branches[0]?.name;
    useDocumentTitle(currentBranchName);

    const sections = getSections({
        t,
        basePath,
        currentBranchId,
        currentCompanyId,
    });

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <>
            <TableHeader
                title={currentBranchName}
                paths={[{ path: "/branches", label: t("branches")},
                    { path: `/branches/menu/${params.id}`, label: currentBranchName }
                ]}
                colSize={12}
            />

            <div className="row justify-content mt-5">
                {sections.map((section, index) => (
                    <Col key={index} lg={6} xxl={4} className="mb-4">
                        <Card className="d-flex flex-column h-100 border rounded-3 p-4">
                            <Card.Header className="mb-4">
                                <h5 className="fs-4 mb-0">{section.title}</h5>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-center mb-4">
                                    {section.image}
                                </div>
                                <ListGroup as="ul" className="list-grid list-unstyled mb-4">
                                    {section.items.map((item, index) => {
                                        return (<ListGroup.Item as="li" className="py-2">
                                            <Link key={index} to={item.to} className="text-violet-hover text-decoration-none">{item.label}</Link>
                                        </ListGroup.Item>)
                                    })}
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer className="bg-white border-top-0 mt-auto">
                                <Link to={section.navigateTo} className="btn btn-outline-primary w-100 fw-normal rounded-1">
                                    {section.linkLabel}
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </div>
        </>
    );
};

export default MainMenu;
