import {FiltersButton, InfoBanner, TableHeader} from '@services/ui-components';
import {Button, Col, Row} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ClientsTable from './ClientsTable';
import {useNavigate} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import ReactSelect from '@services/ui-components/src/ReactSelect/ReactSelect';
import RequirementsFileInfo from "../../services/importFiles/RequirementsFileInfo";
import i18next from "i18next";
import {requirementsInfoType} from "../../interfaces/interfaces";
import {ImportFiles} from "../ImportFiles/ImportFiles";
import {ExportBtn} from "../ExportBtn/ExportBtn";
import {EntityType, UserRole} from "../../utils/enums";
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/main/globalVar/state';

interface Option {
    value: string;
    label: string;
}

enum VisitRanges {
    '1-10' = '1-10',
    '11-30' = '11-30',
    '31-50' = '31-50',
    '50+' = '50+',
}

const Clients: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userRole = useReactiveVar(currentUserRoleVar);
    const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
    const [showFilters, setShowFilters] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({search: '', amountOfVisits: ''});
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [
        clientRequirementsInfo,
        setClientRequirementsInfo
    ] = useState<requirementsInfoType>(null);

    const toggleFilters = () => setShowFilters(!showFilters);

    const transformedOptions = Object.values(VisitRanges).map((range) => ({
        value: range,
        label: range,
    }));

    const handleSelectChange = (option: Option | null) => setSelectedOption(option);
    const handleApplyFilter = () => {
        const amountOfVisitsValue = selectedOption ? selectedOption?.value : '';
        setFilter({
            amountOfVisits: amountOfVisitsValue,
            search: search,
        });
    };

    useEffect(() => {
        if (currentCompanyId) {
            setClientRequirementsInfo(RequirementsFileInfo.clientFileRequirements(parseInt(currentCompanyId), t, i18next.language));
        }
    }, [i18next.language, currentCompanyId]);

    const onOpenImportModalHandler = () => {
        setIsImportModalOpen(true);
    }
    const onCloseImportModalHandler = () => {
        setIsImportModalOpen(false)
    }

    const handleClearFilter = () => {
        setFilter({search: '', amountOfVisits: ''});
        setSelectedOption(null);
        setSearch('');
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    const handleCreateClient = () => {
        navigate(`/clients/${currentCompanyId}/new`);
    };

    return (
        <div className='mb-9'>
            <InfoBanner>
                <p className='fs-7 mb-0'>
                    {t('All your Customers are displayed on this page. When creating records, through the' +
                        ' record log or online record, new Customers are added to the customer base automatically. You can also create a new client by clicking Add client.')}
                    <i className='bi bi-people'></i>
                </p>
            </InfoBanner>

            <TableHeader
                title={t('List of Clients')}
                paths={[
                    {path: `/clients/${currentCompanyId}/list`, label: t('Customers')},
                    {path: `/clients/${currentCompanyId}/list`, label: t('List of Clients')},
                ]}
                colSize={5}
                btnColSize={7}
                buttons={
                    <Row
                        className='gx-3 flex-lg-nowrap justify-content-end mt-3 mt-lg-0'>
                        {!isEmployeeRole
                            &&
                            <>
                                <Col xs={2} xl={3}>
                                    <ExportBtn companyId={parseInt(currentCompanyId)} exportType={EntityType.CLIENTS} classes="d-none d-xl-inline-block"/>
                                </Col>
                                <Col xs={2} xl={3}>
                                    <Button
                                        variant='outline-violet'
                                        className='border-primary text-primary fw-normal d-flex align-items-center justify-content-center w-100'
                                        onClick={onOpenImportModalHandler}
                                    >
                                        <i className='bi bi-box-arrow-in-down'></i>
                                        <span className="d-none d-xl-inline-block ms-1">{t('imports')}</span>
                                    </Button>
                                </Col>
                                <Col
                                    xs={5} xl={4}>
                                    <Button
                                        variant='primary'
                                        className='w-100 fw-bold text-truncate'
                                        onClick={handleCreateClient}>
                                        <i className='bi bi-plus-lg me-1 w-100 h-100'></i>
                                        {t('Add client')}
                                    </Button>
                                </Col>
                            </>
                        }
                        <Col xs={!isEmployeeRole ? 3 : 6} xl={!isEmployeeRole ? 2 : 3}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}
                                           classesText={`${!isEmployeeRole ? "d-none d-xxl-inline-block" : "d-inline-block"}`}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}>
                <Row className='gx-3'>
                    <Col lg={8} xl={9}>
                        <Row className='gx-3'>
                            <Col
                                md={6}
                                className='text-primary'
                            >
                                <div className='position-relative mb-3 mb-lg-0'>
                                    <input
                                        id='listOfClients-search-field'
                                        type='text'
                                        className='form-control text-truncate pe-5'
                                        placeholder={t('Search by name, surname, phone or e-mail')}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <i
                                        className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-2'></i>
                                </div>
                            </Col>

                            <Col
                                md={6}
                                className='text-primary mb-3 mb-lg-0'
                            >
                                <ReactSelect
                                    id='listOfClients-byTheNumberOfVisits-field'
                                    placeholder={t('By the number of Visits')}
                                    options={transformedOptions}
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                    isSearchable={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} xl={3}>
                        <Row className='gx-3 h-100'>
                            <Col
                                xs={6}
                                className='mb-3 mb-lg-0'>
                                <button
                                    type='button'
                                    className='btn btn-outline-dark border-grey w-100 fw-normal'
                                    onClick={handleClearFilter}>
                                    {t('clear')}
                                </button>
                            </Col>
                            <Col
                                xs={6}
                                className='mb-3 mb-lg-0'>
                                <button
                                    className='btn btn-primary w-100 px-1'
                                    onClick={handleApplyFilter}>
                                    {t('apply')}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>
            <ClientsTable filter={filter}/>
            {clientRequirementsInfo &&
                <ImportFiles currentRequirementsInfo={clientRequirementsInfo} isOpen={isImportModalOpen}
                             onClose={onCloseImportModalHandler}/>}

        </div>
    );
};

export default Clients;
