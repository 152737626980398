import React, {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {ReactSelect} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {CalendarFilterI, Option, renderingOptionServices} from './constants';
import {
  useGetBookingStatusForLogbookQuery,
  useGetEmployeePositionsQuery,
  useGetEmployeesForLogbookQuery,
  useGetServicesForLogbookQuery
} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {
  BookingStatusProxy,
  EmployeePositionProxy,
  EmployeeProxy,
  ServiceProxy
} from '@me-team/host/main/graphql/types';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {UserRole} from '../../utils/enums';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/main/globalVar/state';

interface CalendarFilterWrapperProps {
  setFilters: React.Dispatch<React.SetStateAction<CalendarFilterI>>;
}

const CalendarFilterWrapper: React.FC<CalendarFilterWrapperProps> = ({setFilters}) => {
  const {t, i18n} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedEmployeePosition, setSelectedEmployeePosition] = useState(null);

  const {
    data: bookingStatusesData,
    loading: bookingStatusesLoading
  } = useGetBookingStatusForLogbookQuery({
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  });
  const {
    data: employeePositionsData,
    loading: employeePositionsLoading
  } = useGetEmployeePositionsQuery({
    variables: {
      itemsPerPage: 1000,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const {data: servicesData, loading: servicesLoading} = useGetServicesForLogbookQuery({
    variables: {
      onlyFromBookings: false,
      itemsPerPage: 1000,
      companyId: +currentCompanyId
    },
    skip: !currentCompanyId,
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  });

  const {data: employeesData, loading: employeesLoading} = useGetEmployeesForLogbookQuery({
    variables: {
      onlyActive: true,
      itemsPerPage: 1000,
      filterByNameAndPosition: true,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const statusesOptions = bookingStatusesData?.bookingStatus?.slice(0, -1).map((status: BookingStatusProxy) => ({
    label: t(status?.name),
    value: status?.id,
  })) || [];

  const employeePositionsOptions = employeePositionsData?.user?.company?.employeePositions?.employeePositions?.map((position: EmployeePositionProxy) => {
    return {
      value: position?.id,
      label: position?.name,
    }
  }) || [];

  const servicesOptions = servicesData?.user?.company?.services?.services?.map((service: ServiceProxy) => {
    return {
      value: service?.id,
      label: renderingOptionServices(service),
    }
  }) || [];

  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee: EmployeeProxy) => {
    return {
      value: employee?.id,
      label: `${employee?.name} ${employee?.surname} (${employee?.position?.name})`
    }
  }) || [];

  const handleStatusChange = (selectedOption: Option) => {
    setSelectedStatus(selectedOption);
  };

  const handleEmployeePositionsChange = (selectedOption: Option) => {
    setSelectedEmployeePosition(selectedOption);
  };

  const handleServicesChange = (selectedOption: Option) => {
    setSelectedService(selectedOption);
  };

  const handleEmployeesChange = (selectedOption: Option) => {
    setSelectedEmployees(selectedOption);
  };

  const handleApplyFilter = () => {
    setFilters(prevState => {
      return {
        ...prevState,
        statusId: selectedStatus?.value ? selectedStatus.value : null,
        positionId: selectedEmployeePosition?.value ? selectedEmployeePosition.value : null,
        serviceId: selectedService?.value ? selectedService?.value : null,
        employeeId: selectedEmployees?.value ? selectedEmployees?.value : null
      }
    })
  };

  const handleClearFilter = () => {
    setFilters(prevState => {
      return {
        ...prevState,
      statusId: null,
      positionId: null,
      serviceId: null,
      employeeId: null
    }})
    setSelectedStatus(null);
    setSelectedEmployeePosition(null);
    setSelectedService(null);
    setSelectedEmployees(null);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleApplyFilter();
    }
  };

  return (
    <>
      <Row className='gx-3'>
        <Col lg={8} xl={9} className="mb-3 mb-lg-0">
          <Row className='gx-3 h-100'>
            <Col sm={6}
              lg={3}
              className='text-primary mb-3 mb-lg-0'
            >
              <ReactSelect
                name='statusId'
                id="logbook-statusId-field"
                value={selectedStatus}
                options={statusesOptions}
                placeholder={t('status')}
                onChange={handleStatusChange}
                isSearchable={false}
                onKeyDown={handleSearchKeyDown}
              />
            </Col>
            <Col sm={6}
              lg={3}
              className='text-primary mb-3 mb-sm-0'>
              <ReactSelect
                name='positionId'
                id="logbook-positionId-field"
                value={selectedEmployeePosition}
                options={employeePositionsOptions}
                placeholder={t('Position')}
                onChange={handleEmployeePositionsChange}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
              />
            </Col>
            <Col sm={6}
              lg={3}
              className='text-primary mb-3 mb-sm-0'
            >
              <ReactSelect
                name='serviceId'
                id="logbook-serviceId-field"
                value={selectedService}
                options={servicesOptions}
                placeholder={t('service')}
                onChange={handleServicesChange}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
              />
            </Col>
            <Col sm={6}
              lg={3}
              className='text-primary'>
              <ReactSelect
                name='employeeId'
                id='logbook-employeeId-field'
                value={selectedEmployees}
                options={employeesOptions}
                placeholder={t('Review modal and table.Employee')}
                onChange={handleEmployeesChange}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
                disabled={isEmployeeRole}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={4} xl={3}>
          <Row className='gx-3 h-100'>
            <Col xs={6} lg={6}>
              <Button
                type='button'
                variant='outline-dark'
                className='border-grey w-100 h-100  fw-normal'
                onClick={handleClearFilter}
              >
                {t('clear')}
              </Button>
            </Col>
            <Col xs={6} lg={6}>
              <Button
                variant='primary'
                className='w-100 h-100 px-1'
                onClick={handleApplyFilter}
              >
                {t('apply')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
}

export default CalendarFilterWrapper;
