import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../public/locales/en/translation.json';
import translationUA from '../public/locales/ua/translation.json';
import translationHI from '../public/locales/hi/translation.json'
import translationID from '../public/locales/id/translation.json'
import translationTH from '../public/locales/th/translation.json'
import translationVI from '../public/locales/vi/translation.json'
import translationAR from '../public/locales/ar/translation.json'

const resources = {
    en: {
        translation: translationEN
    },
    uk: {
        translation: translationUA
    },
    hi: {
        translation: translationHI
    },
    id: {
        translation: translationID
    },
    th: {
        translation: translationTH
    },
    vi: {
        translation: translationVI
    },
    ar: {
        translation: translationAR
    }
};

const savedLanguage = localStorage.getItem('i18nextLng') || 'en';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage,
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
