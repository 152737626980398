import React, {useEffect, useState} from 'react';
import {AddEditModal, ReactSelect} from '../../../index';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {Formik, Form, ErrorMessage} from 'formik';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {Option} from 'src/Modal/AddOrUpdateAccessRightsModal/AccessRightsModal';
import {useTranslation} from 'react-i18next';
import {useGetRolesQuery} from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import {ApolloError} from '@apollo/client';
import ErrorService from '@me-team/common/src/services/ErrorService';
import {RoleProxy} from '@me-team/host/main/graphql/types';
import Spinner from '../../../Spinner/Spinner';
import {
  useGetCurrentBranchQuery
} from '@me-team/host/main/setUpAccessRole/graphql/setUpAccessRole.hooks';
import {FormDataAccessRights, SelectedEmployee} from '../types';
import {addAccessRightsFormValidationSchema} from '../constants';
import {UserRole} from '@me-team/common/src/utils/enums';

interface AddAccessRightsModalProps {
  isAddAccessRightsModal: boolean;
  onCloseAddAccessRightsModal: () => void;
  selectedEmployeeToAdd: SelectedEmployee;
  onAddAccessRightsEmployee: (values: FormDataAccessRights) => void;
  branchToAdd?: CurrentBranch;
}

interface CurrentBranch {
  id: number;
  name: string;
}

const AddAccessRightsModal: React.FC<AddAccessRightsModalProps> = ({
                                                                     isAddAccessRightsModal,
                                                                     onCloseAddAccessRightsModal,
                                                                     selectedEmployeeToAdd,
                                                                     onAddAccessRightsEmployee,
                                                                     branchToAdd
                                                                   }) => {
  const {t} = useTranslation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {data: currentBranchData, loading: currentBranchLoading} = useGetCurrentBranchQuery({
    context: {
      errorType: 'local'
    },
    skip: branchToAdd,
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const currentBranch: CurrentBranch = branchToAdd ? branchToAdd : currentBranchData?.user?.currentBranch 

  const {data: rolesData, loading: roleLoading} = useGetRolesQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const rolesOptions: Option[] = rolesData?.role?.filter((role: RoleProxy) => role.id !== UserRole.OWNER)
  .map((role: RoleProxy) => {
    return {
      value: role?.id,
      label: role?.name
    }
  }) || [];

  const initialValues: FormDataAccessRights = {
    employeeId: selectedEmployeeToAdd?.id ? selectedEmployeeToAdd.id : null,
    branchId: currentBranch?.id ? currentBranch.id : null,
    roleId: null,
  }

  if (roleLoading || currentBranchLoading) return <Spinner/>;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addAccessRightsFormValidationSchema(t)}
        enableReinitialize
        onSubmit={(values, {resetForm, setSubmitting}) => {
          onAddAccessRightsEmployee(values);
          resetForm()
          setSubmitting(false);
        }}
      >
        {({values, setFieldValue, handleSubmit, isSubmitting, resetForm}) => {
          useEffect(() => {
            if(selectedEmployeeToAdd?.roleId) setFieldValue('roleId', selectedEmployeeToAdd?.roleId)
          }, [selectedEmployeeToAdd])

          return (
            <Form>
              <AddEditModal
                isModalOpened={isAddAccessRightsModal}
                setIsModalOpened={onCloseAddAccessRightsModal}
                title={t('Add access rights')}
                modalBodyClass='pb-0 px-0'
              >
                <Container className='pb-3 px-3'>
                  <Row className="m-0 mb-3">
                    <Col
                      md={3}
                      className='mb-2 mb-md-0 text-start d-flex align-items-center'>
                      <label
                        className='text-dark fs-7 fw-bold py-1'
                      >
                        {t('Employee')}
                      </label>
                    </Col>
                    <Col
                      md={9}
                      className='d-flex align-items-center '
                    >
                      <span
                        className='fs-7 py-1'>{selectedEmployeeToAdd?.name} {selectedEmployeeToAdd?.surname}</span>
                    </Col>
                  </Row>
                  <Row className="m-0 mb-3">
                    <Col
                      md={3}
                      className='mb-2 mb-md-0 text-start d-flex align-items-center'>
                      <label
                        className='text-dark fs-7 fw-bold py-1'
                      >
                        {t('Branch')}
                      </label>
                    </Col>
                    <Col
                      md={9}
                      className='d-flex align-items-center '
                    >
                      <span className='fs-7 py-1'>{currentBranch?.name}</span>
                    </Col>
                  </Row>
                  <Row className='m-0 h-100' style={{minHeight: '49px'}}>
                    <Col
                      md={3}
                      className='mb-2 mb-md-0'>
                      <label
                        htmlFor='editEmployee-role-field'
                        className='text-dark fs-7 fw-bold py-1 h-100 d-flex align-items-center'
                      >
                        {t('Access rights (role)')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={9} className='text-start'>
                      <ReactSelect
                        id='AddAccessRightsModal-Id-field'
                        name='roleId'
                        placeholder={t('Select a role')}
                        options={rolesOptions}
                        value={rolesOptions.find((option) => option.value === values.roleId)}
                        onChange={(selectedOption: Option) => setFieldValue('roleId', selectedOption?.value)}
                        isSearchable={false}
                      />
                      <ErrorMessage name="roleId">{msg => <div
                        className='text-danger fs-7 mt-1'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row className='p-6'>
                    <Col lg={6}
                         className='offset-lg-6 d-flex justify-content-end gap-2 px-0 ps-lg-4'>
                      <Col xs={6} lg={6}>
                        <Button
                          onClick={() => {
                            resetForm()
                            onCloseAddAccessRightsModal()
                          }}
                          variant="outline-primary" type="button"
                          className="w-100 fw-normal"
                        >
                          {t("cancel")}
                        </Button>
                      </Col>
                      <Col xs={6}>
                        <Button
                          variant="primary"
                          type='button'
                          className="w-100"
                          onClick={() => handleSubmit()}
                          disabled={isSubmitting}
                        >
                          {t("add")}
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </AddEditModal>
            </Form>
          )
        }}
      </Formik>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default AddAccessRightsModal;