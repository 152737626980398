import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {
    useCreateVacationMutation,
    useDeleteVacationMutation,
    useGetEmployeeByIdScheduleQuery, useUpdateVacationMutation,
} from '@me-team/host/main/work-shedule/graphql/workShedule.hooks';
import {Link} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {VacationProxy} from "@me-team/host/main/graphql/types";
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

interface Employee {
    id: number;
    surname: string;
    name: string;
    vacations: Array<{ id: number; startDate: string; endDate: string }>;
}

interface VacationModalProps {
    show: boolean;
    handleClose?: () => void;
    employeeId: number;
    forceRefresh: () => void;
    toggleShowToast: (str: string) => void
    edit: boolean
    editDate: any
}

interface FormValues {
    startDate: Date | null | string;
    endDate: Date | null | string;
}

const setToEndOfDay = (date: Date): Date => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
};

const validationSchema = (vacations: Employee['vacations'], t: (key: string) => string, edit: boolean) =>
    Yup.object()
        .shape({
            startDate: Yup.date()
                .nullable()
                .required(t('Start date is required')),
            endDate: Yup.date()
                .nullable()
                .required(t('End date is required'))
                .min(Yup.ref('startDate'), t('End date cannot be before start date')),
        })
        .test(
            'date-overlap',
            t('Vacation has already been added for the specified dates. Edit it.'),
            function (values) {
                if (edit) {
                    return true;
                }
                const { startDate, endDate } = values;
                if (!startDate || !endDate) return true;

                const startTimestamp = startDate.getTime();
                const endTimestamp = setToEndOfDay(endDate).getTime();

                for (let vac of vacations) {
                    const vacStart = new Date(vac.startDate.replace(' ', 'T'));
                    const vacEnd = setToEndOfDay(new Date(vac.endDate.replace(' ', 'T')));
                    const vacStartTimestamp = vacStart.getTime();
                    const vacEndTimestamp = vacEnd.getTime();
                    if (
                        (startTimestamp >= vacStartTimestamp &&
                            startTimestamp <= vacEndTimestamp) ||
                        (endTimestamp >= vacStartTimestamp &&
                            endTimestamp <= vacEndTimestamp) ||
                        (startTimestamp <= vacStartTimestamp &&
                            endTimestamp >= vacEndTimestamp)
                    ) {
                        return this.createError({
                            path: 'startDate',
                            message: t('Vacation has already been added for the specified dates. Edit it.'),
                        });
                    }
                }
                return true;
            }
        );


const formatDate = (date: Date): string => {
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
};

const formatDateRange = (startDateStr: string, endDateStr: string): string => {
    const startDate = new Date(startDateStr.replace(' ', 'T') + 'Z');
    const endDate = new Date(endDateStr.replace(' ', 'T') + 'Z');

    return `${formatDate(startDate)} – ${formatDate(endDate)}`;
};

interface PreviousVacationsListProps {
    vacations: Employee['vacations'];
    onDelete: (vacationId: number) => void;
    t: (key: string) => string;
}

const PreviousVacationsList: React.FC<PreviousVacationsListProps> = ({ vacations, onDelete, t }) => {
    if (!vacations || !vacations.length) {
        return <span className='fs-7'>{t('MissedAddVacationModal')}</span>;
    }

    return (
        <>
            {vacations.map((vac, index) => (
                <div  className={`d-flex justify-content-between ${index !== 0 ? 'pt-3' : ''}`} key={vac.id}>
                    <span className="fs-7">{formatDateRange(vac.startDate, vac.endDate)}</span>
                    {new Date(vac.endDate) >= new Date() && (
                        <i
                            className="bi bi-trash3"
                            data-bs-title={t('Delete vacation')}
                            data-bs-placement="bottom"
                            onClick={() => onDelete(vac.id)}
                            style={{ cursor: 'pointer', width: '14px', height: '16px', fontSize: '14px' }}
                        ></i>
                    )}
                </div>
            ))}
        </>
    );
};

interface DatePickerFieldProps {
    id: string;
    name: string;
    label: string;
    selected: Date | null;
    onChange: (date: Date) => void;
    errorMessage: string | undefined;
    t: (key: string) => string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({ id, name, label, selected, onChange, errorMessage, t }) => {
    return (
        <Row className="pt-3">
            <Col xs={3} className="pe-0 pt-2">
                <label htmlFor={id}>
                    <strong className="text-dark fs-7">{t(label)}</strong>
                </label>
            </Col>
            <Col xs={9} className="text-dark">
                <DatePicker
                    id={id}
                    name={name}
                    className="form-control fs-7"
                    wrapperClassName="w-100"
                    selected={selected}
                    placeholderText={t('dd.mm.yyyy')}
                    onChange={onChange}
                    dateFormat="dd.MM.yyyy"
                    calendarStartDay={1}
                    maxDate={new Date("12-27-9999")}

                />
                {errorMessage && <p className="fs-7 p-1 text-danger m-0">{errorMessage}</p>}
            </Col>
        </Row>
    );
}
const AddVacationModal: React.FC<VacationModalProps> = ({
                                                            show,
                                                            handleClose,
                                                            employeeId,
                                                            forceRefresh,
                                                            toggleShowToast,
                                                            edit,
                                                            editDate
                                                        }) => {
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const { data, loading, refetch } = useGetEmployeeByIdScheduleQuery({
        variables: { employeeId, companyId: +currentCompanyId },
        skip: !currentCompanyId,
        context: {
            errorType: 'global',
        },
    });
    const [createVacation] = useCreateVacationMutation({
        context: {
            errorType: 'local',
        },
    });
    const [deleteVac] = useDeleteVacationMutation({
        context: {
            errorType: 'local',
        },
    });
    const [updateVacation] = useUpdateVacationMutation({
        context: {
            errorType: 'local',
        },
    });
    const employee = data?.user?.company?.employees?.employees[0];
    const [vacationsToDelete, setVacationsToDelete] = useState<number[]>([]);
    const [displayedVacations, setDisplayedVacations] = useState<Employee['vacations']>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [editVacations, setEditVacations] = useState(null)

    const checkDateOverlap = (vacations: VacationProxy[], editDateStart: string) => {
        const editStartDate = Date.parse(editDateStart.slice(0, 10));
        for (const vacation of vacations) {
            const vacationStart = Date.parse(vacation.startDate.slice(0, 10));
            const vacationEnd = Date.parse(vacation.endDate.slice(0, 10))
            if (editStartDate >= vacationStart && editStartDate <= vacationEnd) {
                setEditVacations(vacation);
                return vacation;
            }
        }
        return null;
    };

    useEffect(() => {
        if (employee?.vacations) {
            edit && checkDateOverlap(employee?.vacations, editDate?.startDate);
            setDisplayedVacations(employee?.vacations);
        }
    }, [employee]);

    if (loading) {
        return (
            <Modal size="lg" show={true}>
                <Spinner />
            </Modal>
        );
    }

    const handleMarkVacationForDeletion = (vacationId: number) => {
        setVacationsToDelete((prev) => [...prev, vacationId]);
        setDisplayedVacations((prevVacations) =>
            prevVacations.filter((vac) => vac.id !== vacationId)
        );
    };
    const formatDateForInput = (date: Date | string): string => {
        if (typeof date === "string" && /^\d{4}-\d{2}-\d{2}$/.test(date.slice(0, 10))) {
            return date.slice(0, 10)
        }
        if (date instanceof Date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        }
        throw new Error("Invalid date format");
    };

    const handleFormSubmit = async (values: FormValues, { resetForm }: any) => {
        try {
            await Promise.all(
                vacationsToDelete.map((vacationId) =>
                    deleteVac({
                        variables: { vacationId },
                    })
                )
            );
            if (values.startDate && values.endDate) {
                if (edit) {
                    await updateVacation({
                        variables: {
                            vacationId: editVacations?.id,
                            input: {
                                startDate: formatDateForInput(values.startDate),
                                endDate: formatDateForInput(values.endDate),
                            },
                        },
                    })
                } else {
                    await createVacation({
                        variables: {
                            employeeId: employeeId,
                            input: {
                                startDate: formatDateForInput(values.startDate),
                                endDate: formatDateForInput(values.endDate),
                            },
                        },
                    });
                }
            }
            await refetch();
            forceRefresh()
            toggleShowToast(t('Saved'));
            handleClose();
        } catch (error) {
            console.error(error)
            if (error.message === "There is a booking for these dates") {
                setErrorMessage("There is a booking for these dates");
            }
        }
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Modal.Header className="border-0" closeButton>
                {errorMessage === "There is a booking for these dates" ?
                    <h5 className="m-0"> {t('Vacation creation denied')}</h5>
                    :
                    <h5 className="m-0"> {edit ? t('Edit vacation') : t('Add Vacation') } </h5>
                }
            </Modal.Header>
            <Formik
                key={editVacations?.id || 'new-form'}
                initialValues={{
                    startDate: edit ? editVacations?.startDate :  null,
                    endDate: edit ? editVacations?.endDate :  null,
                }}
                validationSchema={validationSchema(displayedVacations || [], t, edit)}
                onSubmit={handleFormSubmit}
            >
                {({ setFieldValue, values, errors, touched }) => (
                    <Form noValidate>
                        { errorMessage === "There is a booking for these dates" ?
                            <Modal.Body style={{padding: '16px 28px'}}>
                                <Row className="justify-content-center text-center">
                                    <span className="">
                                        {t('Vacation can not be created due to existing bookings for today or/and future. Go to logbook to change bookings parameters.')}
                                    </span>
                                </Row>
                                <div className="border-0 col-12">
                                    <div className="d-flex px-3 justify-content-center">
                                        <div className="col-6 pe-1">
                                            <Link className="col-3 btn btn-primary fw-bold w-100 fs-7 mt-4"
                                                  to={`/logbook/journal/` + currentBranchId}>
                                                {t('To Logbook')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            :
                            <>
                                <Modal.Body style={{padding: '16px 28px'}}>
                                    <Row className="py-3">
                                        <Col xs={3} className="my-auto">
                                            <strong>
                                                <span className="fs-7">{t('Employee')}</span>
                                            </strong>
                                        </Col>
                                        <Col xs={9}>
                                    <span className="fs-7">
                                        {employee?.name} {employee?.surname}
                                    </span>
                                        </Col>
                                    </Row>

                                    <DatePickerField
                                        id="startDate"
                                        name="startDate"
                                        label={t('Start Date')}
                                        selected={values.startDate}
                                        onChange={(date) => setFieldValue('startDate', date)}
                                        errorMessage={touched.startDate && errors.startDate ? errors.startDate : undefined}
                                        t={t}
                                    />

                                    <DatePickerField
                                        id="endDate"
                                        name="endDate"
                                        label={t('End Date')}
                                        selected={values.endDate}
                                        onChange={(date) => setFieldValue('endDate', date)}
                                        errorMessage={touched.endDate && errors.endDate ? errors.endDate : undefined}
                                        t={t}
                                    />

                                    <Row className='pt-3'>
                                        <Col xs={3} className="pe-0">
                                            <label htmlFor="previousVacation">
                                                <strong className="text-dark fs-7">
                                                    {t('Previous Vacation')}
                                                </strong>
                                            </label>
                                        </Col>
                                        <Col xs={9} className="text-dark">
                                            <PreviousVacationsList
                                                vacations={displayedVacations}
                                                onDelete={handleMarkVacationForDeletion}
                                                t={t}
                                            />
                                        </Col>
                                    </Row>
                                </Modal.Body>

                                <div style={{ padding: '12px' }}>
                                    <Row className="gx-3 justify-content-end">
                                        <Col xs={3}>
                                            <Button
                                                variant="outline-dark"
                                                className="w-100 h-100 fw-normal"
                                                onClick={handleClose}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                        </Col>
                                        <Col xs={3}>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="w-100 text-truncate"
                                            >
                                                {t('Confirm')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddVacationModal;
