import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, ErrorMessage, FormikValues } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import CustomReactSelect from "@services/ui-components/src/select/CustomReactSelect";
import {
    useDeleteEmployeePositionByIdMutation,
    useDeleteEmployeePositionMutation
} from '@me-team/host/main/employeeList/graphql/employeeList.hooks';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

interface EmployeePositionProxy {
    __typename?: "EmployeePositionProxy";
    id: number;
    name: string;
    description?: string;
    employees?: { __typename?: "EmployeeProxy"; id: number; specialization?: string; }[];
}

interface DeletePositionModalProps {
    show: boolean;
    handleModal: (employee: EmployeePositionProxy | null) => void;
    selectedPosition?: EmployeePositionProxy | null;
    positions: EmployeePositionProxy[];
    refetch: () => void;
    toggleShowToast?: (text: string) => void
}

const DeletePositionModal: React.FC<DeletePositionModalProps> = ({
    show,
    handleModal,
    selectedPosition,
    positions,
    refetch,
    toggleShowToast
}) => {
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const initialValues = {
        id: selectedPosition?.id || '',
        newPositionId: ''
    };

    const validationSchema = Yup.object().shape({
        newPositionId: Yup.number().required(t('Position is required'))
    });

    const filteredPositions = useMemo(() => {
        return positions?.filter(item => item.id !== selectedPosition?.id);
    }, [positions, selectedPosition?.id]);

    const [deletePosition] = useDeleteEmployeePositionMutation();
    const [deletePositionID] = useDeleteEmployeePositionByIdMutation();

    const handleSubmit = async (values: FormikValues) => {
        try {
            await deletePosition({
                variables: {
                    id: +values.id,
                    newPositionId: +values.newPositionId,
                    companyId: +currentCompanyId
                }
            });
            refetch();
            setTimeout(() => handleModal(null), 0)
            toggleShowToast(t('Deleted'))
        } catch (error) {
            console.error('Error deleting position:', error);
        }
    };

    const handleSubmit2 = async (values: FormikValues) => {
        try {
            await deletePositionID({
                variables: {
                    id: +values.id,
                    companyId: +currentCompanyId
                }
            });
            refetch();
            setTimeout(() => handleModal(null), 0);
            toggleShowToast(t('Deleted'))
        } catch (error) {
            console.error('Error deleting position:', error);
        }
    };

    const renderDeletePositionContent = () => {
        if (positions?.length === 1 && positions[0]?.employees?.length > 0) {
            return (
                <Modal
                    show={show}
                    onHide={() => handleModal(null)}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title className='text-danger'>{t('Deletion of position impossible')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {t('Deletion is not possible. Please create a new position.')}
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        <Col>
                            <Button
                                className="btn btn-danger border-grey w-100 h-100 fw-normal"
                                onClick={() => handleModal(null)}
                            >
                                OK
                            </Button>
                        </Col>
                    </Modal.Footer>
                </Modal>
            );
        } else if (selectedPosition?.employees?.length === 0) {
            return (
                <Modal
                    show={show}
                    onHide={() => handleModal(null)}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title>{t('Delete position')}</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit2}
                    >
                        {() => (
                            <Form>
                                <Modal.Body className="text-center">
                                    <p className='mb-1'>{t('Do you really want to delete the position')} {selectedPosition?.name}?</p>
                                </Modal.Body>
                                <Modal.Footer className="border-0">
                                    <Col>
                                        <Button
                                            variant="outline-primary"
                                            className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                            onClick={() => handleModal(null)}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="btn btn-primary w-100 text-truncate"
                                            type="submit"
                                        >
                                            {t('Delete')}
                                        </Button>
                                    </Col>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            );
        } else {
            return (
                <Modal
                    size="lg"
                    show={show}
                    onHide={() => handleModal(null)}
                    centered
                >
                    <Modal.Header className="border-0" closeButton>
                        <h5 className="m-0">{t('Delete position')}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <p className='mb-4'> {t('Positions found for this search')} {selectedPosition?.employees?.length} {t('employees. To delete this position, choose a new one for the affected employees.')}</p>
                                    </Row>
                                    <Row >
                                        <Col xs={2} className="d-flex  align-items-center">
                                            <span className="text-dark fw-bold">
                                                <label htmlFor="deletePositionModal-newPositionId" className="mb-0 text-dark form-label required">{t('Position')}</label>
                                            </span>
                                        </Col>
                                        <Col xs={10}>
                                            <div className="position-relative w-100">
                                                <CustomReactSelect
                                                    optionsArray={filteredPositions}
                                                    placeholder={t('Select new position')}
                                                    setField={setFieldValue}
                                                    fieldName="newPositionId"
                                                    value="id"
                                                    name={"deletePositionModal-newPositionId"}
                                                    id={"deletePositionModal-newPositionId-field"}
                                                />

                                            </div>

                                        </Col>
                                        <Col >

                                        </Col>
                                        <Col xs={10}>
                                            <ErrorMessage name="newPositionId">
                                                {msg => <p className="fs-7 m-0 p-1 text-danger">{msg}</p>}
                                            </ErrorMessage>
                                        </Col>

                                    </Row>
                                    <Row className='mt-4 d-flex align-items-center'>
                                        <Col>
                                            {t('Or go to')} <Link to='/employees-list'>{t('Employee List')}</Link>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            variant="outline-primary"
                                            className="btn btn-outline-primary modal-button me-2 custom-btn fw-normal"
                                            onClick={() => handleModal(null)}
                                        >
                                            <span>
                                                {t('cancel')}
                                            </span>
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-primary fw-bold modal-button custom-btn"
                                        >
                                            <span>{t('Delete')}</span>
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            );
        }
    };

    return <>{renderDeletePositionContent()}</>;
};

export default DeletePositionModal;
