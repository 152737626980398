import React, {useState} from 'react';
import {InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import UpdateCompanyForm from './UpdateCompanyForm';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useNavigate} from 'react-router-dom';
import {UserRole} from '../../utils/enums';
import { useGetBranchesIsDeletedQuery, useGetRoleUserQuery } from '@me-team/host/main/company/graphql/company.hooks';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

const UpdateCompany: React.FC = () => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const {data: infoUserData, loading} = useGetRoleUserQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const role: UserRole = infoUserData?.user?.role?.id

  const {data: getBranchesIsDeleted} = useGetBranchesIsDeletedQuery({
    variables: {
      isDeleted: true,
      companyId: +currentCompanyId
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const isDeletedBranches = (getBranchesIsDeleted?.user?.company?.branches?.length ?? 0) > 0

  const handleDeleteCompany = () => {
    setShowModalDelete(true);
  };

  if (loading) return <Spinner/>;

  return (
    <div className='mb-9 px-2 px-lg-0 pb-4'>
      <InfoBanner>
        <p
          className='fs-7 mb-0'>{t("On this page, you can enter information about your company." +
          " Required fields are marked with an asterisk - *. If your company has several branches, you can create them using the button '+ Add branch'. If you want to restore the deleted branch - click 'Restore branch'")}
        </p>
      </InfoBanner>
      <TableHeader
        title={t('Company')}
        colSize={8}
        btnColSize={4}
        buttons={
          <Row
            className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-center justify-content-lg-end gap-2  gap-md-4 gap-lg-0'>
            <Col
              xs={12}
              sm={4}
              lg={6}
              className='px-0'
            >
              <Button
                variant='outline-primary'
                className='w-100 mt-3 mt-lg-0  mb-3 mb-lg-0 fw-normal'
                onClick={() => navigate('/branches')}
                disabled={!isDeletedBranches}
              >
                {t('Restore Branches')}
              </Button>
            </Col>
            {/*TODO Скрыть кнопку удаления компании (на время пока не сделали восстановление)*/}
            {/*{role === UserRole.OWNER ?*/}
            {/*  <Col*/}
            {/*    xs={12}*/}
            {/*    sm={4}*/}
            {/*    lg={6}*/}
            {/*    className='px-0 ms-md-4'*/}
            {/*  >*/}
            {/*    <Button*/}
            {/*      variant="outline-orange"*/}
            {/*      className='fw-normal w-100 mt-3 mt-lg-0  mb-3 mb-lg-0'*/}
            {/*      onClick={handleDeleteCompany}*/}
            {/*    >*/}
            {/*      {t('Delete Company')}*/}
            {/*    </Button>*/}
            {/*  </Col> : null*/}
            {/*}*/}
          </Row>
        }
      />
      <Row className='mt-4'>
        <UpdateCompanyForm
          setShowModalDelete={setShowModalDelete}
          showModalDelete={showModalDelete}
        />
      </Row>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default UpdateCompany;
