import {LanguageProxy} from "@me-team/host/main/graphql/types";
import {LanguagesIds} from "../../utils/enums";
import {ReactSelectInterface} from "../../interfaces/interfaces";

class LanguagesService {
    public static languageFilter = (languagesList: LanguageProxy[]) : ReactSelectInterface[] => {
        const PROJECT_LANGUAGES_IDS: number[] = [
            LanguagesIds.ENGLISH,
            LanguagesIds.UKRAINIAN,
            LanguagesIds.CZECH,
            LanguagesIds.ARABIC,
            LanguagesIds.HINDI,
            LanguagesIds.INDONESIAN,
            LanguagesIds.THAI,
            LanguagesIds.VIETNAMESE
        ];
        const filteredLanguages = languagesList.filter(language =>
            PROJECT_LANGUAGES_IDS.includes(language.id)
        );
        const languagesToOptionsConvert = (languagesList : LanguageProxy[]) : ReactSelectInterface[] => {
          return languagesList.map((language : LanguageProxy ) : ReactSelectInterface  => ({
              value: language.id,
              label: language.name
          })) || []
        }
        return languagesToOptionsConvert(filteredLanguages);
    }
}

export default LanguagesService