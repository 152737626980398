import React, { useState, useRef, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactSelect, TooltipPopover } from '@services/ui-components';
import { stylesSelect } from '../../TariffManagement/TariffPlans/stylesSelectReact';
import { useReactiveVar } from "@apollo/client";
import {EmployeeServiceRelationProxy, SalaryType} from '@me-team/host/main/graphql/types';
import {CSSObjectWithLabel, StylesConfig} from 'react-select';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/main/globalVar/state';
interface SalaryComponentProps {
    service: EmployeeServiceRelationProxy | any;
    updateEmployeeServices: (i: EmployeeServiceRelationProxy) => void;
    currencySign: string;
    price: number;
    index?: number
    value: number
}
const updatedStylesSelect: StylesConfig = {
    ...stylesSelect,
    menuList: (provided: CSSObjectWithLabel) => ({
        ...provided,
        padding: '8px 4px',
        textAlign: 'center'
    }),
}
const   SalaryComponent: React.FC<SalaryComponentProps> = ({
                                                             service,
                                                             updateEmployeeServices,
                                                             currencySign,
                                                             price,
                                                             index,
                                                            value
                                                         }) => {
    const userRole = useReactiveVar(currentUserRoleVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const { t } = useTranslation();
    const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;
    const options = [
        { value: '%', label: '%' },
        { value: currencySign, label: currencySign },
    ];

    const [salaryType, setSalaryType] = useState(
        service?.salaryType === 1
            ? '%'
            : service?.salaryType === 2
                ? currencySign
                : null
    );

    const [salaryValue, setSalaryValue] = useState(service?.salaryType === 1
        ? roundToTwoDecimals(value * 100)
        : value);

    const [error, setError] = useState<string | null>(null);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
    const maxServicePrice = price;

    const getSelectedOption = useCallback(() => {
        return options.find(option => option.value === salaryType) || null;
    }, [salaryType, options]);

    const validateSalary = (value: string, type: string | null): boolean => {
        let numericValue = parseFloat(value);
        if (type === '%' && (/^0\d+/.test(value) || numericValue < 0 || numericValue > 100)) {
            setError(t('Enter a value between 0 and 100.'));
            return false;
        } else if (type === currencySign && (/^0\d+/.test(value) || numericValue < 0 || numericValue > maxServicePrice)) {
            setError(`${t('Enter a value between 0 and')} ${maxServicePrice}.`);
            return false;
        } else if (numericValue < 0) {
            setError(t('Enter a value greater than or equal to 0.'));
            return false;
        } else if (service?.price < numericValue && type !== '%') {
            setError(t('The price cannot be less than the salary'))
            return false
        } else {
            setError(null);
            return true;
        }
    }

    const updatePrice = (i: EmployeeServiceRelationProxy, newValue: number) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            updateEmployeeServices({ ...i, salaryValue: newValue, salaryType: salaryType === '%' ? 1 : 2});
        }, 1300);
    };
    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(',', '.');
        const validFormat = /^\d{0,6}(\.\d{0,2})?$/

        if (validFormat.test(inputValue) && (inputValue === '' || Number(inputValue) >= 0)) {
            if (inputValue.startsWith('.') && !inputValue.startsWith('0.')) {
                inputValue = '0' + inputValue;
            }
            setSalaryValue(inputValue);
            const isValid = validateSalary(inputValue, salaryType);
            if (!isValid) {
                return;
            }
            const adjustedValue = salaryType === '%' ? (Number(inputValue) / 100) : inputValue;
            updatePrice(service, Number(adjustedValue));
        }
    };

    const updateSymbol = (i: EmployeeServiceRelationProxy, newValue: SalaryType) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            updateEmployeeServices({ ...i, salaryType: newValue,
                salaryValue: +newValue === 1 ? (Number(salaryValue) / 100) : salaryValue
            });
        }, 1300);
    };

    const handleSalaryTypeChange = (selectedOption: { value: string; label: string }) => {
        setSalaryType(selectedOption.value);
        const isValid = validateSalary(salaryValue, selectedOption.value);
        if (!isValid) {
            return;
        }
        updateSymbol(service, selectedOption.value === '%' ? 1 : 2);
    };
    return (
        <>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <TooltipPopover
                    placement="bottom"
                    text={
                        <div className="d-flex align-items-center">
                            <i className="bi bi-exclamation-square-fill text-orange me-2 fs-5"></i>
                            <span className="fs-7 text-dark">{error}</span>
                        </div>
                    }
                    styleBdy="py-1 px-2"
                    show={!!error}
                    maxWidth="500px"
                    trigger="click"
                    zIndex={1}
                >
                    <Form.Group>
                        <Form.Control
                            size="sm"
                            id={`salaryComponent-${index}-field`}
                            onChange={handlePriceChange}
                            placeholder={salaryType === "%" ? "0" : "0.00"}
                            value={salaryValue}
                            style={{
                                width: '88px',
                                backgroundImage: 'none',
                                border: error ? '1px solid red' : ''
                            }}
                            disabled={userRole === 5}
                        />
                    </Form.Group>
                </TooltipPopover>
                <div style={{ paddingLeft: '8px' }}>
                    <ReactSelect
                        options={options}
                        name={`salaryComponent-${index}-select`}
                        id={`salaryComponent-${index}-select`}
                        value={getSelectedOption()}
                        onChange={handleSalaryTypeChange}
                        stylesSelect={updatedStylesSelect}
                        menuPlacement={'auto'}
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        disabled={userRole === 5}
                    />
                </div>
            </div>
        </>
    );
};

export default SalaryComponent;
