import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import { Alert } from 'react-bootstrap';
import moment from 'moment/moment';
import { ApolloError, useReactiveVar } from "@apollo/client";
import { UserRole } from "@me-team/common/src/utils/enums";
import { useGetExpiredFeaturesQuery } from "@me-team/host/main/paymentExpired/paymentExpired.hooks";
import AlertPaymentExpired from "./AlertPaymentExpired";
import ErrorService from "../../services/ErrorService";
import { Spinner } from "@services/ui-components";
import { ErrorModalComponent } from "@me-pos/error-modal";
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/main/globalVar/state';

const PaymentExpired: React.FC = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userRole = useReactiveVar(currentUserRoleVar);
    const isOwnerRole = +userRole === UserRole.OWNER;
    const isRegistrationOrCreateOwner = location.pathname === "/registration" || location.pathname === "/create-owner";

    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

    const {data: expiredFeaturesData, loading} = useGetExpiredFeaturesQuery({
        variables: { companyId: +currentCompanyId },
        context: {
            errorType: 'global'
        },
        skip: !currentCompanyId,
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error));
        },
    });

    const expiredFeature = expiredFeaturesData?.user?.company?.expiredPaidFeatures?.[0];
    const isUserActiveWithinAccount = expiredFeaturesData?.user?.isUserActiveWithinTariff;

    const dayOfEndPayment = expiredFeature?.dateOfEnd ? moment(expiredFeature.dateOfEnd) : null;
    const extendedPaymentDueDate = dayOfEndPayment ? moment(dayOfEndPayment).add(7, 'days').format("DD.MM.YYYY") : null;
    const tariffName = expiredFeature?.name.replace(/_/g, ' ').toLowerCase();
    const currentDate = moment();
    const isPaymentExpired = dayOfEndPayment && currentDate.isSameOrAfter(dayOfEndPayment, 'day');
    const isExpirationAlertShown = isPaymentExpired && isUserActiveWithinAccount;

    if (loading) return <Spinner/>;

    return (
        <>
            {
                !isRegistrationOrCreateOwner && isExpirationAlertShown ? (
                    <AlertPaymentExpired>
                        <p className="fs-7 text-dark mb-0">
                            {t("The subscription to the")}{" "}
                            <span className="text-capitalize">{tariffName}</span>{" "}{t("has not been paid. You have 7 additional days. On")}{" "}
                            <span className="d-inline-block fw-bold">{extendedPaymentDueDate}</span>{" "}
                            <span className="text-capitalize">{tariffName}</span>{" "}{t("will be deactivated. Check if you have money on your account or")}{" "}
                            <Alert.Link href={`payment/history/company/${currentCompanyId}`} className="text-primary">{t("change your card")}</Alert.Link>.
                        </p>
                    </AlertPaymentExpired>
                ) : null
            }

            {
                !isRegistrationOrCreateOwner && isUserActiveWithinAccount && !isOwnerRole ? (
                    <AlertPaymentExpired>
                        <p className="fs-7 text-dark mb-0">
                            {t("Your options in Me-POS are limited. Please contact your administrator.")}
                        </p>
                    </AlertPaymentExpired>
                ) : null
            }

            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
        </>
    )
}

export default PaymentExpired;