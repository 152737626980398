import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Image from './image/connect-online.svg';
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import AccessService from "@me-team/host/services/AccessService";
import {useTranslation} from "react-i18next";
import {currentUserEmailVar} from '@me-team/host/main/globalVar/state';

interface ConnectModalProps {
    show: boolean;
    setOpenConnectModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConnectModal: React.FC<ConnectModalProps> = ({ show, setOpenConnectModal }) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const currentUserEmail = useReactiveVar(currentUserEmailVar);
    const handleClose = () => setOpenConnectModal(false);

    const start = (step: string) => {
        localStorage.setItem('step', step)
        localStorage.setItem('run', 'true')
        navigate('/employees-list', { state:  {  navigationStep: step }});
    }
    const goToStep3 = () => {
        const accessService = AccessService.getInstance().isPremium
        start(accessService ?  '4' : '3')
        navigate('/employees-list', { state:  {  mail: currentUserEmail, navigationStep: accessService ?  '4' : '3' }})
    }
    return (
        <Modal show={show} onHide={handleClose} centered  >
            <Modal.Header className='border-0' closeButton>

            </Modal.Header>
            <Modal.Body className='text-center pt-0 pb-0'>
                <h5 className='fw-normal mb-3'>{t('Online booking connection')}</h5>
                <Image />
                <p className='mb-0  mt-3'>{t('Basic steps to connect Online booking.')}</p>
                <p className='mb-0'>{t('Select the number of employees:')}</p>
            </Modal.Body>
            <Modal.Footer className='border-0 px-4 pt-4'>
                <Col >
                    <Button variant="primary" className="  w-100  "  onClick={()=> goToStep3()} >
                        {t('I work alone')}
                    </Button>
                </Col>
                <Col>
                    <Button variant="success" className="btn btn-primary w-100 text-truncate " onClick={()=> start('1')}>
                        {t('I have employee(s)')}
                    </Button>
                </Col>
                <Col xs={12} className='text-center '>
                    <Button variant="link" className='text-grey' onClick={handleClose}>
                        {t('Skip training')}
                    </Button>
                </Col>
            </Modal.Footer>

        </Modal>
    );
};

export default ConnectModal;
