import React from 'react';
import {Button} from 'react-bootstrap';

interface AddAccessRightsButtonProps {
  onShow: () => void;
}

const AddAccessRightsButton: React.FC<AddAccessRightsButtonProps> = ({onShow}) => {
  return (
    <Button
      variant="outline-primary"
      className='p-2 h-100 w-100'
      onClick={onShow}
    >
      <i className="bi bi-person-down fs-6"></i>
    </Button>
  );
};

export default AddAccessRightsButton;