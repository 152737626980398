import {Image, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import React from 'react';
import {EmployeeProxy} from '@me-team/host/main/graphql/types';
import {TooltipAvailableBranches} from '../index';

const EmployeeCell: React.FC<{ i: EmployeeProxy, link?: boolean }> = ({i, link}) => {
  const basePath = process.env.REACT_APP_POS_URL;
  const imageUrl = i?.image ? `${basePath}/${i.image}` : `${process.env.REACT_APP_POS_URL}/build/images/person.jpg`;
  return (
    <Row className="align-items-center">
      <Col xs="auto" className='pr-0'>
        <Image className="img-employ-list rounded-circle" src={imageUrl} width="40px" height="40px"/>
      </Col>
      <Col className='p-0'>
        <div className='d-flex align-items-center flex-nowrap'>
          {link ? (
            <Link to={'/employees-list/edit-employee/' + i?.id} className='text-decoration-none text-dark'>
              <p className="mb-0 fs-16">
                {i?.name} {i?.surname}
              </p>
            </Link>
          ) : (
            <p className="mb-0 fs-16">
              {i?.name} {i?.surname}
            </p>
          )}
          <TooltipAvailableBranches userBranchRelations={i.userBranchRelations}>
            <i className="bi bi-info-circle text-dark ms-2 align-self-center fs-7"></i>
          </TooltipAvailableBranches>
        </div>
        <span className="text-secondary">
          <small>{i?.position.name}</small>
        </span>
      </Col>
    </Row>
  );
};

export default EmployeeCell;
