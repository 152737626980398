import {
  BranchIdAndName,
  BranchRoleRelationForEmployee,
  EmployeeBranchRole,
  IdAndName
} from './types';

export const getAvailableBranchesForOptions = (companyBranches: IdAndName[], EmployeeBranches: BranchIdAndName[]) => {
  const existingBranchIds = new Set(EmployeeBranches.map(item => item.branch.id));
  const filteredBranches = companyBranches.filter(branch => !existingBranchIds.has(branch.id));
  return filteredBranches.map(branch => ({
    value: branch.id,
    label: branch.name
  })) || [];
};

export const updateAccessRightsArray = (
  accessRightsArray: BranchRoleRelationForEmployee[],
  newAccessRights: BranchRoleRelationForEmployee
): BranchRoleRelationForEmployee[] => {
  return accessRightsArray.map(item =>
    item.branch?.id === newAccessRights.branch?.id ? newAccessRights : item
  );
};

export const addAccessRights = (
  accessRightsArray: BranchRoleRelationForEmployee[],
  newAccessRights: BranchRoleRelationForEmployee
): BranchRoleRelationForEmployee[] => {
  const exists = accessRightsArray.some(item => item.branch?.id === newAccessRights.branch?.id);
  if (exists) {
    return accessRightsArray;
  }
  return [...accessRightsArray, newAccessRights];
};

export const getBranchIdsForDelete = (prevArray: BranchRoleRelationForEmployee[], newArray: BranchRoleRelationForEmployee[]): number[] => {
  const newRelationIds = new Set(newArray.map(item => item.branch.id));
  return prevArray
    .filter(item => !newRelationIds.has(item.branch.id))
    .map(item => item.relation);
};

export const getArrayForUpdateAccessRights = (
  prevArray: BranchRoleRelationForEmployee[],
  newArray: BranchRoleRelationForEmployee[],
  employeeId: number
): EmployeeBranchRole[] => {
  return newArray
    .filter(newItem => {
      if (!newItem.branch) return false;
      return !prevArray.some(
        prevItem => prevItem.branch?.id === newItem.branch!.id && prevItem.role.id === newItem.role.id
      );
    })
    .map(item => ({
      branchId: item.branch!.id,
      employeeId,
      roleId: item.role.id
    }));
};