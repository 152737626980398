import React from 'react';
import {Modal} from 'react-bootstrap';
import NotificationStep from './NotificationStep';
import {useTranslation} from 'react-i18next';
import {checkStepDataType, getCheckStepData} from '../constants';
import {useReactiveVar} from '@apollo/client';
import {BranchProxy} from '@me-team/host/main/graphql/types';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

interface NotificationsModalProps {
  show: boolean;
  handleClose: () => void;
  branchData: BranchProxy;
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({
                                                                 show,
                                                                 handleClose,
                                                                 branchData
                                                               }) => {
  const {t} = useTranslation();
  const currentCompanyId: string = useReactiveVar(currentCompanyIdVar);
  const checkStepData: checkStepDataType[] = getCheckStepData(currentCompanyId, branchData);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className='px-1 w-100'
    >
      <Modal.Header
        closeButton
        className='border-0 align-items-center'
      >
        <Modal.Title className='h5'>{t('Getting started with the logbook')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center pb-4'>
        <div className='px-6  d-flex flex-column gap-3'>
          {checkStepData?.map((step: checkStepDataType) => (
            <NotificationStep key={step.id} checkStepData={step}/>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationsModal;