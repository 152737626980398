import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import RenderCompletionIcon from './RenderCompletionIcon';
import {useTranslation} from 'react-i18next';
import {checkStepDataType} from '../constants';
import {useNavigate} from 'react-router-dom';

interface NotificationStepProps {
  checkStepData: checkStepDataType;
}

const NotificationStep: React.FC<NotificationStepProps> = ({checkStepData}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='border rounded-1 border-secondary p-4'>
      <Row
        className='align-items-center'
      >
        <Col className="d-flex align-items-center">
          <RenderCompletionIcon isCompleted={checkStepData.completed} />
          <span className='fs-6 text-dark'>{t(checkStepData.title)}</span>
        </Col>
        <Col xs="auto">
          <Button
            variant="primary"
            className='w-100 py-1 fw-bold'
            style={{minWidth: '104px'}}
            onClick={() => navigate(checkStepData.route) }
            disabled={checkStepData.completed}
          >
            {t('Go')}
          </Button>
        </Col>
      </Row>
    </div>

  );
};

export default NotificationStep;