import { DateTime } from 'luxon';
import React from 'react';

interface WorkSchedule {
    __typename?: string;
    id: number;
    startDate: string;
    endDate: string;
}

export const compareDates = (schedule: WorkSchedule[], missed: string, installed: string) => {
    if (schedule.length === 0) {
        return (
            <div className="outline-orange text-orange w-100 h-100 p-1 border border-orange text-center rounded fs-7">
                {missed}
            </div>
        );
    }
    const dateObject = new Date(schedule[schedule.length - 1]?.endDate);
    const inputMilliseconds = dateObject.getTime();
    const currentMilliseconds = Date.now();

    if (inputMilliseconds > currentMilliseconds) {
        return (
            <div className="outline-green text-green w-100 h-100 p-1 border border-green text-center rounded fs-7">
                {installed}
            </div>
        );
    } else if (inputMilliseconds < currentMilliseconds) {
        return (
            <div className="outline-orange text-orange w-100 h-100 p-1 border border-orange text-center rounded fs-7">
                {missed}
            </div>
        );
    } else {
        return (
            <div className="outline-orange text-orange w-100 h-100 p-1 border border-orange text-center rounded fs-7">
                {missed}
            </div>
        );
    }
};

export const getNextDay = (dateString: string): string => {
    return DateTime.fromISO(dateString)
      .plus({ days: 1 })
      .toISODate();
};

