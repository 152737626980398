import {TFunction} from "i18next";
import {requirementsInfoType} from "../../interfaces/interfaces";

class RequirementsFileInfo {
    public static employeeFileRequirements = (companyId: number, translate: TFunction<"translation", undefined>, locale : string): requirementsInfoType => {
        const urls = this.getCommonUrls(companyId, 'employee', locale);
        const requirements = [
            this.getRequirement(translate, 'Name', 'character limit - 50', true),
            this.getRequirement(translate, 'Last name', 'character limit - 50'),
            this.getRequirement(translate, 'Middle name', 'character limit - 50'),
            this.getRequirement(translate, 'Date of birth', 'format dd.mm.yyyy (25.03.2001)'),
            this.getRequirement(translate, 'E-mail', 'format mail@mail.com', true),
            this.getRequirement(translate, 'Phone', 'format +00000000000', true),
            this.getRequirement(translate, 'Passport data', 'character limit - 10'),
            this.getRequirement(translate, 'SSN \\ NIN', 'character limit - 20'),
            this.getRequirement(translate, 'About myself', 'character limit - 400'),
            this.getRequirement(translate, 'Position', 'one of the positions in your company', true),
            this.getRequirement(translate, 'Specialization', 'character limit - 50', true),
            this.getRequirement(translate, 'Editing permissions', 'one of 2 values: Administrator, Employee', true)
        ];
        return {...urls, requirements};
    }

    public static clientFileRequirements = (companyId: number, translate: TFunction<"translation", undefined>, locale : string): requirementsInfoType => {
        const urls = this.getCommonUrls(companyId, 'client', locale);
        const requirements = [
            this.getRequirement(translate, 'Name', 'character limit - 50', true),
            this.getRequirement(translate, 'Last name', 'character limit - 50'),
            this.getRequirement(translate, 'Middle name', 'character limit - 50'),
            this.getRequirement(translate, 'Date of birth', 'format dd.mm.yyyy (25.03.2001)'),
            this.getRequirement(translate, 'E-mail', 'format mail@mail.com', true),
            this.getRequirement(translate, 'Phone', 'format +00000000000', true),
            this.getRequirement(translate, 'Gender', 'one of the values : male, female, other'),
            this.getRequirement(translate, 'Comment', 'character limit - 400')
        ];
        return {...urls, requirements};
    }

    public static serviceFileRequirements = (companyId: number, translate: TFunction<"translation", undefined>, locale : string): requirementsInfoType => {
        const urls = this.getCommonUrls(companyId, 'service', locale);
        const requirements = [
            this.getRequirement(translate, 'Name of the service', 'character limit - 50', true),
            this.getRequirement(translate, 'Category', 'one of the existing categories of companies', true),
            this.getRequirement(translate, 'Subcategory', 'one of the existing subcategories of the company'),
            this.getRequirement(translate, 'Description', 'character limit - 100'),
            this.getRequirement(translate, 'Duration', 'in hh:mm format with a step of 15 minutes, cannot be equal to 00:00', true),
            this.getRequirement(translate, 'Price', '6 decimal places and 2 decimal places (123456.12), the separator is a period', true),
            this.getRequirement(translate, 'Online booking', 'Allowed / Restricted')
        ];
        return {...urls, requirements};
    }

    //TODO change type serviceCategory in to type from back
    public static serviceCategoryFileRequirements = (companyId: number, translate: TFunction<"translation", undefined>, locale : string): requirementsInfoType => {
        const urls = this.getCommonUrls(companyId, 'service-category', locale);
        const requirements = [
            this.getRequirement(translate, 'Category name', 'character limit - 50', true),
            this.getRequirement(translate, 'Category description', 'character limit - 400'),
            this.getRequirement(translate, 'Subcategory name', 'character limit - 50'),
            this.getRequirement(translate, 'Subcategory description', 'character limit - 400')
        ];
        return {...urls, requirements};
    }

    private static getCommonUrls(companyId: number, type: string, locale : string) {
        const UK_LOCALE: string ='uk'
        return {
            templateUrl: `${process.env.REACT_APP_POS_URL}/import/${type}_template${locale === UK_LOCALE ? '_ua' : ''}.csv`,
            validationHeaderUrl: `${process.env.REACT_APP_POS_URL}/api/import/${type}/validate-file-structure/company/${companyId}`,
            uploadFileUrl: `${process.env.REACT_APP_POS_URL}/api/import/${type}/company/${companyId}/upload`,
        };
    }

    private static getRequirement(translate: TFunction, titleKey: string, descriptionKey: string, isRequirement: boolean = false) {
        return {
            title: translate(titleKey),
            description: translate(descriptionKey),
            isRequirement
        };
    }
}

export default RequirementsFileInfo;
