import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col,Container, Row , Navbar, Offcanvas, CloseButton } from "react-bootstrap";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import {useReactiveVar} from '@apollo/client';
import AccessService from "../../../services/AccessService";
import SidebarHeader from "../Sidebar/SidebarHeader";
import SidebarSubheader from "../Sidebar/SidebarSubheader";
import SidebarBranchList from "../Sidebar/SidebarBranchList";
import SidebarMenu from "../Sidebar/SidebarMenu";
import { refetchUserDataMenu } from "../../apollo/apollo-client";
import SocketConnection from "../../../../common/src/services/SocketConnection";
import {LoadingStatus} from "@me-team/common/src/components/LoadingStatus/LoadingStatus";
import {EntityType} from "@me-team/common/src/utils/enums";
import { branchesLength, isRefetchBranches } from '../../../main/branches/state';
import { useGetBranchesQuery } from '../../../main/branches/graphql/branches.hooks';
import {useGetCompanyAndRoleQuery, useGetCompanyModulesQuery} from '../../../main/company/graphql/company.hooks';
import {BranchProxy, JobContainerProxy, ModuleProxy} from '../../../main/graphql/types';
import { useGetJobsQuery } from '../../../main/jobs/graphql/jobs.hooks';
import PaymentExpired from "@me-team/common/src/components/PaymentExpired/PaymentExpired";
import {Debounce} from "@me-team/common/src/services/Debounce";
import '../../../services/joyride';
import useScrollToTop from "@me-team/common/src/hooks/useScrollToTop";
import SidebarService from "@me-team/common/src/services/SidebarService/SidebarService";
import {useUpdateUserMutation} from '../../../main/globalVar/graphql/globalVar.hooks';
import {
    currentBranchEmployeeIdVar,
    currentBranchIdVar,
    currentCompanyIdVar,
    currentUserRoleVar,
    userIdVar
} from '../../../main/globalVar/state';

export const App = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [updateUser] = useUpdateUserMutation();
    const basePath = process.env.REACT_APP_POS_URL;
    const [isShowNavbar, setShowNavbar] = useState<boolean>(false);
    const isRefetch = useReactiveVar(isRefetchBranches);
    const accessService = AccessService.getInstance().isPremium; // Працює раз через раз, хто писав хай гляне
    const userRole = useReactiveVar(currentUserRoleVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const userId = useReactiveVar(userIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currentBranchEmployeeId = useReactiveVar(currentBranchEmployeeIdVar);

    useScrollToTop(location?.pathname)

    const {data : userData, loading: loadingUserData, refetch: refetchCompanyAndRole  } = useGetCompanyAndRoleQuery({
        skip: !currentCompanyId,
        variables: { companyId: parseInt(currentCompanyId) },
        context: { errorType: "global" },
        onCompleted: (data) => {
            const accessServiceInstance = AccessService.getInstance();
            accessServiceInstance.init(data.user.company.paidFeatures);
            accessServiceInstance.setUserRole(data.user.role);
            accessServiceInstance.setRolesAccesses(data.role);
            currentBranchEmployeeIdVar(data?.user?.employee?.id);
        }
    });

    const { data, loading, refetch: refetchBranches } = useGetBranchesQuery({
        skip: !currentCompanyId || !currentBranchEmployeeId,
        variables: {
            itemsPerPage: 30,
            page: 1,
            branchTypeId: null,
            name: null,
            companyId: +currentCompanyId,
            employeeId: +currentBranchEmployeeId,
        },
        context: {
            errorType: 'global',
        },
        onCompleted: (data) => {
            const filteredBranches : BranchProxy[] | [] = data?.user?.company?.branchesPaginated?.branches?.filter(branch => !branch.deleted);
            branchesLength(filteredBranches?.length);
        },
        onError(error: Error) {
            console.error(error);
        }
    });

    const { data: modules, loading : loadingModules } = useGetCompanyModulesQuery({
        skip : !currentCompanyId,
        variables: { companyId: +currentCompanyId },
        context: {
            errorType: 'global',
            },
        }
    );
    const hasWarehouseModule = modules?.user?.company?.modules?.some((module: ModuleProxy) => module.name === "Warehouses");

    useEffect(() => {
        if (currentCompanyId && currentBranchEmployeeId) {
            refetchBranches();
            refetchUserDataMenu();
            isRefetchBranches(false);
        }
    }, [isRefetch, currentCompanyId, currentBranchEmployeeId]);

    const branches : BranchProxy[] | [] = useMemo(() => data?.user?.company?.branchesPaginated?.branches || [], [data]);

    const activeBranches : BranchProxy[] = useMemo(() => branches.filter((branch : BranchProxy) => !branch.deleted), [branches]);

    const companyMain = useMemo(() => userData?.user?.company,[userData]);
    const [jobList, setJobList] = useState<JobContainerProxy>(null);
    const [isLoadingStatusOpen, setIsLoadingStatusOpen] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(() => localStorage.getItem("isVisible") === "true");

    const {refetch : refetchJobs} = useGetJobsQuery({ variables : {companyId: parseInt(currentCompanyId)},
        skip : !currentCompanyId,
        onCompleted: (data) => {
            setJobList(data.job);
        }
    })

    useEffect(() => {
        localStorage.setItem('isVisible', isVisible.toString());
    }, [isVisible]);

    const debouncedRefetchJobs = useCallback(
        Debounce(() => {
            refetchJobs().then((data) => {
                setJobList(data.data.job);
                setIsVisible(true);
                setIsLoadingStatusOpen(true);
            });
        }, 500),
        [refetchJobs]
    );

    useEffect(() => {
        if (currentCompanyId) {
            new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
                if (data.entity === EntityType.IMPORT) {
                    debouncedRefetchJobs()
                }
                if (data.entity === EntityType.EXPORT) {
                    debouncedRefetchJobs()
                }
            });
        }
    }, [currentCompanyId, debouncedRefetchJobs]);

    const handleChangeBranch = async (branchId: number) => {
        try {
           const {data} = userId && currentBranchEmployeeId && await updateUser({
                variables: {
                    companyId: +currentCompanyId,
                    userId: +userId,
                    input: {
                        currentBranch: branchId
                    },
                    employeeId: currentBranchEmployeeId
                }
            });
            currentBranchIdVar(branchId);
            const userBranchRole = data.updateUser?.currentBranch?.employeesPaginated?.employees?.[0]?.userBranchRelations
                ?.find(item => item.branch?.id === branchId)
                ?.role;
            currentUserRoleVar(userBranchRole.id);
            const accessServiceInstance = AccessService.getInstance();
            accessServiceInstance.setUserRole(userBranchRole);

            navigate(`/branches/main/branch/${branchId}`);
        } catch (e) {
            console.error('Error changing branch:', e);
        }
    };

    const isRegistrationOrCreateOwner = location.pathname === "/registration" || location.pathname === "/create-owner";
    const hasAbilityToCreateBranch = userRole !== 5 && (accessService || activeBranches.length < 2);

    const handleCloseNavbar = () => setShowNavbar(false);
    const handleShowNavbar = () => setShowNavbar(true);

    if (loading || loadingUserData || loadingModules) return <></>

    const onCloseLoadingImportHandler = () => {
        const newValue = !isVisible;
        setIsVisible(newValue);
        localStorage.setItem('isVisible', newValue.toString());
    }

    return (
            <div className='pt-4'>
                <Row className='gx-0'>
                    { userId ? (
                        <Col xs={12} md={4} lg={3} xl={2} className='px-3'>
                            <div className="d-md-none">
                                <Navbar fixed="top" expand={false} className="bg-white position-fixed border-bottom">
                                    <Container fluid className="flex-nowrap">
                                        {isRegistrationOrCreateOwner ? (
                                            <>
                                                <Navbar.Toggle className="border-0 px-1" onClick={handleShowNavbar}>
                                                    <i className="bi bi-list fs-3"></i>
                                                </Navbar.Toggle>
                                                <div className="nav-item d-flex align-items-center flex-grow-1 justify-content-center text-truncate py-0">
                                                    <SidebarHeader isMobile />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Navbar.Toggle className="border-0 px-1" onClick={handleShowNavbar}>
                                                    <i className="bi bi-list fs-3"></i>
                                                </Navbar.Toggle>
                                                <div className="nav-item d-flex align-items-center flex-grow-1 justify-content-center text-truncate py-0">
                                                    <SidebarSubheader company={companyMain} isMobile />
                                                </div>
                                                <LanguageDropdown isMobile/>
                                            </>
                                        )}

                                        <Offcanvas show={isShowNavbar} onHide={handleCloseNavbar}>
                                            <Offcanvas.Header onHide className="border-bottom py-2">
                                                <CloseButton aria-label="Hide" className="m-0" onClick={handleCloseNavbar} />
                                                <Offcanvas.Title className="d-flex align-items-center flex-grow-1 justify-content-center text-truncate">
                                                    {isRegistrationOrCreateOwner ? <SidebarHeader isMobile /> : <SidebarSubheader company={companyMain} handleCloseNavbar={handleCloseNavbar} isMobile />}
                                                </Offcanvas.Title>
                                                {!isRegistrationOrCreateOwner && <LanguageDropdown handleCloseNavbar={handleCloseNavbar} isMobile/>}
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                {hasAbilityToCreateBranch && (
                                                    <div className="nav-item-menu rounded-1 bg-white py-0 mb-2">
                                                        <Link className={`nav-link-menu text-primary ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`} to={'create-branch'} onClick={handleCloseNavbar}><i
                                                            className='bi bi-plus-lg me-2'></i>{t("Add branch")}</Link>
                                                    </div>
                                                )}
                                                {!isRegistrationOrCreateOwner && <SidebarBranchList branches={branches} handleCloseNavbar={handleCloseNavbar} handleChangeBranch={handleChangeBranch}/>}
                                                <SidebarMenu accordionsData={SidebarService.accordionContentService(basePath, String(currentBranchId), currentCompanyId, String(userRole), t, hasWarehouseModule)} handleCloseNavbar={handleCloseNavbar} isRegistrationOrCreateOwner={isRegistrationOrCreateOwner}/>
                                                <PaymentExpired/>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </Container>
                                </Navbar>
                            </div>
                            <div className="d-none d-md-block menu-container">
                                <SidebarHeader />
                                {!isRegistrationOrCreateOwner && (
                                    <div className="d-flex justify-content-between mb-3 pe-3">
                                        <SidebarSubheader company={companyMain} />
                                    </div>
                                )}
                                {hasAbilityToCreateBranch && (
                                    <div className="nav-item-menu rounded-1 bg-white py-1">
                                        <Link className={`nav-link-menu text-primary ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`} to={'create-branch'}><i
                                            className='bi bi-plus-lg me-2'></i>{t("Add branch")}</Link>
                                    </div>
                                )}
                                {!isRegistrationOrCreateOwner && (
                                    <SidebarBranchList branches={branches} handleChangeBranch={handleChangeBranch}/>
                                )}
                                <SidebarMenu accordionsData={SidebarService.accordionContentService(basePath, String(currentBranchId), currentCompanyId, String(userRole), t,hasWarehouseModule)} isRegistrationOrCreateOwner={isRegistrationOrCreateOwner}/>
                                <PaymentExpired/>
                            </div>
                        </Col>
                    ) : null }
                    <Col xs={12} md={8} lg={9} xl={10} className='pt-5 pt-md-0 mx-auto'>
                        <Container fluid className="px-3 px-xxl-1">
                            <Col xs={12} xl={10} className="offset-xl-1">
                                <Outlet />
                            </Col>
                        </Container>
                    </Col>
                    {jobList && (jobList.importJobs.length !== 0 || jobList.exportJobs.length !== 0) &&
                        <div className={"position-fixed bottom-0 end-0 loading-wrapper"}>
                            <LoadingStatus jobList={jobList} isOpen={isLoadingStatusOpen} isVisible={isVisible} onClose={onCloseLoadingImportHandler}/>
                        </div>
                    }
                </Row>
            </div>
    );
};

