import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {
  BranchRoleRelationForEmployee, RelationForDelete, SetNewAccessSettingsState,
} from './types';
import ErrorService from '../../services/ErrorService';
import {useReactiveVar} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {getNextDay} from '../EmployeesList/utils/dateUtils';
import {DateTime} from 'luxon';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';
import {
  useGetBranchByIdLazyQuery, useGetBranchesByCompanyIdQuery,
  useGetRecordsForEmployeeByBranchLazyQuery
} from '@me-team/host/main/setUpAccessRole/graphql/setUpAccessRole.hooks';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {
  AccessDeletionBlockedPopup,
  AccessDeletionConfirmationModal,
  TooltipCustom
} from '@services/ui-components';
import AccessRightsModal
  from '../../../../../services/ui-components/src/Modal/AddOrUpdateAccessRightsModal/AccessRightsModal';
import {BookingMainProxy, EmployeeProxy} from '@me-team/host/main/graphql/types';
import {
  EmployeeInfo
} from '@services/ui-components/src/ModalDelete/AccessDeletionBlockedPopup/AccessDeletionBlockedPopup';
import {
  addAccessRights,
  getAvailableBranchesForOptions,
  updateAccessRightsArray
} from './constants';
import {UserRole} from '../../utils/enums';

interface AccessSettingsEmployeeTableProps {
  userBranchRelations: BranchRoleRelationForEmployee[];
  setNewAccessSettingsState: SetNewAccessSettingsState;
}

interface Option {
  value: number;
  label: string;
}

const AccessSettingsEmployeeTable: React.FC<AccessSettingsEmployeeTableProps> = ({
                                                                                   userBranchRelations,
                                                                                   setNewAccessSettingsState
                                                                                 }) => {
  const {t} = useTranslation();
  const {id} = useParams<{ id: string }>();
  const employeeId = Number(id);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const nextDay = useMemo(() => getNextDay(DateTime.now().toISODate()), []);
  const initAccessSettingsCurrentState: BranchRoleRelationForEmployee[] = userBranchRelations && userBranchRelations

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [showEditAccessRightsModal, setShowEditAccessRightsModal] = useState<boolean>(false);
  const [showAddAccessRightsModal, setShowAddAccessRightsModal] = useState<boolean>(false);
  const [branchAndRoleRelation, setBranchAndRoleRelation] = useState<BranchRoleRelationForEmployee>(null);
  const [showAccessDeletionBlockedPopup, setShowAccessDeletionBlockedPopup] = useState<boolean>(false);
  const [showAccessDeletionConfirmationModal, setShowAccessDeletionConfirmationModal] = useState<boolean>(false);
  const [branchOptions, setBranchOptions] = useState<Option[]>([]);
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfo>(null);
  const [accessSettingsCurrentState, setAccessSettingsCurrentState] = useState<BranchRoleRelationForEmployee[]>(initAccessSettingsCurrentState);
  const [selectedBranchForDelete, setSelectedBranchForDelete] = useState<RelationForDelete>(null);
  const isDisablingDeleteButton = accessSettingsCurrentState?.length < 2;

  const handleToggleAccessDeletionBlockedPopup = () => setShowAccessDeletionBlockedPopup(prevState => !prevState);
  const handleToggleAccessDeletionConfirmationModal = () => setShowAccessDeletionConfirmationModal(prevState => !prevState);
  const handleOpenEditAccessRightsModal = (relation: BranchRoleRelationForEmployee) => {
    setBranchAndRoleRelation(relation)
    setShowEditAccessRightsModal(true);
  }
  const handleCloseEditAccessRightsModal = () => {
    setShowEditAccessRightsModal(false)
  }
  const handleToggleAddAccessRightsModal = () => setShowAddAccessRightsModal(prevState => !prevState);

  const [GetBranchById, {
    data: branchByIdData
  }] = useGetBranchByIdLazyQuery({
      context: {errorType: 'local'},
      onError: (error) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
      },
    }
  );

  const {
    data: branchesByCompanyIdData,
    loading: branchesByCompanyIdLoading
  } = useGetBranchesByCompanyIdQuery({
      variables: {companyId: currentCompanyId},
      skip: !currentCompanyId,
      context: {errorType: 'local'},
      onCompleted: (data) => {
        const branchesOptionsArr: Option[] = getAvailableBranchesForOptions(data.companyBranches, initAccessSettingsCurrentState)
        setBranchOptions(branchesOptionsArr)
      },
      onError: (error) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
      },
    }
  );

  const [GetRecordsForEmployeeByBranch, {
    data: activeBookingsData
  }] = useGetRecordsForEmployeeByBranchLazyQuery({
      context: {errorType: 'local'},
      onError: (error) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
      },
    }
  );

  useEffect(() => {
    setNewAccessSettingsState(accessSettingsCurrentState)
  }, [accessSettingsCurrentState]);

  const handleRemoveAccess = async (relation: BranchRoleRelationForEmployee) => {
    const {data} = currentCompanyId && employeeId && await GetRecordsForEmployeeByBranch({
      variables: {
        companyId: +currentCompanyId,
        employeeId: employeeId,
        removeCanceledBookings: false,
        branchId: relation.branch.id,
        startDate: nextDay,
      },
    })
    if (!data) return
    const employeeData: EmployeeProxy = data?.user?.company?.employees?.employees?.[0]
    const bookingsData: BookingMainProxy[] = employeeData?.bookings?.bookings;
    const isBookings: boolean = bookingsData && employeeData && bookingsData?.length > 0;
    if (isBookings) {
      const info: EmployeeInfo = {
        name: employeeData?.name,
        surname: employeeData?.surname ? employeeData?.surname : null,
        branch: bookingsData?.[0].branch?.name,
        branchId: bookingsData?.[0].branch?.id,
      }
      setEmployeeInfo(info)
      info && setShowAccessDeletionBlockedPopup(true)
    } else {
      const employeeNameSurname: string = `${employeeData?.name} ${employeeData?.surname}`
      setEmployeeInfo(null);
      setSelectedBranchForDelete({
        employeeNameSurname: employeeNameSurname,
        relation: relation
      })
      setShowAccessDeletionConfirmationModal(true)
    }
  }

  const handleRemoveAccessRight = () => {
    const updateAccessRight: BranchRoleRelationForEmployee[] = accessSettingsCurrentState?.filter(item => item.branch.id !== selectedBranchForDelete?.relation?.branch?.id);
    setAccessSettingsCurrentState(updateAccessRight)
    handleToggleAccessDeletionConfirmationModal()
  }

  const handleEditAccessRights = (formData: BranchRoleRelationForEmployee) => {
    const accessRightsArray: BranchRoleRelationForEmployee[] = updateAccessRightsArray(accessSettingsCurrentState, formData)
    accessRightsArray && setAccessSettingsCurrentState(accessRightsArray)
    handleCloseEditAccessRightsModal()
  }

  const handleAddAccessRights = (formData: BranchRoleRelationForEmployee) => {
    const updatedBranchOptions = branchOptions.filter(option => option.value !== formData.branch.id);
    const newAccessRights: BranchRoleRelationForEmployee[] = addAccessRights(accessSettingsCurrentState, formData)
    newAccessRights && setAccessSettingsCurrentState(newAccessRights)
    setBranchOptions(updatedBranchOptions)
    handleToggleAddAccessRightsModal()
  }

  if (!currentCompanyId || branchesByCompanyIdLoading) return <Spinner/>;

  return (
    <>
      <Row>
        <Col>
          <h4 className='fw-normal mb-4 mt-5'>{t('Access settings')}</h4>
        </Col>
      </Row>
      <Container fluid className='table-responsive scrollbar position-relative px-0'>
        <table className='table mb-0'>
          <thead>
          <tr className='fs-7'>
            <th className='align-middle col-6 ps-3 py-2'>
            <span>
              {t('Branch name')}
            </span>
            </th>
            <th className='align-middle col-5 py-2'>
            <span>
              {t('Access rights (role)')}
            </span>
            </th>
            <th className='align-middle col-1 py-2'></th>
          </tr>
          </thead>
          <tbody>
          {accessSettingsCurrentState?.length > 0 ? accessSettingsCurrentState.map((relation) => {
            const isOwner = relation.role.id === UserRole.OWNER;
            return (
              <tr key={relation.branch.id}>
                <td className='align-middle ps-3 py-4'>
                  <span>
                    {relation.branch.name}
                  </span>
                </td>
                <td className='align-middle py-4'>
                  <span>
                    {relation.role.name}
                  </span>
                </td>
                <td className='align-middle text-end pe-0 py-3'>
                  {!isOwner ?
                    <div
                      className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                      <TooltipCustom placement='bottom' text={t('Edit access')}>
                        <Button
                          className='btn btn-square btn-sm p-2'
                          style={{width: '39px'}}
                          variant='outline-secondary'
                          onClick={() => handleOpenEditAccessRightsModal(relation)}
                        >
                          <i className='bi bi-pencil text-dark'></i>
                        </Button>
                      </TooltipCustom>
                      <TooltipCustom placement='bottom' text={t('Remove access to branch')}>
                        <Button
                          className='btn btn-sm btn-square p-2'
                          style={{width: '39px'}}
                          variant='outline-secondary'
                          onClick={() => handleRemoveAccess(relation)}
                          disabled={isDisablingDeleteButton}
                        >
                          <i
                            className={`bi bi-trash3 ${isDisablingDeleteButton ? 'text-secondary' : 'text-dark'}`}></i>
                        </Button>
                      </TooltipCustom>
                    </div> : null
                  }
                </td>
              </tr>
            )
          }) : null}
          </tbody>
        </table>
      </Container>
      {branchOptions?.length > 0 ?
        <Row>
          <Col>
            <Button
              variant='outline-primary'
              className='mt-4 py-1'
              onClick={handleToggleAddAccessRightsModal}
            >
              <i className="bi bi-plus-lg me-2"></i>
              {t('Add a branch')}
            </Button>
          </Col>
        </Row> : null
      }

      <AccessRightsModal
        action='update'
        showModal={showEditAccessRightsModal}
        onClose={handleCloseEditAccessRightsModal}
        branchAndRoleRelation={branchAndRoleRelation}
        onSubmit={handleEditAccessRights}
      />
      <AccessRightsModal
        action='add'
        showModal={showAddAccessRightsModal}
        onClose={handleToggleAddAccessRightsModal}
        branchOptions={branchOptions}
        onSubmit={handleAddAccessRights}
      />
      <AccessDeletionBlockedPopup
        title={t('Deletion is not possible')}
        show={showAccessDeletionBlockedPopup}
        handleClose={handleToggleAccessDeletionBlockedPopup}
        employeeInfo={employeeInfo}
      />
      <AccessDeletionConfirmationModal
        show={showAccessDeletionConfirmationModal}
        handleClose={handleToggleAccessDeletionConfirmationModal}
        selectedBranchForDelete={selectedBranchForDelete}
        onDelete={handleRemoveAccessRight}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default AccessSettingsEmployeeTable;