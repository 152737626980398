import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from '@apollo/client';
import { Row, Col, Button } from "react-bootstrap";
import error404 from "../../images/errors/error404.png";
import ContactUs from "./ContactUs";
import {
    currentBranchIdVar,
    currentCompanyIdVar,
    userIdVar
} from '@me-team/host/main/globalVar/state';


const Page404: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const userId = useReactiveVar(userIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const basePath = process.env.REACT_APP_POS_URL;

    const handleButtonNavigate = () => {
        userId ? navigate(-1) : window.location.href = `${basePath}/login`;
    };

    const handleButtonOnMain = () => {
        userId
            ? window.location.href = `${basePath}/branch/company/${currentCompanyId}/${currentBranchId}`
            : window.location.href = basePath
    };

    return (<>
        <Row className="align-items-center text-dark py-5 bg-white">
            <Col xs={12} lg={6} className="my-auto order-2 order-lg-0">
                <h1 className="error-heading text-violet-light fw-bold d-none d-lg-block mb-4">404</h1>
                <h2 className="text-capitalize fw-bold text-center text-lg-start mb-3">{t("This page not found")}</h2>
                <Col xs={12} sm={10} className="mx-auto mx-lg-0 mb-5">
                    <p className="text-grey text-center text-lg-start mb-0 me-lg-4">
                        {t("Make sure you enter the correct web address in the address bar and refresh the page, or return to the main menu. If you think it's a mistake, please contact us:")}{" "}
                        <a className="text-primary fw-bold text-decoration-none" href="mailto:support@me-pos.com">support@me-pos.com</a>
                    </p>
                </Col>
                <Row className="col-xxl-8">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                        <Button
                            onClick={handleButtonNavigate}
                            variant="success"
                            className="fs-7 btn btn-success text-white fw-bold rounded-3 w-100">
                            {userId ? t("Back") : t("Authorization")}</Button>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Button
                            onClick={handleButtonOnMain}
                            variant="outline-success"
                            className="fs-7 text-capitalize fw-normal rounded-3 w-100">
                            {t("Main menu")}</Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center py-5">
                <img src={error404} width="531" height="586" loading="lazy" className="img-fluid" alt="404 Error"/>
            </Col>
        </Row>
        <ContactUs/>
    </>)
}

export default Page404;
