import React, {useState} from 'react';
import {InfoBanner, TableHeader} from '@services/ui-components';
import {Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import UpdateRecoveryServiceForm from './UpdateRecoveryServiceForm';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../../services/ErrorService';
import { useGetOneServiceQuery } from '@me-team/host/main/services/graphql/services.hooks';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

const UpdateService: React.FC = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {
    data: oneServiceData,
    error,
    loading
  } = useGetOneServiceQuery({
    variables: {
      id: +id,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });
  const service = oneServiceData?.user?.company?.services?.services[0];

  if (loading || !currentCompanyId) return <Spinner/>;

  return (
    <div className='mb-3'>
      <InfoBanner>
        <p className='fs-7 mb-0'>{t('On this page, you can create a new service. After creating' +
          ' it, be sure to select the related employees to make the service available for booking.')}</p>
      </InfoBanner>
      <TableHeader
        title={`${t('Editing Service')} ${service?.name}`}
        paths={[
          {path: `/services/service/${currentCompanyId}/service-list/${id}/edit`, label: t('services')},
          {path: `/services/service/${currentCompanyId}/service-list`, label: t('Service list')},
          {path: `/services/service/${currentCompanyId}/service-list/${id}/edit`, label: `${t('Editing Service')} ${service?.name}`},
        ]}
        colSize={12}
      />
      <Row>
        <UpdateRecoveryServiceForm/>
      </Row>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default UpdateService
