import React from 'react';
import {useTranslation} from 'react-i18next';
import {RelationForDelete} from '@me-team/common/src/components/EditingEmployee/types';
import ModalDelete from '../ModalDelete';

interface AccessDeletionConfirmationModalProps {
  show: boolean;
  handleClose: () => void;
  selectedBranchForDelete: RelationForDelete;
  onDelete: () => void;
}

const AccessDeletionConfirmationModal: React.FC<AccessDeletionConfirmationModalProps> = ({
                                                                                           show,
                                                                                           handleClose,
                                                                                           selectedBranchForDelete,
                                                                                           onDelete
                                                                                         }) => {
  const {t} = useTranslation();

  return (
    <ModalDelete
      show={show}
      handleClose={handleClose}
      title={t('Remove access to the branch')}
      body={t('removeAccessConfirmation', {
        branchName: selectedBranchForDelete?.relation?.branch?.name,
        roleName: selectedBranchForDelete?.relation?.role?.name,
        employeeName: selectedBranchForDelete?.employeeNameSurname,
    })}
      buttons={[
        {
          text: t('cancel'),
          onClick: () => handleClose(),
          variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
          className: 'd-flex justify-content-center align-items-center w-100',
        },
        {
          text: t('delete'),
          onClick: () => onDelete(),
          variant: 'primary',
          className: 'w-100',
        }
      ]}
    />
  );
};

export default AccessDeletionConfirmationModal;