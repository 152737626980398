import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { Col, OverlayTrigger, Tooltip, Button, Row, Popover } from 'react-bootstrap';
import i18next from "i18next";
import { ErrorModalComponent } from "@me-pos/error-modal";
import { useReactiveVar } from '@apollo/client';
import {TableHeader} from '@services/ui-components';
import CreatePositionModal from '../ui-components/CreatePositionModal/CreatePositionModal';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import { addEmployeeFormValidationSchema } from '../AddEmployee/constants/constants';
import CustomCreatableReactSelect from '@services/ui-components/src/select/CustomCreatableReactSelect';
import PhonePicker from '@services/ui-components/src/PhonePicker/PhonePicker';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import DropItem from '@services/ui-components/src/DropItem/DropItem';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import ProgressBar from '@services/ui-components/src/ProgressBar/ProgressBar';
import DropPhotosMultiply from '../DropPhotosMultiply/DropPhotosMultiply';
import ErrorService from "../../services/ErrorService";
import CustomReactSelect from "@services/ui-components/src/select/CustomReactSelect";
import useNavigateOnRole from "../../hooks/useNavigateOnRole";
import {
    useCreateEmployeeMutation,
    useGetEmployeesPositionsQuery, useGetRolesQuery,
    useGetSpecSpecializationQuery
} from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import { useGetCompanyIdQuery } from '@me-team/host/main/employeeList/graphql/employeeList.hooks';
import { RoleProxy } from '@me-team/host/main/graphql/types';
import { isRefetchBranches } from '@me-team/host/main/branches/state';
import {useGetCountryGeoQuery} from "@me-team/host/main/branches/graphql/branches.hooks";
import {useGetUserQuery} from '@me-team/host/main/globalVar/graphql/globalVar.hooks';
import {
    currentBranchEmployeeIdVar,
    currentCompanyIdVar,
    currentUserEmailVar
} from '@me-team/host/main/globalVar/state';
import {refetchUserDataMenu} from '@me-team/host/src/apollo/apollo-client';

interface IStep {
    key: string;
    label: string;
}

const steps = (t: any): IStep[] => [
    { key: 'registration', label: t('Registration') },
    { key: 'create-company', label: t('Create company') },
    { key: 'create-owner', label: t('Create owner') },
];

type TransformedRole = {
    value: string;
    label: string;
};

const OwnerCreation: React.FC = () => {
    const { t } = useTranslation()
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const navigate = useNavigate();
    const stepsArray = steps(t);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [date] = useState('');
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [photosQualification, setPhotosQualification] = useState<(File | null)[]>([null]);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<null | any>(null);
    const [newPossition, setNewPossition] = useState(null);
    const [key, setKey] = useState(0);
    const currentUserEmail = useReactiveVar(currentUserEmailVar)
    useNavigateOnRole()
    const { data: roles, loading: loadingRoles } = useGetRolesQuery();
    const { data: user, loading: loadingUser, refetch: refetchUser } = useGetUserQuery({
        onCompleted: (data) => {
            if(data?.user?.companies[0]?.id && !currentCompanyId) {
                currentCompanyIdVar(String(data.user.companies[0].id))
            }
        },
        onError: (error) => {
            console.error("Error fetching user data", error);
        }
    });
    currentCompanyId && refetchUserDataMenu()
    const { data: countryGeoData, loading: loadingCountryGeo} = useGetCountryGeoQuery({});
    const handleChange = (date: Date | null, setFieldValue: (field: string, value: string) => void) => {
        if (date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const formattedDateString = `${year}-${month}-${day}`;
            setStartDate(formattedDate);
            setFieldValue('dateOfBirth', formattedDateString);
        }
    };

    const { data: possitions, loading: loadingPossitions, refetch: refetchPositions } = useGetEmployeesPositionsQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
    });
    const { data: dataSpecialization, loading: loadingSpecialization } = useGetSpecSpecializationQuery();
    const specialization = dataSpecialization?.employeeSpecializations;
    const { data: companyId, loading: loadingCompanyId, refetch: refetchCompanyId } = useGetCompanyIdQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
        context: {
            errorType: 'global',
        },
    });

    useEffect(() => { 
        if(!currentCompanyId) {
            refetchUser()
        }
     }, [refetchUser, currentCompanyId]);
    const [createEmployee] = useCreateEmployeeMutation();

    const handlePositionModal = () => {
        setShowPositionModal(prevState => !prevState);
        setKey(prevKey => prevKey + 1);
    };

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 14);
    const countryGeo = countryGeoData?.countryGeo?.country;
    const transform = (original: RoleProxy): TransformedRole => ({
        value: original?.name,
        label: original?.name
    });

    const waitForValidIds = async (maxRetries: number = 5, retryDelay: number = 1000): Promise<{ idCompany: number; branch: number }> => {
        await refetchUser();
        let idCompany = +currentCompanyId;
        let branch = user?.user?.currentBranch?.id;

        if (typeof idCompany === 'number' && typeof branch === 'number') {
            return { idCompany, branch };
        }
        if (maxRetries <= 0) {
            throw new Error('Failed to retrieve valid company or branch ID.');
        }
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        return waitForValidIds(maxRetries - 1, retryDelay);
    };

    if (loadingPossitions || loadingSpecialization || loadingCompanyId || loadingRoles || loadingUser
        ||  typeof !companyId?.user?.company?.ownerPosition?.id === 'number'
        || loadingCountryGeo
    ) return <Spinner />

    return (
        <>
            <ProgressBar currentStep={2} steps={stepsArray} />
            <VioletBadge>
                {t('Required fields are marked with an asterisk *. The data that customers see in the online record form is marked ')}
                {' '} <i className="bi bi-info-circle"></i>
            </VioletBadge>
            <TableHeader
                title={t('Add owner')}
            />
            <Formik
                initialValues={{
                    company: companyId?.user?.company?.id,
                    name: '',
                    surname: '',
                    patronymic: '',
                    dateOfBirth: date,
                    email: currentUserEmail,
                    phone: countryGeo?.phoneCode?.code ? `+${countryGeo?.phoneCode?.code}` : '',
                    passportData: '',
                    itn: '',
                    aboutMe: '',
                    employeePosition: companyId?.user?.company?.ownerPosition?.id,
                    specialization: '',
                    role: 1,
                    status: 3,
                    manualBlocking: true,
                    tariffBlocking: true,
                }}
                validationSchema={addEmployeeFormValidationSchema(t)}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await refetchUser();
                        const { idCompany, branch } = await waitForValidIds();
                        const cleanedPhotosQualification = photosQualification?.filter(photo => photo != null);
                        const updatedValues = {
                            ...values,
                            qualificationConfirmations: [...cleanedPhotosQualification],
                            company: idCompany
                        };
                        const response = await createEmployee({
                            variables: {
                                companyId: idCompany,
                                input: updatedValues
                            }
                        });
                        if (response?.data) {
                            const employeeId: number = response?.data?.createEmployee?.company?.employeesList?.[0].id;
                            isRefetchBranches(true);
                            currentBranchEmployeeIdVar(employeeId)
                            navigate(`/branches/main/branch/${branch}`, { state:  {  creat: true } });
                        }
                    } catch (error) {
                        console.error('Error creating employee:', error);
                        const graphQLError = error.graphQLErrors?.[0];

                        if (graphQLError?.extensions?.validation?.phone?.[0]?.message === 'This value is not a valid phone number.') {
                            setErrors({ phone: t('Invalid phone number') });
                        } else if (graphQLError?.message === "Employee already exists") {
                            setErrors({ email: `Email  ${values.email} ${t('already exists in system.')}` });
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, touched, setFieldValue, values, handleSubmit }) => (
                    <>
                        <CreatePositionModal
                            key={key}
                            show={showPositionModal}
                            handleModal={handlePositionModal}
                            companyId={companyId?.user?.company?.id}
                            refetch={refetchPositions}
                            setNewPossition={setNewPossition}
                            setFieldValue={setFieldValue}
                        />
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="d-flex flex-column flex-lg-row">
                                    <Col xs={12} lg={9} className="me-0">
                                        <div className="row ">
                                            <h4 className="fw-normal">{t('Personal data')}</h4>
                                        </div>
                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-name-field"
                                                            className="mb-0 text-dark form-label required">{t('Name')}</label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        id="addOwner-name-field"
                                                        placeholder={t('Add Name')}
                                                        className={`fs-7 form-control ${errors?.name && touched?.name ? "border-danger" : ""}`}
                                                        maxLength="50"
                                                    />
                                                    <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                            <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                <i className="bi bi-info-circle"></i>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="name">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-surname-field"
                                                            className="mb-0 text-dark form-label required">{t('Last name')} </label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        type="text"
                                                        name="surname"
                                                        id="addOwner-surname-field"
                                                        placeholder={t('Add Last name')}
                                                        className={`fs-7 form-control ${errors.surname && touched.surname ? "border-danger" : ""}`}
                                                        maxLength="50"
                                                    />
                                                    <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                            <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                <i className="bi bi-info-circle"></i>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="surname">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-middleName-field"
                                                            className="mb-0 text-dark form-label required">{t('Middle name')}</label>
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        type="text"
                                                        name="patronymic"
                                                        id="addOwner-middleName-field"
                                                        placeholder={t('Add Middle name')}
                                                        className="fs-7 form-control"
                                                        maxLength="50"
                                                    />
                                                </div>
                                                <ErrorMessage name="patronymic">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-date-field"
                                                            className="mb-0 text-dark form-label required">{t('Date of birth')} </label>
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <DatePicker
                                                        id='addOwner-date-field'
                                                        className="form-control w-100"
                                                        wrapperClassName='w-100'
                                                        selected={startDate}
                                                        placeholderText={t('dd.mm.yyyy')}
                                                        onChange={(date) => handleChange(date, setFieldValue)}
                                                        dateFormat="dd.MM.yyyy"
                                                        maxDate={maxDate}
                                                        showYearDropdown
                                                        yearDropdownItemNumber={124}
                                                        scrollableYearDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-email-field"
                                                            className="mb-0 text-dark form-label required">{t('E-mail')}</label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        className={`fs-7 form-control ${errors.email && touched.email ? "border-danger" : ""}`}
                                                        maxLength="50"
                                                        value={currentUserEmail}
                                                    />
                                                    <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                        <i className="bi bi-shield-check"
                                                           style={{ paddingRight: '12px' }}> </i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="email">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-phone-field"
                                                            className="mb-0 text-dark form-label required">{t('Phone')} </label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <PhonePicker
                                                    name='phone'
                                                    id='addOwner-phone-field'
                                                    value={values.phone}
                                                />
                                                <ErrorMessage name="phone">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-passportData-field"
                                                            className="mb-0 text-dark form-label required">{t('Passport Data')}  </label>
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        type="text"
                                                        name="passportData"
                                                        placeholder={t('Enter Passport Data')}
                                                        className="fs-7 form-control"
                                                        maxLength="50"
                                                        id="addOwner-passportData-field"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-lg-10 ms-auto">
                                                <ErrorMessage name="passportData">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                <span
                                                    className="text-grey fs-7"> {values.passportData.length}/10</span>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-ssn-field"
                                                            className="mb-0 text-dark form-label required">{t('SSN (Social Security Number)')}  </label>
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        type="text"
                                                        name="itn"
                                                        placeholder={t('Enter SSN')}
                                                        className="fs-7 form-control"
                                                        maxLength="20"
                                                        id="addOwner-ssn-field"
                                                    />
                                                </div>
                                                <ErrorMessage name="itn">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addOwner-about-field"
                                                            className="mb-0 text-dark form-label required">{t('About')} </label>
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field id="addOwner-about-field" as="textarea" name='aboutMe' className="form-control"
                                                        placeholder={t('Enter a short description for the master presentation')} />
                                                    <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                            <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                <i className="bi bi-info-circle"></i>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <ErrorMessage name="aboutMe">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-10 ms-auto">
                                                <span className="text-grey fs-7">
                                                    {values.aboutMe.length} /400
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <h4 className="text-dark fw-normal">{t('Professional Information')}</h4>
                                        </div>
                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="position-field"
                                                            className="mb-0 text-dark form-label required">{t('Position')}</label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '} *
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <div className='d-flex align-items-center w-100'>
                                                        <div className=' w-100 position-relative'>
                                                            <CustomReactSelect
                                                                key={key}
                                                                optionsArray={possitions?.user?.company?.employeePositions?.employeePositions}
                                                                placeholder={t('Owner')}
                                                                setField={setFieldValue}
                                                                fieldName="employeePosition"
                                                                value="id"
                                                                isTooltip={true}
                                                                initialValue={newPossition || transform(roles?.role[0])}
                                                                name={"position-field"}
                                                                id={'addOwner-position-field'}
                                                            />
                                                            <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Popover >
                                                                            <Popover.Body  >
                                                                                <Row>
                                                                                    <p className="m-0 mb-1" >{t('The position is displayed inside the system, and the specialization is in the form of an online record.')} </p>
                                                                                </Row>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                        <i className="bi bi-info-circle"></i>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                type="button"
                                                                onClick={handlePositionModal}
                                                                variant="outline-gray"
                                                                className=" text-dark ms-2  px-3 border square-btn"
                                                                style={{ minWidth: '50px', height: '50px' }}
                                                            >
                                                                <i className="bi bi-plus-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="employeePosition">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label htmlFor="specialization-field" className="mb-0 text-dark form-label required">
                                                            {t('Specialization')}<span
                                                                className="text-danger"> {' '}*  </span>
                                                        </label>
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <CustomCreatableReactSelect
                                                        optionsArray={specialization}
                                                        placeholder={t('Enter specialization')}
                                                        setField={setFieldValue}
                                                        fieldName="specialization"
                                                        value='name'
                                                        isTooltip={true}
                                                        name={"specialization-field"}
                                                        id={"addOwner-specialization-field"}
                                                    />
                                                    <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                            <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                <i className="bi bi-info-circle"></i>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-10 ms-auto">
                                                <span className="text-grey fs-7"> {values.specialization.length}/50</span>
                                                <ErrorMessage name="specialization">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-3">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <label  htmlFor="registration-role-field" className="form-label mb-0 text-dark fw-bold">
                                                    <small>
                                                        {t('Editing Permissions')}
                                                        <span className="text-danger"> {' '}* </span>
                                                    </small>
                                                </label>
                                            </div>
                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="position-relative">
                                                    <Field
                                                        id="editing-role-field"
                                                        type="text"
                                                        name="role-permissions"
                                                        placeholder={t('Owner')}
                                                        className="fs-7 form-control"
                                                        maxLength="50"
                                                        disabled
                                                    />
                                                    <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Popover >
                                                                    <Popover.Body  >
                                                                        <Row>
                                                                            <p className="m-0 mb-1" > {t('"Editing permissions" - defines what access the employee has.')} </p>
                                                                        </Row>
                                                                        <Row>
                                                                            <p className="m-0 mb-1" > {t('Owner - unlimited access to all sections.')}</p>
                                                                        </Row>
                                                                        <Row>
                                                                            <p className="m-0 mb-1" >{t('Administrator - access to all sections except "Tariff Management."')}</p>
                                                                        </Row>
                                                                        <Row>
                                                                            <p className="m-0 mb-1" >{t('Employee - has limited rights, he can only view his schedule, his record log, information about himself and the services he performs.')}  </p>
                                                                        </Row>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <div className="position-absolute top-50 translate-middle-y end-0 me-3">
                                                                <i className="bi bi-info-circle"></i>
                                                            </div>
                                                        </OverlayTrigger>

                                                    </div>
                                                </div>

                                                <ErrorMessage name="role">{msg => <p
                                                    className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                            </div>

                                        </div>
                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label htmlFor="registration-qualificationConfirmations-field" className="mb-0 text-dark form-label required">{t('Qualification Confirmation')}</label>
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <div className="mb-0 ps-sm-1 mb-2 text-dark">
                                                    <span className="fs-7">
                                                        {t('Add JPEG images of diplomas, certificates')}{' '}
                                                    </span>
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                        <i className="bi bi-info-circle" style={{ paddingRight: '12px' }}></i>
                                                    </OverlayTrigger>
                                                </div>
                                                <Row className='m-0'>
                                                    <DropPhotosMultiply
                                                        photosArray={photosQualification}
                                                        setPhotos={setPhotosQualification}
                                                    />
                                                </Row>
                                            </div>
                                            <div className="col-12 col-lg-10 ms-auto">
                                                <span className="text-grey fs-7"> {t('Maximum size: 3 MB')}</span>
                                            </div>
                                        </div>

                                        <div className="row mt-lg-4">
                                            <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label htmlFor="registration-contract-field" className="mb-0 text-dark form-label required"> {t('Contract')}</label>
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="col-12 col-lg-10 pe-lg-5">
                                                <DropItem
                                                    index={0}
                                                    handleFile={setFieldValue}
                                                    fieldName={"contract"}
                                                    file={true}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-10 ms-auto">
                                                <span
                                                    className="text-grey fs-7">{t('Document formats: PDF, DOC, DOCX')}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} lg={3} className="ps-lg-3 ps-0 mt-4 mt-lg-0">
                                        <h4 className="text-dark pb-3 mt-1">
                                            {t('Owner\'s photo')}
                                        </h4>
                                        <ImageCrop setFieldValue={setFieldValue} modalTitle={t('Owner\'s photo')} notBGinfo />
                                    </Col>
                                </div>
                                <div className="d-sm-flex mb-5 mt-3">
                                    <Button type="submit" variant="primary" className="fw-bold custom-btn rounded-1">
                                        {t('save')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik >
            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
        </>
    );
};

export default OwnerCreation;
