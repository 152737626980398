import React, {ReactElement} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {right} from '@popperjs/core';
import Tooltip from 'react-bootstrap/Tooltip';
import {UserBranchRelationProxy} from '@me-team/host/main/graphql/types';
import RenderBranches from './RenderBranches';

interface TooltipAvailableBranchesProps {
  children?: ReactElement;
  userBranchRelations: UserBranchRelationProxy[];
}

const TooltipAvailableBranches: React.FC<TooltipAvailableBranchesProps> = ({
                                                                             children,
                                                                             userBranchRelations
                                                                           }) => {
  const renderTooltip = () => <Tooltip
    id='tooltip'
    className='pointer-events-none'
  >
    <RenderBranches userBranchRelations={userBranchRelations} />
  </Tooltip>;
  return (
    <OverlayTrigger
      placement={right}
      overlay={renderTooltip()}
      container={document.body}
      flip
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipAvailableBranches;