import {SalaryType} from "@me-team/host/main/graphql/types";

export interface SalarySection {
    id:  string | number;
    categoryId?: string | number;
    subCategoryId?: string | number;
    salary: number;
    salaryType: string;
    categoryName?: string;
    subCategoryName?: string;
    selectedAllCategories?: boolean;
    update?: boolean;
    errorCategory?: boolean;
    salaryError?: boolean;
    errorMessage?: string;
    mutation?: string;
    currSign?: string
}

interface Employee {
    id: number;
    ServiceCategorySalaries?: ServiceCategorySalaryProxy[];
    name?: string;
    surname?: string;
}

interface ServiceCategorySalaryProxy {
    id: number;
    salaryValue: number;
    salaryType: SalaryType;
    serviceCategory: { id: number; name: string }[];
    selectedAll: boolean;
    deleted: boolean;
}

export const validateSalarySections = (
    salarySections: SalarySection[],
    checkbox: boolean,
    t:  (key: string) => string
): { hasErrors: boolean; updatedSections: SalarySection[] } => {
    let hasErrors = false;

    const updatedSections = salarySections.map((section) => ({
        ...section,
        errorCategory: false,
        salaryError: false,
        errorMessage: '',
    }));

    const seenCategories = new Map<string, number[]>();


    updatedSections.forEach((section, index) => {
        const { categoryId, subCategoryId, salaryType, salary } = section;
        let sectionHasError = false;
        const errorCategory = !checkbox && !categoryId && !subCategoryId;
        if (errorCategory) {
            hasErrors = true;
            sectionHasError = true;
            section.errorCategory = true;
            section.errorMessage = t('Fill this field');
        }
        const salaryError = !(
            salaryType === '%'
                ? salary > 0 && salary <= 100
                : salary > 0
        );
        if (salaryError) {
            hasErrors = true;
            sectionHasError = true;
            section.salaryError = true;
            section.errorMessage =
                salaryType === '%' && salary > 100
                    ? t('Enter a value between 0 and 100.')
                    : t('Fill this field');
        }

        const key = `${categoryId || 'null'}-${subCategoryId || 'null'}`;

        if (seenCategories.has(key)) {
            hasErrors = true;
            sectionHasError = true;
            section.errorCategory = true;
            section.errorMessage = t('Categories/Subcategories should not be repeated');

            seenCategories.get(key)!.forEach((prevIndex) => {
                updatedSections[prevIndex].errorCategory = true;
                updatedSections[prevIndex].errorMessage = t('Categories/Subcategories should not be repeated');
            });

            seenCategories.get(key)!.push(index);
        } else {
            seenCategories.set(key, [index]);
        }
    });

    return { hasErrors, updatedSections };
};

export const processDeleteState = async (
    deleteState: SalarySection[],
    updateServiceCategory: any,
    setDeleteState: React.Dispatch<React.SetStateAction<SalarySection[]>>
) => {
    if (deleteState.length > 0) {
        const deletePromises = deleteState.map(async (section) => {
            return updateServiceCategory({
                variables: {
                    serviceCategorySalaryId: section.id,
                    input: {
                        salaryValue: section.salary,
                        salaryType:
                            section.salaryType === '%'
                                ? SalaryType.Percent
                                : SalaryType.Currency,
                        selectedAllCategories: section.selectedAllCategories,
                        deleted: true,
                    },
                },
            });
        })
        await Promise.allSettled(deletePromises);
        setDeleteState([]);
    }
};

export const processSalarySection = async (
    section: SalarySection,
    checkbox: boolean,
    employee: Employee,
    currentCompanyId: string,
    updateServiceCategory: any,
    createServiceCategory: any
) => {
    const salaryValue = Number(parseFloat(section.salary.toString()).toFixed(2));
    const salaryType =
        section.salaryType === '%' ? SalaryType.Percent : SalaryType.Currency;
    let categoryNum = Number(section.subCategoryId) || Number(section.categoryId);
    let mutation;
    let variables;

    if (section.update) {
        mutation = updateServiceCategory;
        variables = {
            serviceCategorySalaryId: section.id,
            companyId: +currentCompanyId,
            input: {
                serviceCategoryId: categoryNum,
                salaryValue: salaryValue,
                salaryType: salaryType,
                selectedAllCategories: checkbox,
            },
        };
    } else {
        const existingSalary = employee?.ServiceCategorySalaries?.find(
            (salary) =>
                salary.serviceCategory.length > 0 &&
                salary.serviceCategory[0].id === categoryNum &&
                salary.deleted === true
        );

        if (existingSalary) {
            if (checkbox) {
                categoryNum = existingSalary.serviceCategory[0].id;
            }
            mutation = updateServiceCategory;
            variables = {
                serviceCategorySalaryId: existingSalary.id,
                companyId: +currentCompanyId,
                input: {
                    serviceCategoryId: categoryNum,
                    salaryValue: salaryValue,
                    salaryType: salaryType,
                    selectedAllCategories: checkbox,
                    deleted: false,
                },
            };
        } else {
            mutation = createServiceCategory;
            variables = {
                employeeId: employee?.id,
                companyId: +currentCompanyId,
                input: {
                    serviceCategoryId: categoryNum,
                    salaryValue: salaryValue,
                    salaryType: salaryType,
                    selectedAllCategories: checkbox,
                },
            };
        }
    }

    return mutation({
        variables,
    });
};


export const transformSalarySections = (
    employee: Employee,
    defaultSalarySection: SalarySection,
    currSign: string
): SalarySection[] => {
    const transformedSections = employee.ServiceCategorySalaries.reduce(
        (accumulator: SalarySection[], item: ServiceCategorySalaryProxy) => {
            if (!item.deleted) {
                accumulator.push({
                    id: item.id,
                    categoryId: item.serviceCategory[0]?.id || '',
                    salary: item.salaryValue,
                    salaryType: item.salaryType === 1 ? '%' : currSign,
                    categoryName: item.serviceCategory[0]?.name || '',
                    subCategoryId: '',
                    selectedAllCategories: item.selectedAll,
                    update: true
                });
            }
            return accumulator;
        },
        [] as SalarySection[]
    );

    if (transformedSections.length === 0) transformedSections.push(defaultSalarySection);
    return transformedSections;
};