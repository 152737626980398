import {CompanyAccess, CompanyProxy, EmployeeInput, EmployeeProxy, RoleProxy} from "../main/graphql/types";
import { UserRole } from "@me-team/common/src/utils/enums";

type PartialEmployeeProxy = Omit<EmployeeProxy, 'workSchedules' | 'userBranchRelations'>;

interface RoleAccesses {
    roleId: UserRole;
    actions: CompanyAccess[]
}

export default class AccessService {
    get isPremium(): boolean {
        return this._isPremium;
    }
    get currentUserRole(): UserRole | undefined {
        return this._currentUserRole;
    }
    static instance: AccessService;
    private _isPremium: boolean
    private _currentUserRole: UserRole | undefined;
    private _roleAccesses: RoleAccesses[] = [];
    
    constructor() {

    }

    static getInstance(): AccessService {
        if (!AccessService.instance) {
            AccessService.instance = new AccessService();
        }
        return AccessService.instance;
    }

    public init(paidFeatures: CompanyProxy['paidFeatures']): void {
        if (!paidFeatures) return null;
        const currentDate = new Date();
        const activeSubscription = paidFeatures.find(subscription => {
            const subscriptionEndDate = new Date(subscription.dateOfEnd);
            return subscriptionEndDate > currentDate;
        });

        this._isPremium = activeSubscription?.name === 'TARIFF_PREMIUM';
    }

    public setUserRole(userRole:  RoleProxy): void {
        if (Object.values(UserRole).includes(userRole.id)) {
            this._currentUserRole = userRole.id;
        }
    }

    public setRolesAccesses(accesses: RoleProxy[]): void {
        const allActions = new Set<CompanyAccess>(
            accesses.flatMap(role => role.companyAccesses.map(access => access.action))
        );
    
        this._roleAccesses = accesses.map(role => ({
            roleId: role.id,
            actions: role.id === UserRole.OWNER 
                ? Array.from(allActions) // Owner получает все доступные actions
                : role.companyAccesses.map(access => access.action) ?? []
        }));
    }

    canAddEmployee(employees: PartialEmployeeProxy[], employeeInput: EmployeeInput): boolean {
        // TODO: Если премиум возвращать тру
        if (this.isPremium) return true
        
        let employeeCounter = 0;
        let administratorCounter = 0
        let ownerCounter = 0
        employees.forEach(employee => {
            if (!employee.tariffBlocking) {
                const employeeRoleId = employee.role.id;
                if (employeeRoleId === UserRole.OWNER) {
                    ownerCounter++
                } else if (employeeRoleId === UserRole.ADMINISTRATOR) {
                    administratorCounter++
                } else if (employeeRoleId === UserRole.EMPLOYEE) {
                    employeeCounter++
                }
            }
        });
        
        if (employeeInput.role === UserRole.OWNER && ownerCounter >= 1) {
            return false;
        } else if (employeeInput.role === UserRole.ADMINISTRATOR && administratorCounter >= 1) {
            return false;
        } else if (employeeInput.role === UserRole.EMPLOYEE && employeeCounter >= 5) {
            return false;
        }

        return true;
    }

    canAction(actionOrActions: CompanyAccess | CompanyAccess[]): boolean {
        return !!this._currentUserRole && this.canRoleAction(this._currentUserRole, actionOrActions);
    }

    canRoleAction(roleId: UserRole, actionOrActions: CompanyAccess | CompanyAccess[]): boolean {
        if(this._currentUserRole === UserRole.OWNER) return true;
        const roleAccess = this._roleAccesses.find((item) => item.roleId === roleId);
        if (!roleAccess) return false;

        const actions = Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions];
        return actions.some(action => roleAccess.actions.includes(action));
    }
}

