import React, {useEffect, useState} from 'react';
import { AccordionNav } from "@services/ui-components";
import { Accordion } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import {
  currentBranchIdVar,
  currentUserRoleVar,
} from "@me-team/host/main/globalVar/state";
import { UserRole } from "@me-team/common/src/utils/enums";

type accordionItemProps = {
    label: string,
    to: string,
    href?: string,
}

type accordionDataProps = {
    title: string,
    items: accordionItemProps[],
    icon: string
}

type SidebarMenuProps = {
    accordionsData: accordionDataProps[],
    handleCloseNavbar?: () => void,
    isRegistrationOrCreateOwner?: boolean
}

const SidebarMenu: React.FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentRole = useReactiveVar(currentUserRoleVar);
    const {accordionsData, isRegistrationOrCreateOwner} = props;
    const location = useLocation();
    const [activeKey, setActiveKey] = useState<number | AccordionEventKey>(-1)

    const filteredItems = (accordion: accordionDataProps) => accordion.items.filter(Boolean);

    const currentActiveKey = accordionsData.findIndex((data) => {
        return filteredItems(data).some((item: accordionItemProps) => `/${item.to}` === location.pathname || location.pathname.startsWith(`/${item.to}`))
    });

    useEffect(() => {
        setActiveKey(currentActiveKey)
    }, [currentActiveKey]);

    return (
        <>
            <Accordion activeKey={`${activeKey}`} onSelect={(k) => setActiveKey(k)} className={`pb-3 ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""} accordion-sidebar`}>
                {accordionsData.map((accordion, index) => (
                    <AccordionNav key={index} title={accordion.title} items={filteredItems(accordion)}
                                firstItemLink={accordion.items[0].to}
                                icon={accordion.icon} eventKey={index.toString()}
                                handleCloseNavbar={props.handleCloseNavbar}/>
                ))}
            </Accordion>
            {(currentRole === UserRole.OWNER || currentRole === UserRole.ADMINISTRATOR) && <div className='ps-4 ms-4 cursor-pointer pb-3' onClick={() => navigate(`/branch/access-settings/${currentBranchId}`)}><i className="bi bi-person-lock me-2" /><span>{t("Access settings")}</span></div>}
        </>
    );
}

export default SidebarMenu;
