import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, useFormikContext, useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {Col} from 'react-bootstrap';
import {TooltipCustom} from '../index';

interface TextInputProps {
  name: string;
  placeholder?: string;
  label: React.ReactNode;
  maxLength?: number;
  labelCol?: number;
  inputCol?: number;
  textTooltip?: string;
  id: string;
  disabled?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
                                               name,
                                               placeholder,
                                               label,
                                               maxLength,
                                               labelCol = 2,
                                               inputCol = 10,
                                               textTooltip,
                                               id,
                                               disabled
                                             }) => {
  const {t} = useTranslation();
  const {handleBlur} = useFormikContext();
  const [_, meta] = useField(name);
  const [tooltipPlacement, setTooltipPlacement] = useState<'top' | 'right'>('top');

  useEffect(() => {
    const handleResize = () => {
      setTooltipPlacement(window.innerWidth < 1025 ? 'top' : 'right');
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Col md={labelCol} className='mb-2 mb-md-0 text-start d-flex align-items-center'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}
        </label>
      </Col>
      <Col md={inputCol} className='text-start'>
        <div className='position-relative'>
          <Field
            name={name}
            id={id}
            placeholder={t(placeholder)}
            className={`form-control fs-7 ${meta.touched && meta.error ? 'is-invalid' : ''}`}
            onBlur={handleBlur}
            maxLength={maxLength}
            disabled={disabled}
          />
          {textTooltip &&
            <TooltipCustom placement={tooltipPlacement} text={textTooltip}>
              <i className={`bi bi-info-circle position-absolute top-0 end-0 translate-middle-y text-dark mt-4 me-4
               ${meta.error ? 'd-none' : ''}`}>
              </i>
            </TooltipCustom>
          }
        </div>
        <ErrorMessage name={name}>
          {msg => <div className='invalid-feedback d-block'>{msg}</div>}
        </ErrorMessage>
      </Col>
    </>
  );
};

export default TextInput;