import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal, Button, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

type ModalLimitProps = {
    show: boolean;
    handleClose: () => void;
    isRestore?: boolean;
}

const BranchModalLimit: React.FC<ModalLimitProps> = ({ show, handleClose, isRestore = false }) => {
    const {t} = useTranslation();
    const basePath = process.env.REACT_APP_POS_URL;
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered>
            <Modal.Header
                closeButton
                className='border-0'>
                <Modal.Title className="h5 text-danger text-capitalize">
                    {isRestore ? t("Branch restore is not possible") : t("Branch creation is not possible")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <Col sm={10} className="mx-auto">
                    {isRestore
                        ? (<p className="mb-0">{t("Only 2 active establishments are available with your tariff. Delete one of the existing branches before restoring another, or upgrade a tariff")}
                                <Link to={`${basePath}/payment/company/${currentCompanyId}/tariff`} className="text-primary ms-1">Premium</Link>.
                            </p>)
                        : (<p className="mb-0">{t("Only 2 active establishments are available with your tariff. Delete one of the existing branches before creating another, or upgrade a tariff")}
                            <Link to={`${basePath}/payment/company/${currentCompanyId}/tariff`} className="text-primary ms-1">Premium</Link>.
                        </p>)
                    }
                </Col>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <Button
                    variant="danger"
                    className="w-100 py-6 px-4 m-0"
                    onClick={handleClose}
                >
                    {t("servicesPage.deleteModal.okBtn")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BranchModalLimit;
