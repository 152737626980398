import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ModalDelete from '../ModalDelete';

export interface EmployeeInfo {
  name: string;
  surname: string;
  branch: string;
  branchId: number;
}

interface AccessDeletionBlockedPopupProps {
  title: string;
  show: boolean;
  handleClose: () => void;
  employeeInfo: EmployeeInfo;
}

const AccessDeletionBlockedPopup: React.FC<AccessDeletionBlockedPopupProps> = ({
                                                                                 title,
                                                                                 show,
                                                                                 handleClose,
                                                                                 employeeInfo
                                                                               }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <ModalDelete
      show={show}
      handleClose={handleClose}
      title={title}
      body={t('employeeHasBookings', {
        employee: `${employeeInfo?.name} ${employeeInfo?.surname}`,
        branch: employeeInfo?.branch
      })}
      buttons={[
        {
          text: t('Go to the logbook'),
          onClick: () => navigate(`/logbook/journal/${employeeInfo?.branchId}`),
          variant: 'primary',
          className: 'd-flex justify-content-center align-items-center w-100 fw-bold',
        }
      ]}
    />
  );
};

export default AccessDeletionBlockedPopup;