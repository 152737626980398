import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import LanguageDropdown from "@me-team/host/src/components/LanguageDropdown/LanguageDropdown";
import { Container, Col, Button, Form, Modal } from "react-bootstrap";
import Logo from '@me-team/host/src/images/logo_lg.svg';
import {useReactiveVar} from "@apollo/client";
import {CompanyProxy} from '@me-team/host/main/graphql/types';
import {refetchUserDataMenu} from "@me-team/host/src/apollo/apollo-client";
import {useUpdateCurrentBranchMutation} from '@me-team/host/main/branches/graphql/branches.hooks';
import {currentCompanyIdVar, userIdVar} from '@me-team/host/main/globalVar/state';

interface  ActiveCompaniesProps {
    show: boolean;
    companies: CompanyProxy[];
    close: () => void;
}
const ModalUserActiveCompanies: React.FC<ActiveCompaniesProps> = ({show, companies, close}) => {
    const {t} = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userId = useReactiveVar(userIdVar);
    const basePath = process.env.REACT_APP_POS_URL;

    const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(+currentCompanyId);
    const [updateCurrentBranch] = useUpdateCurrentBranchMutation();

    const handleCompanySelect = (companyId: number) => {
        setSelectedCompanyId(companyId);
    };

    const handleChangeCompany = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            await updateCurrentBranch({
                variables: {companyId: selectedCompanyId, userId: +userId},
            });
        } catch (error) {
            console.error('Error updating current branch:', error);
        }
        await refetchUserDataMenu();
    };

    useEffect(() => {
        if (companies?.length > 0 && selectedCompanyId === null) {
            setSelectedCompanyId(companies?.[0].id);
        }
    }, [companies, selectedCompanyId]);

    return (
        <>
            <Modal centered size={"lg"} show={show} close={close}>
                <Container className="text-dark pt-3">
                    <Col lg={9} className="mx-auto">
                        <div className="bg-white p-5 text-center rounded-1">
                            <Link to={`${basePath}`} className="d-block mb-5">
                                <Logo/>
                            </Link>
                            <h2 className="fs-4 text-dark mb-4">{t('Select a company and account to sign in')}</h2>

                            <Form>
                                <div className="row gap-3">
                                    {companies?.map((company, index) => (
                                        <Col key={index} xs={12} className="text-start">
                                            <div className="p-3 border rounded-1">
                                                <Form.Check
                                                    type="radio"
                                                    id={`radio-${company?.id}`}
                                                    label={<div className="ps-2 w-100"><span className="d-block mb-2">{t("Company")}:{" "}
                                                        <strong>{company?.name}</strong></span>
                                                        <span className="d-block mb-2">{t("User")}:{" "}
                                                            <strong>{company?.loggedInUser?.name} {company?.loggedInUser?.surname}</strong></span>
                                                        <span className="d-block">{t("Role")}:{" "}
                                                            <strong>{company?.loggedInUser?.role?.name}</strong></span></div>}
                                                    className={`form-check-active-companies fs-7 ${selectedCompanyId === company.id ? 'active' : ''}`}
                                                    checked={selectedCompanyId === company.id}
                                                    onChange={() => handleCompanySelect(company.id)}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 mb-3 mt-5">
                                        <Button variant="primary" type="submit" className="w-100 fw-bold py-2"
                                                onClick={handleChangeCompany}>
                                            {t("Sign In")}
                                        </Button>
                                    </div>
                                </div>
                            </Form>

                            <div className="col-2 mt-4 mx-auto">
                                <LanguageDropdown isShowOnlyLanguagesDropdown />
                            </div>
                        </div>
                    </Col>
                </Container>
            </Modal>
        </>

    )
}

export default ModalUserActiveCompanies;