/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetCurrentBranchDocument = gql`
    query GetCurrentBranch {
  user {
    id
    currentBranch {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCurrentBranchQuery__
 *
 * To run a query within a React component, call `useGetCurrentBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentBranchQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>(GetCurrentBranchDocument, options);
      }
export function useGetCurrentBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>(GetCurrentBranchDocument, options);
        }
export function useGetCurrentBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>(GetCurrentBranchDocument, options);
        }
export type GetCurrentBranchQueryHookResult = ReturnType<typeof useGetCurrentBranchQuery>;
export type GetCurrentBranchLazyQueryHookResult = ReturnType<typeof useGetCurrentBranchLazyQuery>;
export type GetCurrentBranchSuspenseQueryHookResult = ReturnType<typeof useGetCurrentBranchSuspenseQuery>;
export type GetCurrentBranchQueryResult = Apollo.QueryResult<GetCurrentBranchQuery, GetCurrentBranchQueryVariables>;
export const GetBranchByIdDocument = gql`
    query GetBranchById($branchId: ID!) {
  branch(id: $branchId) {
    id
    name
  }
}
    `;

/**
 * __useGetBranchByIdQuery__
 *
 * To run a query within a React component, call `useGetBranchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchByIdQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useGetBranchByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables> & ({ variables: GetBranchByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(GetBranchByIdDocument, options);
      }
export function useGetBranchByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(GetBranchByIdDocument, options);
        }
export function useGetBranchByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(GetBranchByIdDocument, options);
        }
export type GetBranchByIdQueryHookResult = ReturnType<typeof useGetBranchByIdQuery>;
export type GetBranchByIdLazyQueryHookResult = ReturnType<typeof useGetBranchByIdLazyQuery>;
export type GetBranchByIdSuspenseQueryHookResult = ReturnType<typeof useGetBranchByIdSuspenseQuery>;
export type GetBranchByIdQueryResult = Apollo.QueryResult<GetBranchByIdQuery, GetBranchByIdQueryVariables>;
export const GetBranchesByCompanyIdDocument = gql`
    query GetBranchesByCompanyId($companyId: ID!) {
  companyBranches(id: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetBranchesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetBranchesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBranchesByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables> & ({ variables: GetBranchesByCompanyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables>(GetBranchesByCompanyIdDocument, options);
      }
export function useGetBranchesByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables>(GetBranchesByCompanyIdDocument, options);
        }
export function useGetBranchesByCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables>(GetBranchesByCompanyIdDocument, options);
        }
export type GetBranchesByCompanyIdQueryHookResult = ReturnType<typeof useGetBranchesByCompanyIdQuery>;
export type GetBranchesByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetBranchesByCompanyIdLazyQuery>;
export type GetBranchesByCompanyIdSuspenseQueryHookResult = ReturnType<typeof useGetBranchesByCompanyIdSuspenseQuery>;
export type GetBranchesByCompanyIdQueryResult = Apollo.QueryResult<GetBranchesByCompanyIdQuery, GetBranchesByCompanyIdQueryVariables>;
export const GetRecordsForEmployeeByBranchDocument = gql`
    query GetRecordsForEmployeeByBranch($companyId: Int!, $employeeId: Int, $removeCanceledBookings: Boolean, $startDate: String, $branchId: Int) {
  user {
    id
    company(id: $companyId) {
      employees(id: $employeeId) {
        employees {
          id
          name
          surname
          email
          phone
          tariffBlocking
          manualBlocking
          bookings(
            removeCanceledBookings: $removeCanceledBookings
            employeeId: $employeeId
            startDate: $startDate
            branchId: $branchId
          ) {
            totalCount
            bookings {
              id
              date
              needClientRecall
              uuid
              branch {
                id
                name
                address
                bookingQrLink
                removedOfTariff
                onlineRegistration
                currencyCode
                timeZone
                deleted
                isAnyActiveServiceEmployeeRelations
                isAnyActiveServices
                isAnyWorkScheduleForWorkingEmployees
                country {
                  id
                  name
                  localeCode
                  continentName
                  isoCode
                  isInEuropeanUnion
                }
              }
              service {
                id
                name
                deleted
                onlineRegistration
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRecordsForEmployeeByBranchQuery__
 *
 * To run a query within a React component, call `useGetRecordsForEmployeeByBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordsForEmployeeByBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordsForEmployeeByBranchQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      employeeId: // value for 'employeeId'
 *      removeCanceledBookings: // value for 'removeCanceledBookings'
 *      startDate: // value for 'startDate'
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useGetRecordsForEmployeeByBranchQuery(baseOptions: Apollo.QueryHookOptions<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables> & ({ variables: GetRecordsForEmployeeByBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables>(GetRecordsForEmployeeByBranchDocument, options);
      }
export function useGetRecordsForEmployeeByBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables>(GetRecordsForEmployeeByBranchDocument, options);
        }
export function useGetRecordsForEmployeeByBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables>(GetRecordsForEmployeeByBranchDocument, options);
        }
export type GetRecordsForEmployeeByBranchQueryHookResult = ReturnType<typeof useGetRecordsForEmployeeByBranchQuery>;
export type GetRecordsForEmployeeByBranchLazyQueryHookResult = ReturnType<typeof useGetRecordsForEmployeeByBranchLazyQuery>;
export type GetRecordsForEmployeeByBranchSuspenseQueryHookResult = ReturnType<typeof useGetRecordsForEmployeeByBranchSuspenseQuery>;
export type GetRecordsForEmployeeByBranchQueryResult = Apollo.QueryResult<GetRecordsForEmployeeByBranchQuery, GetRecordsForEmployeeByBranchQueryVariables>;
export const CreateUpdateUserBranchRelationDocument = gql`
    mutation CreateUpdateUserBranchRelation($companyId: Int!, $input: [UserBranchRelationInput!]!) {
  createUserBranchRelation(companyId: $companyId, input: $input) {
    id
    branch {
      id
      name
      deleted
      timeZone
      currencyCode
      onlineRegistration
      removedOfTariff
      address
      bookingQrLink
      country {
        id
        name
        isInEuropeanUnion
        isoCode
        continentName
        localeCode
      }
    }
    employee {
      id
      name
      manualBlocking
      tariffBlocking
      phone
      email
    }
    role {
      id
      name
    }
  }
}
    `;
export type CreateUpdateUserBranchRelationMutationFn = Apollo.MutationFunction<CreateUpdateUserBranchRelationMutation, CreateUpdateUserBranchRelationMutationVariables>;

/**
 * __useCreateUpdateUserBranchRelationMutation__
 *
 * To run a mutation, you first call `useCreateUpdateUserBranchRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateUserBranchRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateUserBranchRelationMutation, { data, loading, error }] = useCreateUpdateUserBranchRelationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateUserBranchRelationMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateUserBranchRelationMutation, CreateUpdateUserBranchRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateUserBranchRelationMutation, CreateUpdateUserBranchRelationMutationVariables>(CreateUpdateUserBranchRelationDocument, options);
      }
export type CreateUpdateUserBranchRelationMutationHookResult = ReturnType<typeof useCreateUpdateUserBranchRelationMutation>;
export type CreateUpdateUserBranchRelationMutationResult = Apollo.MutationResult<CreateUpdateUserBranchRelationMutation>;
export type CreateUpdateUserBranchRelationMutationOptions = Apollo.BaseMutationOptions<CreateUpdateUserBranchRelationMutation, CreateUpdateUserBranchRelationMutationVariables>;
export const DeleteUserBranchRelationDocument = gql`
    mutation DeleteUserBranchRelation($companyId: Int!, $ids: [Int!]!) {
  deleteUserBranchRelation(companyId: $companyId, ids: $ids)
}
    `;
export type DeleteUserBranchRelationMutationFn = Apollo.MutationFunction<DeleteUserBranchRelationMutation, DeleteUserBranchRelationMutationVariables>;

/**
 * __useDeleteUserBranchRelationMutation__
 *
 * To run a mutation, you first call `useDeleteUserBranchRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBranchRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBranchRelationMutation, { data, loading, error }] = useDeleteUserBranchRelationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUserBranchRelationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserBranchRelationMutation, DeleteUserBranchRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserBranchRelationMutation, DeleteUserBranchRelationMutationVariables>(DeleteUserBranchRelationDocument, options);
      }
export type DeleteUserBranchRelationMutationHookResult = ReturnType<typeof useDeleteUserBranchRelationMutation>;
export type DeleteUserBranchRelationMutationResult = Apollo.MutationResult<DeleteUserBranchRelationMutation>;
export type DeleteUserBranchRelationMutationOptions = Apollo.BaseMutationOptions<DeleteUserBranchRelationMutation, DeleteUserBranchRelationMutationVariables>;
export type GetCurrentBranchQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentBranchQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, currentBranch?: { __typename?: 'BranchProxy', id: number, name: string } | null } | null };

export type GetBranchByIdQueryVariables = Types.Exact<{
  branchId: Types.Scalars['ID']['input'];
}>;


export type GetBranchByIdQuery = { __typename?: 'Query', branch?: { __typename?: 'BranchProxy', id: number, name: string } | null };

export type GetBranchesByCompanyIdQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID']['input'];
}>;


export type GetBranchesByCompanyIdQuery = { __typename?: 'Query', companyBranches: Array<{ __typename?: 'BranchProxy', id: number, name: string }> };

export type GetRecordsForEmployeeByBranchQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  removeCanceledBookings?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  branchId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetRecordsForEmployeeByBranchQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, email: string, phone: string, tariffBlocking: boolean, manualBlocking: boolean, bookings?: { __typename?: 'BookingPaginated', totalCount?: number | null, bookings?: Array<{ __typename?: 'BookingMainProxy', id: number, date?: string | null, needClientRecall: boolean, uuid: any, branch?: { __typename?: 'BranchProxy', id: number, name: string, address: string, bookingQrLink: string, removedOfTariff: boolean, onlineRegistration: boolean, currencyCode: string, timeZone: string, deleted: boolean, isAnyActiveServiceEmployeeRelations: boolean, isAnyActiveServices: boolean, isAnyWorkScheduleForWorkingEmployees: boolean, country: { __typename?: 'Country', id: string, name: string, localeCode: string, continentName: string, isoCode: string, isInEuropeanUnion: boolean } } | null, service?: { __typename?: 'ServiceProxy', id: number, name: string, deleted: boolean, onlineRegistration: boolean } | null }> | null } | null }> | null } | null } | null } | null };

export type CreateUpdateUserBranchRelationMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Array<Types.UserBranchRelationInput> | Types.UserBranchRelationInput;
}>;


export type CreateUpdateUserBranchRelationMutation = { __typename?: 'Mutation', createUserBranchRelation: Array<{ __typename?: 'UserBranchRelationProxy', id: number, branch: { __typename?: 'BranchProxy', id: number, name: string, deleted: boolean, timeZone: string, currencyCode: string, onlineRegistration: boolean, removedOfTariff: boolean, address: string, bookingQrLink: string, country: { __typename?: 'Country', id: string, name: string, isInEuropeanUnion: boolean, isoCode: string, continentName: string, localeCode: string } }, employee: { __typename?: 'EmployeeProxy', id: number, name?: string | null, manualBlocking: boolean, tariffBlocking: boolean, phone: string, email: string }, role: { __typename?: 'RoleProxy', id: number, name: string } }> };

export type DeleteUserBranchRelationMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type DeleteUserBranchRelationMutation = { __typename?: 'Mutation', deleteUserBranchRelation: boolean };
