import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useTranslation} from 'react-i18next';
import DatePicker from "react-datepicker";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {OverlayTrigger, Tooltip, Col, Button, Popover, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import i18next from "i18next";
import {
  useGetCompanyIdQuery,
  useGetEmployeesPositionsQuery
} from '@me-team/host/main/employeeList/graphql/employeeList.hooks';
import {
  useGetEmployeeByIdQuery,
  useGetRolesQuery,
  useGetSpecSpecializationQuery,
  useUpdateEmployeeMutation
} from "@me-team/host/main/addEmployee/graphql/addEmployee.hooks";
import {AccessDeletionBlockedPopup, TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import CustomCreatableReactSelect
  from '@services/ui-components/src/select/CustomCreatableReactSelect';
import PhonePicker from '@services/ui-components/src/PhonePicker/PhonePicker';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import DropItem from '@services/ui-components/src/DropItem/DropItem';
import RatingStars from '@services/ui-components/src/RatingStars/RatingStars';
import {addEmployeeFormValidationSchema} from '../AddEmployee/constants/constants';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import ErrorService from "../../services/ErrorService";
import {ErrorModalComponent} from "@me-pos/error-modal";
import DropPhotosMultiply from '../DropPhotosMultiply/DropPhotosMultiply';
import {processImages} from '../DropPhotosMultiply/drop-photos-utils';
import CustomReactSelect from "@services/ui-components/src/select/CustomReactSelect";
import {useReactiveVar} from "@apollo/client";
import {
  currentCompanyIdVar,
  currentUserEmailVar,
  currentUserRoleVar
} from '@me-team/host/main/globalVar/state';
import AccessSettingsEmployeeTable from './AccessSettingsEmployeeTable';
import {EmployeeProxy, UserBranchRelationProxy} from '@me-team/host/main/graphql/types';
import {UserRole} from '../../utils/enums';
import {BranchRoleRelationForEmployee, EmployeeBranchRole} from './types';
import {
  useCreateUpdateUserBranchRelationMutation, useDeleteUserBranchRelationMutation
} from '@me-team/host/main/setUpAccessRole/graphql/setUpAccessRole.hooks';
import {getArrayForUpdateAccessRights, getBranchIdsForDelete} from './constants';
import {
  EmployeeInfo
} from '@services/ui-components/src/ModalDelete/AccessDeletionBlockedPopup/AccessDeletionBlockedPopup';
import CreatePositionModal from '../ui-components/CreatePositionModal/CreatePositionModal';

type SetFieldValueType = (field: string, value: string | boolean | string[] | File | (string | File)[]) => void;
type TransformedRole = {
  value: string;
  label: string;
};
const EditingEmployee: React.FC = () => {
  const {id} = useParams();
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_POS_URL;
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isOwnerRoleOrAdministratorRole = +userRole === UserRole.OWNER || +userRole === UserRole.ADMINISTRATOR;
  const [startDate, setStartDate] = useState(null);
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [photosQualification, setPhotosQualification] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(null);
  const [newPossition, setNewPossition] = useState(null);
  const [key, setKey] = useState(0);
  const currentUserEmail = useReactiveVar(currentUserEmailVar)
  const [newAccessSettingsState,  setNewAccessSettingsState] = useState<BranchRoleRelationForEmployee[]>([]);
  const [showAccessDeletionBlockedPopup, setShowAccessDeletionBlockedPopup] = useState<boolean>(false);
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfo>(null);

  const {data, loading, error, refetch: employeesRefetch} = useGetEmployeeByIdQuery({
    variables: {employeeId: +id, companyId: +currentCompanyId},
    skip: !currentCompanyId,
    onCompleted: async (data) => {
      const qualificationConfirmations = data?.user?.company?.employees?.employees[0]?.qualificationConfirmations;
      if (data?.user?.company?.employees?.employees.length === 0) navigate('/*')
      const files = await processImages(qualificationConfirmations, basePath);
      setPhotosQualification(files);
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
    context: {
      errorType: 'global',
    },
  });

  const {data: roles} = useGetRolesQuery({
    context: {
      errorType: 'local',
    },
    skip: !currentCompanyId,
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  })


  const handleChange = (date: string | null | Date, setFieldValue: (field: string, value: string) => void) => {
    if (date) {
      const formattedDate = new Date(date);
      const year = formattedDate.getFullYear();
      const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
      const day = String(formattedDate.getDate()).padStart(2, '0');
      const formattedDateString = `${year}-${month}-${day}`;
      setStartDate(formattedDate);
      setFieldValue('dateOfBirth', formattedDateString);
    }
  }

  const {
    data: possitions,
    loading: loadingPossitions,
    refetch: refetchPositions
  } = useGetEmployeesPositionsQuery({
    variables: {companyId: +currentCompanyId},
    skip: !currentCompanyId,
    context: {
      errorType: 'local',
    },
    onError:(error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const {data: companyId, loading: loadingCompanyId} = useGetCompanyIdQuery({
    variables: {companyId: +currentCompanyId},
    skip: !currentCompanyId,
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
    context: {
      errorType: 'global',
    },
  })

  const {data: dataSpecialization, loading: loadingSpecialization} = useGetSpecSpecializationQuery({
    context: {
      errorType: 'local',
    },
    onError:(error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const specialization = dataSpecialization?.employeeSpecializations

  const [updateEmployee] = useUpdateEmployeeMutation({
    context: {
      errorType: 'local',
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const [deleteUserBranchRelation, {
    loading: deleteUserBranchRelationLoading
  }] = useDeleteUserBranchRelationMutation({
    context: {
      errorType: 'local',
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const [createUserBranchRelation, {loading: createUserBranchRelationLoading}] = useCreateUpdateUserBranchRelationMutation({
    context: {
      errorType: 'local',
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const handlePositionModal = () => {
    setShowPositionModal(prevState => !prevState);
    setKey(prevKey => prevKey + 1);
  };

  const setFieldConfirmation = (setFieldValue: SetFieldValueType) => {
    setFieldValue('isUpdateConfirmations', true)
  }
  const employees: EmployeeProxy = data?.user?.company?.employees?.employees[0]
  const userBranchRelations: UserBranchRelationProxy[] = employees?.userBranchRelations
  const formattedArrayBranchAndRoleRelation: BranchRoleRelationForEmployee[] = userBranchRelations?.map((relation) => ({
    relation: relation.id,
    branch: {id: relation.branch.id, name: relation.branch.name},
    role: {id: relation.role.id, name: relation.role.name},
  })) || [];

  useEffect(() => {
    document.title = ` ${t('Edit')} ${employees?.name} ${employees?.surname}`;
    if (userRole === 5 && employees?.email !== undefined && currentUserEmail !== employees?.email) {
      navigate('/');
    }
  }, [employees?.name]);
  const addContract = (index: number, file: string, setFieldValue: SetFieldValueType): void => {
    setFieldValue('contract', file)
    setFieldValue('isUpdateContract', true)
  }
  const handlePhoto = (setFieldValue: SetFieldValueType) => {
    setFieldValue('isUpdateImage', true);
  }

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 14);

  const transform = (original: string): TransformedRole => ({
    value: original,
    label: original
  });

  const handleCloseAccessDeletionBlockedPopup = () => {
    setShowAccessDeletionBlockedPopup(false)
  }

  if (loadingPossitions || loadingCompanyId || loading || loadingSpecialization || !currentCompanyId || deleteUserBranchRelationLoading || createUserBranchRelationLoading) return <Spinner/>

  return (
    <>
      <VioletBadge>
        {t('Required fields are marked with an asterisk *. The data that customers see in the online record form is marked ')}
        {' '} <i className="bi bi-info-circle"></i>
      </VioletBadge>
      <TableHeader
        title={` ${t('Edit')} ${employees?.name} ${employees?.surname}`}

        paths={[{path: location?.pathname, label: "Personnel management"}, {
          path: "/employees-list",
          label: "List of employees"
        }, {path: location?.pathname, label: "Edit"}]}
      />

      <Formik
        initialValues={{
          company: companyId?.user.company.id,
          name: employees?.name,
          surname: employees?.surname,
          email: employees?.email,
          phone: employees?.phone,
          specialization: employees?.specialization,
          employeePosition: employees?.position.id,
          role: employees?.role.id,
          tariffBlocking: false,
          manualBlocking: false,
          status: +employees?.status.id,
          patronymic: employees?.patronymic,
          dateOfBirth: employees?.dateOfBirth,
          passportData: employees?.passportData,
          itn: employees?.itn,
          aboutMe: employees?.aboutMe,
          isUpdateConfirmations: false,
        }}
        validationSchema={addEmployeeFormValidationSchema(t)}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          try {
            if (!currentCompanyId) return;
            const cleanedPhotosQualification = photosQualification?.filter(photo => photo != null);
            const updatedValues = {
              ...values,
              qualificationConfirmations: cleanedPhotosQualification
            }
            const employeeId: number = Number(id)
            let branchIdsForDelete: number[] = getBranchIdsForDelete(formattedArrayBranchAndRoleRelation, newAccessSettingsState)
            const arrForUpdateAccessRights: EmployeeBranchRole[] = getArrayForUpdateAccessRights(
              formattedArrayBranchAndRoleRelation,
              newAccessSettingsState,
              employeeId
            )

            branchIdsForDelete.length > 0 && await new Promise((resolve, reject) => {
              deleteUserBranchRelation({
                variables: {
                  companyId: +currentCompanyId,
                  ids: branchIdsForDelete
                },
                onCompleted: (data) => {
                  resolve(data);
                },
                onError: (error) => {
                  if (error.graphQLErrors) {
                    const gqlError = error.graphQLErrors[0];
                    const category = gqlError.extensions?.category;

                    if (category === "booking.actual_items") {
                      const match = gqlError.message.match(
                        /Employee \[id: \d+, name: ([^\]]+)] has actual bookings in branch \[id: (\d+), name: ([^\]]+)]/
                      );
                      if (match) {
                        const employeeName = match[1];
                        const branchId = match[2];
                        const branchName = match[3];
                        setEmployeeInfo({
                          name: employeeName,
                          surname: '',
                          branch: branchName,
                          branchId: +branchId
                        })
                        setShowAccessDeletionBlockedPopup(true)
                      }
                    }
                    reject(error);
                  }
                }
              })
            })
            arrForUpdateAccessRights.length > 0 && await createUserBranchRelation({
              variables: {
                companyId: +currentCompanyId,
                input: arrForUpdateAccessRights
              }
            })

            await updateEmployee({
              variables: {
                id: employees?.id,
                input: updatedValues,
                companyId: +currentCompanyId
              },
              onCompleted: (data) => {
                setNewAccessSettingsState([])
                navigate('/employees-list', {state: {text: t('Saved'), toast: true}})
              }
            })
          } catch (error) {
            console.error('Error creating employee:', error);
            const graphQLError = error.graphQLErrors?.[0];
            if (graphQLError?.extensions?.validation?.phone?.[0]?.message === 'This value is not a valid phone number.') {
              setErrors({phone: t('Invalid phone number')});
            } else if (graphQLError?.message === "Employee already exists") {
              console.error("Error", graphQLError.message);
              setErrors({email: `Email  ${values.email} ${t('already exists in system.')}`});
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({errors, touched, setFieldValue, values, handleSubmit}) => (
          <>
            <CreatePositionModal
              key={key}
              show={showPositionModal}
              handleModal={handlePositionModal}
              companyId={companyId?.user.company.id}
              refetch={refetchPositions}
              setNewPossition={setNewPossition}
              setFieldValue={setFieldValue}
            />
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="d-flex flex-column-reverse flex-lg-row">
                  <Col xs={12} lg={9} className="me-0 pe-lg-5">
                    <div className="row ">
                      <h4 className="fw-normal">{t('Personal data')}</h4>
                    </div>
                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-name-field"
                                   className="mb-0 text-dark form-label required">{t('Name')}</label>
                          </small>
                          <span className="text-danger">
                            {' '}*
                          </span>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field
                            type="text"
                            name="name"
                            id="editEmployee-name-field"
                            className={`fs-7 form-control ${errors.name && touched.name ? "border-danger" : ""}`}
                            maxLength="50"
                          />
                          <div className='position-absolute top-50 end-0 translate-middle-y '>
                            <OverlayTrigger overlay={
                              <Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                              <div
                                className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                <i className="bi bi-info-circle"></i>
                              </div>
                            </OverlayTrigger>

                          </div>
                        </div>
                        <ErrorMessage name="name">{msg => <p
                          className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-surname-field"
                                   className="mb-0 text-dark form-label required">{t('Last name')} </label>
                          </small>
                          <span className="text-danger">
                            {' '}*
                          </span>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field id="editEmployee-surname-field" type="text" name="surname"
                                 placeholder={t('Add Last name')} maxLength="50"
                                 className={`fs-7 form-control ${errors.surname && touched.surname ? "border-danger" : ""}`}
                          />
                          <div className='position-absolute top-50 end-0 translate-middle-y '>
                            <OverlayTrigger overlay={
                              <Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                              <div
                                className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                <i className="bi bi-info-circle"></i>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <ErrorMessage name="surname">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-middleName-field"
                                   className="mb-0 text-dark form-label required">{t('Middle name')}</label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field id="editEmployee-middleName-field" type="text" name="patronymic"
                                 maxLength="50" placeholder={t('Add Middle name')}
                                 className="fs-7 form-control"/>
                        </div>
                        <ErrorMessage name="patronymic">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 ">
                        <span className="text-dark fw-bold">
                          <small>
                            <label
                              className="mb-0 text-dark form-label required">{t('rating')}</label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10 d-flex justify-content-start">
                        <RatingStars
                          rating={employees?.reviews}
                        />
                      </div>
                    </div>
                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-date-field"
                                   className="mb-0 text-dark form-label required">{t('Date of birth')} </label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <DatePicker
                            id='editEmployee-date-field'
                            className="form-control w-100"
                            wrapperClassName='w-100'
                            placeholderText={t('dd.mm.yyyy')}
                            selected={startDate ? startDate : employees?.dateOfBirth}
                            onChange={(date) => handleChange(date, setFieldValue)}
                            dateFormat="dd.MM.yyyy"
                            maxDate={maxDate}
                            showYearDropdown
                            yearDropdownItemNumber={124}
                            scrollableYearDropdown
                          />

                        </div>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-E-mail-field"
                                   className="mb-0 text-dark form-label required">{t('E-mail')}</label>
                          </small>
                          <span className="text-danger">
                            {' '}*
                          </span>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field id="editEmployee-E-mail-field" type="email" name='email'
                                 maxLength="50" placeholder={t('Add e-mail')}
                                 className={`fs-7 form-control ${errors.email && touched.email ? "border-danger" : ""}`}
                                 readOnly/>
                          <div className='position-absolute top-50 end-0 translate-middle-y '>
                            <i className="bi bi-shield-check" style={{paddingRight: '12px'}}> </i>
                          </div>
                        </div>
                        <ErrorMessage name="email">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-phone-field"
                                   className="mb-0 text-dark form-label required">{t('Phone')} </label>
                          </small>
                          <span className="text-danger">
                            {' '}*
                          </span>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">

                        <PhonePicker
                          name='phone'
                          value={employees?.phone}
                          id='editEmployee-phone-field'
                        />
                        <ErrorMessage name="phone">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-passportData-field"
                                   className="mb-0 text-dark form-label required">{t('Passport Data')}</label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field id="editEmployee-passportData-field" maxLength="50" type="text"
                                 name="passportData" placeholder={t('Enter Passport Data')}
                                 className="fs-7 form-control"/>
                        </div>
                      </div>

                      <div className="col-12 col-lg-10 ms-auto">
                        <ErrorMessage name="passportData">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                        <span
                          className="text-grey fs-7"> {values?.passportData?.length || 0}/10</span>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-SSN-field"
                                   className="mb-0 text-dark form-label required">{t('SSN (Social Security Number)')}  </label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field id="editEmployee-SSN-field" maxLength="20" type="text" name="itn"
                                 placeholder={t('Enter SSN')} className="fs-7 form-control"/>
                        </div>
                        <ErrorMessage name="itn">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="editEmployee-About-field"
                                   className="mb-0 text-dark form-label required">{t('About')} </label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <Field id="editEmployee-About-field" as="textarea" name='aboutMe'
                                 className="form-control"
                                 placeholder={t('Enter a short description for the master presentation')}/>
                          <div className='position-absolute top-50 end-0 translate-middle-y '>
                            <OverlayTrigger overlay={
                              <Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                              <div
                                className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                <i className="bi bi-info-circle"></i>
                              </div>
                            </OverlayTrigger>
                          </div>
                          <ErrorMessage name="aboutMe">{msg => <p
                            className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12 col-lg-10 ms-auto">
                        <span className="text-grey fs-7">
                          {values?.aboutMe?.length || 0} /400
                        </span>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <h4 className="text-dark fw-normal">{t('Professional Information')}</h4>
                    </div>
                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="position-field"
                                   className="mb-0 text-dark form-label required">{t('Position')}</label>
                          </small>
                          <span className="text-danger">
                            {' '} *
                          </span>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <div className='d-flex align-items-center w-100'>
                            <div className='w-100'>
                              <div className="position-relative w-100">
                                <CustomReactSelect
                                  key={key}
                                  optionsArray={possitions?.user.company.employeePositions.employeePositions}
                                  placeholder={employees?.position?.name || t('Select Position')}
                                  setField={setFieldValue}
                                  fieldName="employeePosition"
                                  value="id"
                                  isTooltip={true}
                                  initialValue={newPossition || transform(employees?.position?.name)}
                                  name={"position-field"}
                                  id={"editEmployee-Position-field"}
                                />

                                <div className='position-absolute top-50 end-0 translate-middle-y '
                                     style={{height: '16px', paddingRight: '12px'}}>
                                  <OverlayTrigger
                                    overlay={
                                      <Popover>
                                        <Popover.Body>
                                          <Row>
                                            <p
                                              className="m-0 mb-1">{t('The position is displayed inside the system, and the specialization is in the form of an online record.')} </p>
                                          </Row>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <div
                                      className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                      <i className="bi bi-info-circle"></i>
                                    </div>
                                  </OverlayTrigger>

                                </div>
                              </div>

                            </div>
                            <div>
                              <Button
                                type="button"
                                onClick={handlePositionModal}
                                variant="outline-gray"
                                className=" text-dark ms-2  px-3 border square-btn"
                                style={{minWidth: '50px', height: '50px'}}
                                disabled={userRole === 5}
                              >

                                <i className="bi bi-plus-lg"></i>
                              </Button>
                            </div>


                          </div>
                          <ErrorMessage name="employeePosition">{msg => <p
                            className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label htmlFor="specialization-field"
                                   className="mb-0 text-dark form-label required">
                              {t('Specialization')}<span className="text-danger"> {' '}*  </span>
                            </label>
                          </small>
                        </span>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          <CustomCreatableReactSelect
                            optionsArray={specialization}
                            placeholder={employees?.specialization || t('Enter specialization')}
                            setField={setFieldValue}
                            fieldName="specialization"
                            value='name'
                            isTooltip={true}
                            initialValue={transform(employees?.specialization || '')}
                            name={"specialization-field"}
                            id={"editEmployee-specialization-field"}
                          />
                          <div className='position-absolute top-50 end-0 translate-middle-y '
                               style={{height: '16px', paddingRight: '12px'}}>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                              <div
                                className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                <i className="bi bi-info-circle"></i>
                              </div>
                            </OverlayTrigger>

                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-10 ms-auto">
                        <span className="text-grey fs-7">
                          {values?.specialization?.length || 0}/50
                        </span>
                        <ErrorMessage name="specialization">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>

                    <div className="row mt-lg-3">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <label htmlFor="permissions-field"
                               className="form-label mb-0 text-dark fw-bold">
                          <small>
                            {t('Editing Permissions')} <span
                            className="text-danger"> {' '}*  </span>
                          </small>
                        </label>
                      </div>
                      <div className="col-12 col-lg-10">
                        <div className="position-relative">
                          {employees?.role?.id === 1 ?
                            <Field
                              type="text"
                              placeholder={t('Owner')}
                              value={t('Owner')}
                              className="fs-7 form-control"
                              maxLength="50"
                              disabled
                            />
                            : <CustomReactSelect
                              optionsArray={roles?.role.filter(role => role.id !== 1)}
                              placeholder={employees?.role?.name || t('Choose a role')}
                              setField={setFieldValue}
                              fieldName="role"
                              value="id"
                              isTooltip={true}
                              initialValue={transform(employees?.role?.name || '')}
                              name={"permissions-field"}
                              id={"editEmployee-permissions-field"}
                              disabled={userRole === 5}
                            />}

                          <div className='position-absolute top-50 end-0 translate-middle-y '
                               style={{height: '16px', paddingRight: '12px'}}>
                            <OverlayTrigger
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <Row>
                                      <p
                                        className="m-0 mb-1"> {t('"Editing permissions" - defines what access the employee has.')} </p>
                                    </Row>
                                    <Row>
                                      <p
                                        className="m-0 mb-1"> {t('Owner - unlimited access to all sections.')}</p>
                                    </Row>
                                    <Row>
                                      <p
                                        className="m-0 mb-1">{t('Administrator - access to all sections except "Tariff Management."')}</p>
                                    </Row>
                                    <Row>
                                      <p
                                        className="m-0 mb-1">{t('Employee - has limited rights, he can only view his schedule, his record log, information about himself and the services he performs.')}  </p>
                                    </Row>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <div
                                className="position-absolute top-50 translate-middle-y end-0 me-3">
                                <i className="bi bi-info-circle"></i>
                              </div>
                            </OverlayTrigger>

                          </div>
                        </div>

                        <ErrorMessage name="role">{msg => <p
                          className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                      </div>

                    </div>
                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label
                              className="mb-0 text-dark form-label required">{t('Qualification Confirmation')}</label>
                          </small>
                        </span>
                      </div>

                      <div className="col-12 col-lg-10">

                        <div className="mb-2 ps-sm-1 text-dark">
                          <span>
                            {t('Add JPEG images of diplomas, certificates')}{' '}
                          </span>
                          <OverlayTrigger overlay={
                            <Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                            <i className="bi bi-info-circle" style={{paddingRight: '12px'}}></i>
                          </OverlayTrigger>
                        </div>
                        <div className="col-12 col-lg-10 ">
                          <div className='row m-0'>
                            <DropPhotosMultiply
                              photosArray={photosQualification}
                              setPhotos={setPhotosQualification}
                              disabled={false}
                              setConfiramitoinField={() => setFieldConfirmation(setFieldValue)}
                            />
                          </div>
                          <span className="text-grey fs-7"> {t('Maximum size: 3 MB')}</span>
                        </div>
                      </div>

                    </div>

                    <div className="row mt-lg-4">
                      <div className="col-12 col-lg-2 mt-2 pt-1">
                        <span className="text-dark fw-bold">
                          <small>
                            <label
                              className="mb-0 text-dark form-label required"> {t('Contract')}</label>
                          </small>
                        </span>
                      </div>

                      <div className="col-12 col-lg-10">
                        <DropItem
                          index={0}
                          handleFile={setFieldValue}
                          fieldName={'contract'}
                          file={true}
                          previewFile={employees?.contract}
                          setFieldValue={setFieldValue}
                          handleFileCallback={addContract}
                          dismissFile={() => setFieldValue('isUpdateContract', true)}
                        />
                      </div>
                      <div className="col-12 col-lg-10 ms-auto">
                        <span
                          className="text-grey fs-7">{t('Document formats: PDF, DOC, DOCX')}</span>
                      </div>
                    </div>
                    {isOwnerRoleOrAdministratorRole ?
                      <AccessSettingsEmployeeTable
                        userBranchRelations={formattedArrayBranchAndRoleRelation}
                        setNewAccessSettingsState={setNewAccessSettingsState}
                      /> : null
                    }
                  </Col>
                  <Col xs={12} lg={3} className="ps-lg-3 ps-0">
                    <h4 className="text-dark pb-3 mt-1">
                      {t('Employee\'s photo')}
                    </h4>
                    <ImageCrop
                      previewPhoto={employees?.image}
                      setFieldValue={setFieldValue}
                      fieldSetter={() => handlePhoto(setFieldValue)}
                      fieldName="image"
                      dismissImage={() => setFieldValue('isUpdateImage', true)}
                      modalTitle={t('Employee\'s photo')}
                      notBGinfo
                    />
                  </Col>
                </div>
                <div className="d-sm-flex mb-5 mt-5">
                  <Link to='/employees-list'
                        className=" btn btn-outline-primary fw-normal mb-3 mb-sm-0 me-3 custom-btn rounded-1"
                        onClick={() => navigate('/employees-list')}>
                    {t("Cancel")}
                  </Link>
                  <Button type="submit" variant="primary" className="fw-bold custom-btn rounded-1">
                    {t('save')}
                  </Button>
                </div>

              </div>
            </Form>
          </>
        )}
      </Formik>
      <AccessDeletionBlockedPopup
        title={t('Access cannot be removed')}
        show={showAccessDeletionBlockedPopup}
        handleClose={handleCloseAccessDeletionBlockedPopup}
        employeeInfo={employeeInfo}
      />
      {isErrorModalOpen &&
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        />
      }
    </>
  );
};

export default EditingEmployee;
