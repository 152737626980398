import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableHeader,
  Pagination,
  Spinner,
  AddEmployeeFromAnotherBranchModal,
  AddAccessRightsModal,
  AccessDeletionConfirmationModal,
  EmptyList,
} from "@services/ui-components";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  currentBranchEmployeeIdVar,
  currentCompanyIdVar,
} from "@me-team/host/main/globalVar/state";
import { useGetBranchEmployeesQuery } from "@me-team/host/main/branches/graphql/branches.hooks";
import { ApolloError, useReactiveVar } from "@apollo/client";
import VioletBadge from "@services/ui-components/src/VioletBadge/VioletBadge";
import EmployeeCell from "@services/ui-components/src/EmployeeCell/EmployeeCell";
import { UserRole } from "@me-team/common/src/utils/enums";
import {
  BookingMainProxy,
  EmployeeProxy,
  UserBranchRelationInput,
} from "@me-team/host/main/graphql/types";
import {
  FormDataAccessRights,
  SelectedEmployee,
} from "@services/ui-components/src/Modal/EmployeeAccessToBranchModal/types";
import { useGetEmployeesWithBranchRelationsQuery } from "@me-team/host/main/employeeList/graphql/employeeList.hooks";
import ErrorService from "../../services/ErrorService";
import { ErrorModalComponent } from "@me-pos/error-modal";
import i18next from "i18next";
import {
  useCreateUpdateUserBranchRelationMutation,
  useDeleteUserBranchRelationMutation,
  useGetRecordsForEmployeeByBranchLazyQuery,
} from "@me-team/host/main/setUpAccessRole/graphql/setUpAccessRole.hooks";
import { getNextDay } from "../EmployeesList/utils/dateUtils";
import { DateTime } from "luxon";
import AccessDeletionBlockedPopup, {
  EmployeeInfo,
} from "@services/ui-components/src/ModalDelete/AccessDeletionBlockedPopup/AccessDeletionBlockedPopup";
import { RelationForDelete } from "../EditingEmployee/types";

const ITEMS_PER_PAGE = 20;

const BranchAccessSettings: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const nextDay = useMemo(() => getNextDay(DateTime.now().toISODate()), []);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentBranchEmployeeId = useReactiveVar(currentBranchEmployeeIdVar);

  const [currentPage, setCurrentPage] = useState(1);
  const [
    isShowAddEmployeeFromAnotherBranchModal,
    setIsShowAddEmployeeFromAnotherBranchModal,
  ] = useState(false);
  const [
    employeesWithoutAccessCurrentBranch,
    setEmployeesWithoutAccessCurrentBranch,
  ] = useState<EmployeeProxy[]>([]);
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] =
    useState<SelectedEmployee>(null);
  const [showAddAccessRightsModal, setShowAddAccessRightsModal] =
    useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(null);
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfo>(null);
  const [showAccessDeletionBlockedPopup, setShowAccessDeletionBlockedPopup] =
    useState(false);
  const [selectedBranchForDelete, setSelectedBranchForDelete] =
    useState<RelationForDelete>(null);
  const [
    showAccessDeletionConfirmationModal,
    setShowAccessDeletionConfirmationModal,
  ] = useState(false);

  const baseVariables = {
    companyId: +currentCompanyId,
    page: 1,
    itemsPerPage: 10000,
    onlyActive: true,
  };

  const {
    data: employeesForAllBranchesData,
    loading: loadingEmployeesForAllBranche,
  } = useGetEmployeesWithBranchRelationsQuery({
    variables: baseVariables,
    context: { errorType: "local" },
    skip: !currentCompanyId,
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  const {
    data: employeesForCurrentBranchData,
    loading: loadingEmployeesForCurrentBranch,
  } = useGetEmployeesWithBranchRelationsQuery({
    variables: {
      ...baseVariables,
      branchId: +id,
    },
    context: { errorType: "local" },
    skip: !currentCompanyId,
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  const [createUserBranchRelation] = useCreateUpdateUserBranchRelationMutation({
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  useEffect(() => {
    if (loadingEmployeesForAllBranche || loadingEmployeesForCurrentBranch)
      return;

    const employeesForAllBranchesList: EmployeeProxy[] =
      employeesForAllBranchesData?.user?.company?.employees?.employees || [];
    const employeesForCurrentBranchList: EmployeeProxy[] =
      employeesForCurrentBranchData?.user?.company?.employees?.employees || [];

    const employeesWithoutAccessCurrentBranch: EmployeeProxy[] =
      employeesForAllBranchesList.filter(
        (employee) =>
          !employeesForCurrentBranchList.some(
            (currentEmployee) => currentEmployee.id === employee.id
          )
      );
    setEmployeesWithoutAccessCurrentBranch(employeesWithoutAccessCurrentBranch);
  }, [
    employeesForAllBranchesData,
    employeesForCurrentBranchData,
    loadingEmployeesForAllBranche,
    loadingEmployeesForCurrentBranch,
  ]);

  const {
    data: employeesData,
    loading,
    refetch,
  } = useGetBranchEmployeesQuery({
    skip: !currentCompanyId,
    variables: {
      companyId: +currentCompanyId,
      branchId: +id,
      itemsPerPage: ITEMS_PER_PAGE,
      page: currentPage,
      onlyActive: true,
    },
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  const [GetRecordsForEmployeeByBranch, {}] =
    useGetRecordsForEmployeeByBranchLazyQuery({
      context: { errorType: "local" },
      onError: (error) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
      },
    });

  const [
    deleteUserBranchRelation,
    { loading: deleteUserBranchRelationLoading },
  ] = useDeleteUserBranchRelationMutation({
    context: {
      errorType: "local",
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  useEffect(() => {
    if (
      !loading &&
      employeesData &&
      (!userBranchRoleId || userBranchRoleId === UserRole.EMPLOYEE)
    ) {
      navigate(`/branches/main/branch/${id}`);
    }
  }, [loading, employeesData]);

  const employees =
    employeesData?.user?.company?.branches[0]?.employeesPaginated?.employees;
  const pageCount = parseInt(
    employeesData?.user?.company?.branches[0]?.employeesPaginated?.pageCount ||
      "0"
  );
  const totalItems =
    employeesData?.user?.company?.branches[0]?.employeesPaginated?.totalCount ||
    0;
  const firstItemIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const lastItemIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalItems);
  const userBranchRoleId = employees
    ?.find(({ id }) => id === currentBranchEmployeeId)
    ?.userBranchRelations?.find(({ branch }) => branch.id === Number(id))
    ?.role?.id;

  const handleCloseAddEmployeeFromAnotherBranch = () => {
    setIsShowAddEmployeeFromAnotherBranchModal(false);
  };

  const handleOpenAddAccessRightsModal = () => {
    setShowAddAccessRightsModal(true);
  };

  const handleCloseAddAccessRightsModal = () => {
    setShowAddAccessRightsModal(false);
    setSelectedEmployeeToAdd(null);
  };

  const handleToggleAccessDeletionBlockedPopup = () =>
    setShowAccessDeletionBlockedPopup((prevState) => !prevState);
  const handleToggleAccessDeletionConfirmationModal = () => {
    deleteUserBranchRelation({
      variables: {
        companyId: +currentCompanyId,
        ids: [selectedBranchForDelete.relation.relation],
      },
      onCompleted(data) {
        data && refetch();
      },
    });
    setShowAccessDeletionConfirmationModal((prevState) => !prevState);
  };

  const handleAddAccessRightsNewEmployee = (values: FormDataAccessRights) => {
    const input: UserBranchRelationInput[] = [
      {
        branchId: +values.branchId,
        employeeId: +values.employeeId,
        roleId: +values.roleId,
      },
    ];
    currentCompanyId &&
      createUserBranchRelation({
        variables: {
          companyId: +currentCompanyId,
          input: input,
        },
        onCompleted: (data) => {
          data && refetch();
        },
      });
    handleCloseAddAccessRightsModal();
  };

  const handleEditRoleModal = (employee: (typeof employees)[0]) => {
    setSelectedEmployeeToAdd({
      id: employee.id,
      name: employee?.name,
      surname: employee?.surname,
      roleId: employee?.userBranchRelations.find(
        (item) => item.branch.id === +id
      )?.role.id,
    });
    handleOpenAddAccessRightsModal();
  };

  const handleRemoveAccess = async (employeeId: number) => {
    const selectedEmployee =
      employeesData.user.company.branches[0].employeesPaginated.employees.find(
        (item) => item.id === employeeId
      );
    const selectedEmployeeRole = selectedEmployee.role;
    const userBranchRelation = selectedEmployee.userBranchRelations.find(
      (item) => item.branch.id === +id
    );
    const branch = userBranchRelation.branch;
    const selectedEmployeeRelationId = userBranchRelation.id;

    const { data } =
      currentCompanyId &&
      (await GetRecordsForEmployeeByBranch({
        variables: {
          companyId: +currentCompanyId,
          employeeId: employeeId,
          removeCanceledBookings: false,
          branchId: +id,
          startDate: nextDay,
        },
      }));

    if (!data) return;
    const employeeData: EmployeeProxy =
      data?.user?.company?.employees?.employees?.[0];
    const bookingsData: BookingMainProxy[] = employeeData?.bookings?.bookings;
    const isBookings: boolean =
      bookingsData && employeeData && bookingsData?.length > 0;
    if (isBookings) {
      const info: EmployeeInfo = {
        name: employeeData?.name,
        surname: employeeData?.surname ? employeeData?.surname : null,
        branch: bookingsData?.[0].branch?.name,
        branchId: bookingsData?.[0].branch?.id,
      };
      setEmployeeInfo(info);
      info && setShowAccessDeletionBlockedPopup(true);
    } else {
      const employeeNameSurname: string = `${employeeData?.name} ${employeeData?.surname}`;
      setEmployeeInfo(null);
      setSelectedBranchForDelete({
        employeeNameSurname: employeeNameSurname,
        relation: {
          relation: selectedEmployeeRelationId,
          role: selectedEmployeeRole,
          branch,
        },
      });
      setShowAccessDeletionConfirmationModal(true);
    }
  };

  const handleGrantAccessModal = () => {
    setIsShowAddEmployeeFromAnotherBranchModal(true);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (
    loading ||
    loadingEmployeesForAllBranche ||
    loadingEmployeesForCurrentBranch ||
    deleteUserBranchRelationLoading
  )
    return <Spinner />;

  return (
    <>
      <VioletBadge>lorem</VioletBadge>
      <TableHeader
        title={t("Access settings")}
        paths={[
          { path: "/branches", label: "Branches" },
          {
            path: `/branches/main/branch/${id}`,
            label:
              employeesData?.user?.company?.branches[0].name || t("Branch"),
          },
          { path: `/branch/access-settings/${id}`, label: "Access settings" },
        ]}
        buttons={
          <Row className="justify-content-end mt-3 mt-lg-0">
            <Col>
              <Button
                variant="outline-primary"
                className="fw-normal w-100 rounded-1 px-1"
                disabled={
                  !userBranchRoleId || userBranchRoleId === UserRole.EMPLOYEE
                }
                onClick={() => handleGrantAccessModal()}
              >
                <i className="bi bi-plus-lg" />
                <span className="ms-2">{t("Grant access")}</span>
              </Button>
            </Col>
          </Row>
        }
        colSize={10}
        btnColSize={2}
      />

      {employees?.length ? (
        <>
          <div className="table-responsive scrollbar mb-4">
            <table className="table mt-6 mb-2">
              <thead>
                <tr className="fs-7">
                  <th className="col-5">{t("Employee")}</th>
                  <th className="col-5">{t("Access rights (role)")}</th>
                  <th className="col-2"></th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={employee.id}>
                    <td className="align-middle">
                      <EmployeeCell i={employee} />
                    </td>
                    <td className="align-middle">
                      {
                        employee?.userBranchRelations.find(
                          (item) => item.branch.id === +id
                        )?.role.name
                      }
                    </td>
                    <td>
                      {employee?.role.id !== UserRole.OWNER && (
                        <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                          <OverlayTrigger
                            overlay={<Tooltip>{t("Edit access")}</Tooltip>}
                          >
                            <Button
                              variant="outline-secondary"
                              disabled={
                                !userBranchRoleId ||
                                userBranchRoleId === UserRole.EMPLOYEE
                              }
                              onClick={() => handleEditRoleModal(employee)}
                              className="p-2 fs-7 text-dark rounded-1"
                            >
                              <i className="bi bi-pencil px-1" />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip>{t("Remove access to branch")}</Tooltip>
                            }
                          >
                            <Button
                              variant="outline-secondary"
                              disabled={
                                !userBranchRoleId ||
                                userBranchRoleId === UserRole.EMPLOYEE ||
                                employee?.userBranchRelations.length === 1
                              }
                              onClick={() => handleRemoveAccess(employee.id)}
                              className="py-2 text-dark rounded-1"
                            >
                              <i className="bi bi-trash3" />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-5">
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        </>
      ) : (
        <EmptyList title={t("NoDataFoundForTheGivenValues")} />
      )}

      <AddEmployeeFromAnotherBranchModal
        isShowAddEmployeeFromAnotherBranchModal={
          isShowAddEmployeeFromAnotherBranchModal
        }
        onCloseAddEmployeeFromAnotherBranch={
          handleCloseAddEmployeeFromAnotherBranch
        }
        employeesWithoutAccessCurrentBranch={
          employeesWithoutAccessCurrentBranch
        }
        onSelectedEmployeeToAdd={setSelectedEmployeeToAdd}
        toggleAddAccessRightsModal={handleOpenAddAccessRightsModal}
      />
      <AddAccessRightsModal
        branchToAdd={employeesData?.user?.company?.branches[0]}
        isAddAccessRightsModal={showAddAccessRightsModal}
        onCloseAddAccessRightsModal={handleCloseAddAccessRightsModal}
        selectedEmployeeToAdd={selectedEmployeeToAdd}
        onAddAccessRightsEmployee={handleAddAccessRightsNewEmployee}
      />
      <AccessDeletionBlockedPopup
        title={t("Deletion is not possible")}
        show={showAccessDeletionBlockedPopup}
        handleClose={handleToggleAccessDeletionBlockedPopup}
        employeeInfo={employeeInfo}
      />
      <AccessDeletionConfirmationModal
        show={showAccessDeletionConfirmationModal}
        handleClose={handleToggleAccessDeletionConfirmationModal}
        selectedBranchForDelete={selectedBranchForDelete}
        onDelete={handleToggleAccessDeletionConfirmationModal}
      />
      {isErrorModalOpen && (
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null);
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        />
      )}
    </>
  );
};

export default BranchAccessSettings;
