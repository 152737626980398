import React, {useState} from 'react';
import {
    Modal,
    Button,
    Col,
    Row,
    OverlayTrigger,
    Tooltip,
    Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import DurationInputCustomModal from './DurationInputCustomModal';
import { useReactiveVar } from '@apollo/client';
import {
    useCreateCustomDayMutation, useDeleteCustomDayMutation,
    useGetEmployeeByIdScheduleQuery, useUpdateCustomDayMutation,
} from '@me-team/host/main/work-shedule/graphql/workShedule.hooks';
import { DateTime, DateObjectUnits } from 'luxon';
import {Link} from "react-router-dom";
import { ApolloError } from '@apollo/client';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/main/globalVar/state';
type CustomDayModalProps = {
    show: boolean;
    toggleModal: () => void;
    date?: DateObjectUnits;
    resource: any;
    forceRefresh: () => void;
    toggleShowToast: (str: string) => void
};
interface Range {
    startTime?: string;
    endTime?: string;
}

interface FormValues {
    ranges: Range[];
    toDeleteRanges: any
}
const CustomDayModal: React.FC<CustomDayModalProps> = ({
                                                           show,
                                                           toggleModal,
                                                           date,
                                                           resource,
                                                           forceRefresh,
                                                           toggleShowToast
                                                       }) => {
    const employeeId = resource?.id || resource?.resource
    const { t, i18n } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorBodyMessage, setErrorBodyMessage] = useState('');
    const [isDeletingAllRanges, setIsDeletingAllRanges] = useState(false);
    const [errorModal, setErrorModal] = useState(false)

    const branchId = useReactiveVar(currentBranchIdVar);
    const { data, refetch } = useGetEmployeeByIdScheduleQuery({
        skip: !employeeId || !currentCompanyId,
        variables: { employeeId, companyId: +currentCompanyId },
        onError: (error) => {
            console.error(error);
        },
        context: {
            errorType: 'global',
        },
    });
    const dateWork = DateTime.fromObject(date);
    const branchName = data?.user?.company?.branches.find( i => i?.id === branchId )?.name;
    const employee = data?.user?.company?.employees?.employees[0];

    const [createCustomDay] = useCreateCustomDayMutation({
        context: {
            errorType: 'local',
        },
    });

    const [updateCustomDay] = useUpdateCustomDayMutation({
        context: {
            errorType: 'local',
        },
    });
    const [deleteCustomDay] = useDeleteCustomDayMutation({
        context: {
            errorType: 'local',
        },
    });

    const formattedDate = dateWork.toFormat('yyyy-MM-dd');

    const matchedCustomDays = employee?.customDays.filter((customDay) => {
        const customDayDate = customDay.date.split(' ')[0];
        return customDayDate === formattedDate;
    })
    const existingRanges = React.useMemo(() => {
        if (matchedCustomDays && matchedCustomDays.length > 0) {
            const allPeriods = matchedCustomDays.flatMap((customDay) =>
                customDay.customDayPeriods.map((period) => ({
                    customDayId: customDay.id,
                    customDayPeriodId: period.id,
                    startTime: period.startTime,
                    endTime: period.endTime,
                }))
            );
            return allPeriods;
        }
        return [{ startTime: '', endTime: '' }];
    }, [matchedCustomDays, refetch, data]);
    const toggleErrorModal = () => setErrorModal(prev => !prev)

    const handleSave = async (values: FormValues) => {
        try {
            if (matchedCustomDays && matchedCustomDays.length > 0) {
                const customDayId = matchedCustomDays[0].id;
                if (values.ranges.length > 0) {
                    try {
                        await updateCustomDay({
                            variables: {
                                customDayId: customDayId,
                                input: {
                                    customDayPeriods: values.ranges.every(
                                        (range: Range) => range.startTime === "" && range.endTime === ""
                                    )
                                        ? []
                                        : values.ranges.map((range: Range) => ({
                                            startTime: range.startTime,
                                            endTime: range.endTime,
                                        })),
                                },
                            },
                        });
                    } catch (error) {
                        console.error('Error updating custom day:', error);
                        if (error.message === "There is a booking for these hours") {
                            setErrorMessage(t('Editing schedule for Custom Day is not possible'));
                            setErrorBodyMessage(t('Editing schedule for Custom Day is not possible because the employee has a booking for this day. Go to the Logbook to move or cancel them'))
                            return;
                        }

                        return;
                    }
                } else {
                    try {
                        await deleteCustomDay({
                            variables: { customDayId },
                        });
                    } catch (error) {
                        console.error('Error deleting custom day:', error);
                        if (error.message === "There is a booking for these hours") {
                            setErrorMessage(t('Delete schedule for Custom Day is not possible'));
                            setErrorBodyMessage(t('Deleting schedule for Custom Day is not possible because the employee has a booking for this day. Go to the Logbook to move or cancel them'));
                            return;
                        }

                        return;
                    }
                }
            } else {
                try {
                    branchId && await createCustomDay({
                        variables: {
                            employeeId: resource?.id,
                            branchId: +branchId,
                            input: {
                                date: formattedDate,
                                customDayPeriods: values.ranges.map((range) => ({
                                    startTime: range.startTime,
                                    endTime: range.endTime,
                                })),
                            },
                        },
                    });
                } catch (error) {
                    console.error('Error creating custom day:', error);
                    if (error instanceof ApolloError) {
                        const graphQLErrors = error.graphQLErrors;
                        const firstMessage = Object.values(graphQLErrors[0].extensions?.validation || {})
                            .flat()[0]?.message === 'There is an uncanceled entry for this date, please edit it.'
                        firstMessage && toggleModal()
                        firstMessage && setTimeout(()=> toggleErrorModal(), 0 )

                    }
                    if (error.message === "There is a booking for these hours") {
                        setErrorMessage(t('Unable to create vacation'))
                        setErrorBodyMessage(t('It is not possible to create a vacation because the employee has a booking for the selected period. Go to the Logbook to move or cancel them.'));
                        return;
                    }

                    return;
                }
            }
            await refetch();
            forceRefresh()
            toggleShowToast(t('Saved'));
            toggleModal();
        } catch (error) {
            console.error('Error handling save:', error);
        }
    };

    const validationSchema = Yup.lazy((values) => {
        if (
            values.ranges.length === 1 &&
            values?.toDeleteRanges.length > 0 &&
            values?.ranges[0]?.startTime === undefined &&
            values?.ranges[0]?.endTime === undefined
        ) {
            return Yup.object().shape({
                ranges: Yup.array().of(
                    Yup.object().shape({
                        startTime: Yup.string().nullable(),
                        endTime: Yup.string().nullable(),
                    })
                ),
            });
        } else {
            return Yup.object().shape({
                ranges: Yup.array().of(
                    Yup.object()
                        .shape({
                            startTime: Yup.string().required(t('Start time is required')),
                            endTime: Yup.string().required(t('End time is required')),
                        })
                        .test(
                            'is-greater',
                            t('End time must be after start time'),
                            function (value) {
                                const { startTime, endTime } = value;
                                if (startTime && endTime) {
                                    const start = DateTime.fromISO(`1970-01-01T${startTime}`);
                                    const end = DateTime.fromISO(`1970-01-01T${endTime}`);
                                    return end > start;
                                }
                                return true;
                            }
                        )
                ),
            });
        }
    });

    return (
        <>
        <Modal
            show={show}
            onHide={toggleModal}
            centered
            className="modal"
            id="modalContentContainer"
            tabIndex="-1"
            role="dialog"
        >

            <Modal.Header closeButton className="border-0">
                {errorMessage ? (
                    <h5 className="m-0 fw-normal">{errorMessage}</h5>
                ) : (
                    <h5 className="m-0">{t('Set Custom Day')}</h5>
                )}
            </Modal.Header>
            <Formik<FormValues>
                initialValues={{
                    ranges: existingRanges,
                    toDeleteRanges: []
                }}
                validationSchema={validationSchema}
                onSubmit={handleSave}
                enableReinitialize
            >
                {({ values, errors, touched }) => (
                    <Form>
                        {errorMessage ? (
                                <>

                                    <Modal.Body style={{padding: '16px 28px'}}>
                                        <Row className="justify-content-center text-center">
                                        <span className="">
                                           {errorBodyMessage}
                                        </span>
                                        </Row>

                                    </Modal.Body>
                                    <Modal.Footer className="border-0">
                                        <Link className=" btn btn-primary fw-bold w-100 fs-7 "
                                              to={`/logbook/journal/` + branchId}>
                                            {t('Go to Logbook')}
                                        </Link>
                                    </Modal.Footer>
                                </>
                            ) : (
                                <>
                                    <Modal.Body>
                                        <Row className="py-3 m-0">
                                            <Col sm={3}>
                                                <strong>
                                        <span className="text-dark fs-7">
                                            {t('Employee')}
                                        </span>
                                                </strong>
                                            </Col>
                                            <Col sm={9}>
                                    <span className="text-dark fs-7">
                                        {employee?.name} {employee?.surname}
                                    </span>
                                            </Col>
                                        </Row>
                                        <Row className="py-3 m-0">
                                            <Col sm={3}>
                                                <strong>
                                        <span className="text-dark fs-7">
                                            {t('branch-custom-modal')}
                                        </span>
                                                </strong>
                                            </Col>
                                            <Col sm={9}>
                                    <span className="text-dark fs-7">
                                        {branchName}
                                    </span>
                                            </Col>
                                        </Row>
                                        <Row className="py-3 m-0">
                                            <Col sm={3}>
                                                <strong>
                                        <span className="text-dark fs-7">
                                            {t('Date')}
                                        </span>
                                                </strong>
                                            </Col>
                                            <Col sm={9}>
                                    <span className="text-dark fs-7">
                                        {dateWork
                                            .setLocale(i18n.language)
                                            .toFormat('ccc, dd LLLL yyyy')}
                                    </span>
                                            </Col>
                                        </Row>
                                        <Row className="py-3 m-0">
                                            <Col sm={3}>
                                                <strong>
                                        <span className="text-dark fs-7">
                                            {t('Working hours')}
                                        </span>
                                                </strong>
                                            </Col>
                                            <Col sm={9}>
                                                <FieldArray name="ranges">
                                                    {(arrayHelpers) => {
                                                        if (values.ranges.length === 0) {
                                                            arrayHelpers.push({
                                                                startTime: '',
                                                                endTime: '',
                                                            });
                                                        }

                                                        return (
                                                            <>
                                                                {values.ranges &&
                                                                    values.ranges.length > 0 &&
                                                                    values.ranges.map(
                                                                        (range, index: number) => (
                                                                            <Row
                                                                                key={index}
                                                                                className={`align-items-center ${
                                                                                    index !== 0
                                                                                        ? 'pt-3'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                <Col
                                                                                    md={10}
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <Container className="p-0">
                                                                                        <DurationInputCustomModal
                                                                                            name={`ranges[${index}].startTime`}
                                                                                        />
                                                                                    </Container>
                                                                                    <i className="bi bi-dash mx-1"></i>
                                                                                    <Container className="p-0">
                                                                                        <DurationInputCustomModal
                                                                                            name={`ranges[${index}].endTime`}
                                                                                        />
                                                                                    </Container>
                                                                                </Col>
                                                                                <Col
                                                                                    md={2}
                                                                                    className="d-flex justify-content-between align-items-center ps-0"
                                                                                >
                                                                                    {values.ranges
                                                                                            .length >
                                                                                        0 && (
                                                                                            <OverlayTrigger
                                                                                                overlay={
                                                                                                    <Tooltip>
                                                                                                        {t(
                                                                                                            'Remove range'
                                                                                                        )}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    onClick={() => {
                                                                                                        values.toDeleteRanges.push(values.ranges[index]);
                                                                                                        arrayHelpers.remove(index);
                                                                                                    }}

                                                                                                    className="bi bi-trash cursor-pointer"
                                                                                                ></i>
                                                                                            </OverlayTrigger>
                                                                                        )}
                                                                                    {index ===
                                                                                        values
                                                                                            .ranges
                                                                                            .length -
                                                                                        1 && (
                                                                                            <OverlayTrigger
                                                                                                overlay={
                                                                                                    <Tooltip>
                                                                                                        {t(
                                                                                                            'Add range'
                                                                                                        )}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className="bi bi-plus-circle cursor-pointer"
                                                                                                    onClick={() =>
                                                                                                        arrayHelpers.push(
                                                                                                            {
                                                                                                                startTime:
                                                                                                                    '',
                                                                                                                endTime:
                                                                                                                    '',
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                ></i>
                                                                                            </OverlayTrigger>
                                                                                        )}
                                                                                </Col>
                                                                                <Col
                                                                                    md={10}
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    {errors.ranges &&
                                                                                        errors.ranges[index] &&
                                                                                        touched.ranges &&
                                                                                        touched.ranges[index] && (
                                                                                            <>
                                                                                                {typeof errors.ranges[index] === 'string' && (
                                                                                                    <p className="fs-7 p-1 text-danger m-0">{errors.ranges[index]}</p>
                                                                                                )}
                                                                                                {typeof errors.ranges[index] === 'object' && (
                                                                                                    <>
                                                                                                        {errors.ranges[index].startTime && touched.ranges[index].startTime && (
                                                                                                            <p className="fs-7 p-1 text-danger m-0">{errors.ranges[index].startTime}</p>
                                                                                                        )}
                                                                                                        {errors.ranges[index].endTime && touched.ranges[index].endTime && (
                                                                                                            <p className="fs-7 p-1 text-danger m-0">{errors.ranges[index].endTime}</p>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}


                                                                                </Col>

                                                                            </Row>
                                                                        )
                                                                    )}
                                                            </>
                                                        );
                                                    }}
                                                </FieldArray>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer className="border-0">
                                        <Col>
                                            <Button
                                                variant="outline-primary"
                                                type="button"
                                                className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                                onClick={toggleModal}
                                            >
                                                {t('cancel')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="btn btn-primary w-100 text-truncate"
                                            >
                                                {matchedCustomDays?.length > 0
                                                    ? t('Edit')
                                                    : t('save')}
                                            </Button>
                                        </Col>
                                    </Modal.Footer>
                                </>
                            )
                        }
                    </Form>
                )}
            </Formik>
        </Modal>
        <Modal size="lg" show={errorModal}
                   onHide={() => setErrorModal(false)}
               centered>
            <Modal.Header className='border-0' closeButton>
                <h5 className="m-0">{t('It is not possible to create a custom day')}</h5>
            </Modal.Header>
            <Modal.Body>
                <p className="text-center mb-0">
                    {t('It is not possible to create a custom day because the employee has records that do not fall within the selected time period')}.{' '}
                    {t('Go to the records journal to modify the entries or choose a different working time for this day')}
                </p>
            </Modal.Body>
            <Modal.Footer className="border-0 d-flex justify-content-center">
                <Link className="btn btn-primary fw-bold fs-7 px-4" to={`/logbook/journal/` + branchId}>
                    {t('Go to Logbook')}
                </Link>
            </Modal.Footer>

        </Modal>
        </>
    );
};

export default CustomDayModal;
