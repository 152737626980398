/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetRelationsDocument = gql`
    query GetRelations {
  relations {
    timezones {
      id
      name
    }
    countries {
      isoCode
      id
      name
      localeCode
      timezone {
        id
        name
      }
      europeanUnion
    }
    currencies {
      currency
      id
      code
      countries {
        id
        name
        europeanUnion
      }
    }
    languages {
      id
      name
      isoCode
    }
  }
}
    `;

/**
 * __useGetRelationsQuery__
 *
 * To run a query within a React component, call `useGetRelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRelationsQuery(baseOptions?: Apollo.QueryHookOptions<GetRelationsQuery, GetRelationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelationsQuery, GetRelationsQueryVariables>(GetRelationsDocument, options);
      }
export function useGetRelationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelationsQuery, GetRelationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelationsQuery, GetRelationsQueryVariables>(GetRelationsDocument, options);
        }
export function useGetRelationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRelationsQuery, GetRelationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRelationsQuery, GetRelationsQueryVariables>(GetRelationsDocument, options);
        }
export type GetRelationsQueryHookResult = ReturnType<typeof useGetRelationsQuery>;
export type GetRelationsLazyQueryHookResult = ReturnType<typeof useGetRelationsLazyQuery>;
export type GetRelationsSuspenseQueryHookResult = ReturnType<typeof useGetRelationsSuspenseQuery>;
export type GetRelationsQueryResult = Apollo.QueryResult<GetRelationsQuery, GetRelationsQueryVariables>;
export const GetCityDocument = gql`
    query GetCity($countryId: Int!) {
  relations {
    cities(countryId: $countryId) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCityQuery__
 *
 * To run a query within a React component, call `useGetCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetCityQuery(baseOptions: Apollo.QueryHookOptions<GetCityQuery, GetCityQueryVariables> & ({ variables: GetCityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCityQuery, GetCityQueryVariables>(GetCityDocument, options);
      }
export function useGetCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCityQuery, GetCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCityQuery, GetCityQueryVariables>(GetCityDocument, options);
        }
export function useGetCitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCityQuery, GetCityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCityQuery, GetCityQueryVariables>(GetCityDocument, options);
        }
export type GetCityQueryHookResult = ReturnType<typeof useGetCityQuery>;
export type GetCityLazyQueryHookResult = ReturnType<typeof useGetCityLazyQuery>;
export type GetCitySuspenseQueryHookResult = ReturnType<typeof useGetCitySuspenseQuery>;
export type GetCityQueryResult = Apollo.QueryResult<GetCityQuery, GetCityQueryVariables>;
export const GetInfoCompanyDocument = gql`
    query GetInfoCompany($companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      name
      category {
        id
        name
        type
      }
      country {
        id
        name
        currencies {
          code
          currency
          id
        }
        timezone {
          id
          name
        }
        cities {
          id
          name
        }
      }
      city {
        id
        name
        country {
          id
          name
        }
      }
      address
      phone
      image
      website
      currency {
        id
        code
        currency
      }
      timeZone {
        id
        name
      }
      deleted
      language {
        name
        id
        isoCode
      }
      languages {
        id
        isoCode
        name
      }
      messageLanguage {
        id
        name
        isoCode
      }
      primaryLanguage {
        id
        name
        isoCode
      }
    }
  }
}
    `;

/**
 * __useGetInfoCompanyQuery__
 *
 * To run a query within a React component, call `useGetInfoCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetInfoCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetInfoCompanyQuery, GetInfoCompanyQueryVariables> & ({ variables: GetInfoCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoCompanyQuery, GetInfoCompanyQueryVariables>(GetInfoCompanyDocument, options);
      }
export function useGetInfoCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoCompanyQuery, GetInfoCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoCompanyQuery, GetInfoCompanyQueryVariables>(GetInfoCompanyDocument, options);
        }
export function useGetInfoCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInfoCompanyQuery, GetInfoCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInfoCompanyQuery, GetInfoCompanyQueryVariables>(GetInfoCompanyDocument, options);
        }
export type GetInfoCompanyQueryHookResult = ReturnType<typeof useGetInfoCompanyQuery>;
export type GetInfoCompanyLazyQueryHookResult = ReturnType<typeof useGetInfoCompanyLazyQuery>;
export type GetInfoCompanySuspenseQueryHookResult = ReturnType<typeof useGetInfoCompanySuspenseQuery>;
export type GetInfoCompanyQueryResult = Apollo.QueryResult<GetInfoCompanyQuery, GetInfoCompanyQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: Int!, $input: CompanyInput!) {
  updateCompany(id: $id, input: $input) {
    id
    name
    country {
      name
      europeanUnion
      id
      timezone {
        id
        name
      }
    }
    currency {
      id
      currency
    }
    image
    deleted
    phone
    address
    bookingQrLink
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CreateCompanyBeautySalonDocument = gql`
    mutation CreateCompanyBeautySalon($input: CompanyInput!) {
  createCompany(input: $input) {
    category {
      id
      name
    }
    name
    country {
      id
      name
    }
  }
}
    `;
export type CreateCompanyBeautySalonMutationFn = Apollo.MutationFunction<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>;

/**
 * __useCreateCompanyBeautySalonMutation__
 *
 * To run a mutation, you first call `useCreateCompanyBeautySalonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyBeautySalonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyBeautySalonMutation, { data, loading, error }] = useCreateCompanyBeautySalonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyBeautySalonMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>(CreateCompanyBeautySalonDocument, options);
      }
export type CreateCompanyBeautySalonMutationHookResult = ReturnType<typeof useCreateCompanyBeautySalonMutation>;
export type CreateCompanyBeautySalonMutationResult = Apollo.MutationResult<CreateCompanyBeautySalonMutation>;
export type CreateCompanyBeautySalonMutationOptions = Apollo.BaseMutationOptions<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>;
export const GetGmtDocument = gql`
    query GetGMT {
  relations {
    timezones {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGmtQuery__
 *
 * To run a query within a React component, call `useGetGmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGmtQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGmtQuery(baseOptions?: Apollo.QueryHookOptions<GetGmtQuery, GetGmtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGmtQuery, GetGmtQueryVariables>(GetGmtDocument, options);
      }
export function useGetGmtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGmtQuery, GetGmtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGmtQuery, GetGmtQueryVariables>(GetGmtDocument, options);
        }
export function useGetGmtSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGmtQuery, GetGmtQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGmtQuery, GetGmtQueryVariables>(GetGmtDocument, options);
        }
export type GetGmtQueryHookResult = ReturnType<typeof useGetGmtQuery>;
export type GetGmtLazyQueryHookResult = ReturnType<typeof useGetGmtLazyQuery>;
export type GetGmtSuspenseQueryHookResult = ReturnType<typeof useGetGmtSuspenseQuery>;
export type GetGmtQueryResult = Apollo.QueryResult<GetGmtQuery, GetGmtQueryVariables>;
export const GetLanguagesDocument = gql`
    query GetLanguages {
  relations {
    languages {
      id
      name
      isoCode
    }
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export function useGetLanguagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetLanguagesSuspenseQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetCurrenciesDocument = gql`
    query GetCurrencies {
  relations {
    currencies {
      id
      currency
      code
    }
  }
}
    `;

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
      }
export function useGetCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export function useGetCurrenciesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>;
export type GetCurrenciesSuspenseQueryHookResult = ReturnType<typeof useGetCurrenciesSuspenseQuery>;
export type GetCurrenciesQueryResult = Apollo.QueryResult<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany($companyId: Int!) {
  user {
    company(id: $companyId) {
      id
      name
      country {
        id
        name
      }
      city {
        id
        name
      }
      address
      phone
      website
      timeZone {
        id
        name
      }
      languages {
        id
        name
      }
      currency {
        id
        currency
      }
      messageLanguage {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables> & ({ variables: GetCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export function useGetCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanySuspenseQueryHookResult = ReturnType<typeof useGetCompanySuspenseQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetBranchesIsDeletedDocument = gql`
    query GetBranchesIsDeleted($isDeleted: Boolean, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      branches(isDeleted: $isDeleted) {
        id
        deleted
        address
        bookingQrLink
        country {
          id
          name
        }
        onlineRegistration
        currencyCode
        name
        removedOfTariff
        timeZone
      }
    }
  }
}
    `;

/**
 * __useGetBranchesIsDeletedQuery__
 *
 * To run a query within a React component, call `useGetBranchesIsDeletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesIsDeletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesIsDeletedQuery({
 *   variables: {
 *      isDeleted: // value for 'isDeleted'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBranchesIsDeletedQuery(baseOptions: Apollo.QueryHookOptions<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables> & ({ variables: GetBranchesIsDeletedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables>(GetBranchesIsDeletedDocument, options);
      }
export function useGetBranchesIsDeletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables>(GetBranchesIsDeletedDocument, options);
        }
export function useGetBranchesIsDeletedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables>(GetBranchesIsDeletedDocument, options);
        }
export type GetBranchesIsDeletedQueryHookResult = ReturnType<typeof useGetBranchesIsDeletedQuery>;
export type GetBranchesIsDeletedLazyQueryHookResult = ReturnType<typeof useGetBranchesIsDeletedLazyQuery>;
export type GetBranchesIsDeletedSuspenseQueryHookResult = ReturnType<typeof useGetBranchesIsDeletedSuspenseQuery>;
export type GetBranchesIsDeletedQueryResult = Apollo.QueryResult<GetBranchesIsDeletedQuery, GetBranchesIsDeletedQueryVariables>;
export const GetRoleUserDocument = gql`
    query GetRoleUser($companyId: Int!) {
  user {
    id
    email
    role {
      id
      name
    }
    currentBranch {
      id
    }
    company(id: $companyId) {
      id
    }
  }
}
    `;

/**
 * __useGetRoleUserQuery__
 *
 * To run a query within a React component, call `useGetRoleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleUserQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetRoleUserQuery(baseOptions: Apollo.QueryHookOptions<GetRoleUserQuery, GetRoleUserQueryVariables> & ({ variables: GetRoleUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleUserQuery, GetRoleUserQueryVariables>(GetRoleUserDocument, options);
      }
export function useGetRoleUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleUserQuery, GetRoleUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleUserQuery, GetRoleUserQueryVariables>(GetRoleUserDocument, options);
        }
export function useGetRoleUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRoleUserQuery, GetRoleUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRoleUserQuery, GetRoleUserQueryVariables>(GetRoleUserDocument, options);
        }
export type GetRoleUserQueryHookResult = ReturnType<typeof useGetRoleUserQuery>;
export type GetRoleUserLazyQueryHookResult = ReturnType<typeof useGetRoleUserLazyQuery>;
export type GetRoleUserSuspenseQueryHookResult = ReturnType<typeof useGetRoleUserSuspenseQuery>;
export type GetRoleUserQueryResult = Apollo.QueryResult<GetRoleUserQuery, GetRoleUserQueryVariables>;
export const GetCompanyModulesDocument = gql`
    query GetCompanyModules($companyId: Int!) {
  user {
    company(id: $companyId) {
      modules {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompanyModulesQuery__
 *
 * To run a query within a React component, call `useGetCompanyModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyModulesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyModulesQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyModulesQuery, GetCompanyModulesQueryVariables> & ({ variables: GetCompanyModulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>(GetCompanyModulesDocument, options);
      }
export function useGetCompanyModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>(GetCompanyModulesDocument, options);
        }
export function useGetCompanyModulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>(GetCompanyModulesDocument, options);
        }
export type GetCompanyModulesQueryHookResult = ReturnType<typeof useGetCompanyModulesQuery>;
export type GetCompanyModulesLazyQueryHookResult = ReturnType<typeof useGetCompanyModulesLazyQuery>;
export type GetCompanyModulesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyModulesSuspenseQuery>;
export type GetCompanyModulesQueryResult = Apollo.QueryResult<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>;
export const GetActiveCompaniesDocument = gql`
    query GetActiveCompanies($companyId: Int!) {
  user {
    companies {
      id
      name
      loggedInUser {
        name
        surname
        role {
          name
        }
        position {
          name
        }
      }
    }
    company(id: $companyId) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetActiveCompaniesQuery__
 *
 * To run a query within a React component, call `useGetActiveCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCompaniesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetActiveCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables> & ({ variables: GetActiveCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables>(GetActiveCompaniesDocument, options);
      }
export function useGetActiveCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables>(GetActiveCompaniesDocument, options);
        }
export function useGetActiveCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables>(GetActiveCompaniesDocument, options);
        }
export type GetActiveCompaniesQueryHookResult = ReturnType<typeof useGetActiveCompaniesQuery>;
export type GetActiveCompaniesLazyQueryHookResult = ReturnType<typeof useGetActiveCompaniesLazyQuery>;
export type GetActiveCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetActiveCompaniesSuspenseQuery>;
export type GetActiveCompaniesQueryResult = Apollo.QueryResult<GetActiveCompaniesQuery, GetActiveCompaniesQueryVariables>;
export const GetCompanyAndRoleDocument = gql`
    query getCompanyAndRole($companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      name
      phone
      isPremiumOn
      bookingQrLink
      address
      deleted
      paidFeatures {
        id
        name
        dateOfEnd
        price
        dateOfLastPayment
        isYearly
      }
    }
    role {
      id
      name
    }
    employee {
      id
    }
  }
  role {
    companyAccesses {
      role {
        name
        id
      }
      action
      id
    }
    id
    name
  }
}
    `;

/**
 * __useGetCompanyAndRoleQuery__
 *
 * To run a query within a React component, call `useGetCompanyAndRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAndRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAndRoleQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyAndRoleQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables> & ({ variables: GetCompanyAndRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables>(GetCompanyAndRoleDocument, options);
      }
export function useGetCompanyAndRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables>(GetCompanyAndRoleDocument, options);
        }
export function useGetCompanyAndRoleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables>(GetCompanyAndRoleDocument, options);
        }
export type GetCompanyAndRoleQueryHookResult = ReturnType<typeof useGetCompanyAndRoleQuery>;
export type GetCompanyAndRoleLazyQueryHookResult = ReturnType<typeof useGetCompanyAndRoleLazyQuery>;
export type GetCompanyAndRoleSuspenseQueryHookResult = ReturnType<typeof useGetCompanyAndRoleSuspenseQuery>;
export type GetCompanyAndRoleQueryResult = Apollo.QueryResult<GetCompanyAndRoleQuery, GetCompanyAndRoleQueryVariables>;
export type GetRelationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRelationsQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', timezones?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string }> | null, countries?: Array<{ __typename?: 'CountryProxy', isoCode?: string | null, id: number, name: string, localeCode?: string | null, europeanUnion: boolean, timezone?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string } | null> | null }> | null, currencies?: Array<{ __typename?: 'CurrencyProxy', currency?: string | null, id: number, code?: string | null, countries?: Array<{ __typename?: 'CountryProxy', id: number, name: string, europeanUnion: boolean }> | null }> | null, languages?: Array<{ __typename?: 'LanguageProxy', id: number, name: string, isoCode: string }> | null } };

export type GetCityQueryVariables = Types.Exact<{
  countryId: Types.Scalars['Int']['input'];
}>;


export type GetCityQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', cities?: Array<{ __typename?: 'CityProxy', id: number, name: string }> | null } };

export type GetInfoCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetInfoCompanyQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, image?: string | null, website?: string | null, deleted: boolean, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string, type: string } | null, country?: { __typename?: 'CountryProxy', id: number, name: string, currencies?: Array<{ __typename?: 'CurrencyProxy', code?: string | null, currency?: string | null, id: number }> | null, timezone?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string } | null> | null, cities?: Array<{ __typename?: 'CityProxy', id: number, name: string }> | null } | null, city?: { __typename?: 'CityProxy', id: number, name: string, country?: { __typename?: 'CountryProxy', id: number, name: string } | null } | null, currency?: { __typename?: 'CurrencyProxy', id: number, code?: string | null, currency?: string | null } | null, timeZone?: { __typename?: 'TimeZoneProxy', id: number, name: string } | null, language?: { __typename?: 'LanguageProxy', name: string, id: number, isoCode: string } | null, languages?: Array<{ __typename?: 'LanguageProxy', id: number, isoCode: string, name: string }> | null, messageLanguage?: { __typename?: 'LanguageProxy', id: number, name: string, isoCode: string } | null, primaryLanguage?: { __typename?: 'LanguageProxy', id: number, name: string, isoCode: string } | null } | null } | null };

export type UpdateCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.CompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyProxy', id: number, name: string, image?: string | null, deleted: boolean, phone: string, address: string, bookingQrLink: string, country?: { __typename?: 'CountryProxy', name: string, europeanUnion: boolean, id: number, timezone?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string } | null> | null } | null, currency?: { __typename?: 'CurrencyProxy', id: number, currency?: string | null } | null } };

export type CreateCompanyBeautySalonMutationVariables = Types.Exact<{
  input: Types.CompanyInput;
}>;


export type CreateCompanyBeautySalonMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyProxy', name: string, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, country?: { __typename?: 'CountryProxy', id: number, name: string } | null } };

export type GetGmtQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGmtQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', timezones?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string }> | null } };

export type GetLanguagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', languages?: Array<{ __typename?: 'LanguageProxy', id: number, name: string, isoCode: string }> | null } };

export type GetCurrenciesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrenciesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', currencies?: Array<{ __typename?: 'CurrencyProxy', id: number, currency?: string | null, code?: string | null }> | null } };

export type GetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, country?: { __typename?: 'CountryProxy', id: number, name: string } | null, city?: { __typename?: 'CityProxy', id: number, name: string } | null, timeZone?: { __typename?: 'TimeZoneProxy', id: number, name: string } | null, languages?: Array<{ __typename?: 'LanguageProxy', id: number, name: string }> | null, currency?: { __typename?: 'CurrencyProxy', id: number, currency?: string | null } | null, messageLanguage?: { __typename?: 'LanguageProxy', id: number, name: string } | null } | null } | null };

export type GetBranchesIsDeletedQueryVariables = Types.Exact<{
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetBranchesIsDeletedQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, branches?: Array<{ __typename?: 'BranchProxy', id: number, deleted: boolean, address: string, bookingQrLink: string, onlineRegistration: boolean, currencyCode: string, name: string, removedOfTariff: boolean, timeZone: string, country: { __typename?: 'Country', id: string, name: string } }> | null } | null } | null };

export type GetRoleUserQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetRoleUserQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, email?: string | null, role?: { __typename?: 'RoleProxy', id: number, name: string } | null, currentBranch?: { __typename?: 'BranchProxy', id: number } | null, company?: { __typename?: 'CompanyProxy', id: number } | null } | null };

export type GetCompanyModulesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetCompanyModulesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null }> | null } | null } | null };

export type GetActiveCompaniesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetActiveCompaniesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', companies?: Array<{ __typename?: 'CompanyProxy', id: number, name: string, loggedInUser?: { __typename?: 'EmployeeProxy', name?: string | null, surname?: string | null, role?: { __typename?: 'RoleProxy', name: string } | null, position?: { __typename?: 'EmployeePositionProxy', name: string } | null } | null }> | null, company?: { __typename?: 'CompanyProxy', id: number, name: string } | null } | null };

export type GetCompanyAndRoleQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetCompanyAndRoleQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, name: string, phone: string, isPremiumOn: boolean, bookingQrLink: string, address: string, deleted: boolean, paidFeatures?: Array<{ __typename?: 'PaidFeatureProxy', id: number, name?: Types.Tariff | null, dateOfEnd?: string | null, price: number, dateOfLastPayment: string, isYearly: boolean }> | null } | null, role?: { __typename?: 'RoleProxy', id: number, name: string } | null, employee?: { __typename?: 'EmployeeProxy', id: number } | null } | null, role: Array<{ __typename?: 'RoleProxy', id: number, name: string, companyAccesses?: Array<{ __typename?: 'CompanyAccessProxy', action?: Types.CompanyAccess | null, id: number, role?: { __typename?: 'RoleProxy', name: string, id: number } | null }> | null }> };
