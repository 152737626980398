import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ApolloError} from '@apollo/client';
import ErrorService from '@me-team/common/src/services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useGetRolesQuery} from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import {RoleProxy} from '@me-team/host/main/graphql/types';
import ReactSelect from '../../ReactSelect/ReactSelect';
import Spinner from '../../Spinner/Spinner';
import {AddEditModal} from '../../index';
import {AccessRightsFormData} from '@me-team/common/src/components/EditingEmployee/types';
import {UserRole} from '@me-team/common/src/utils/enums';

export interface Option {
  value: number;
  label: string;
}

interface EditAccessRightsModalProps {
  showModal: boolean;
  onClose: () => void;
  branchAndRoleRelation?: AccessRightsFormData;
  action: 'add' | 'update';
  branchOptions?: Option[];
  onSubmit: (values: AccessRightsFormData) => void;
}

const AccessRightsModal: React.FC<EditAccessRightsModalProps> = ({
                                                                   showModal,
                                                                   onClose,
                                                                   branchAndRoleRelation,
                                                                   action,
                                                                   branchOptions,
                                                                   onSubmit
                                                                 }) => {
  const {t} = useTranslation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const isUpdateAction = action === 'update';
  const title: string = isUpdateAction ? t('Edit access rights') : t('Provide access to the branch')
  const buttonName: string = isUpdateAction ? t('Save') : t('Add')
  const initTransformedRole: Option = branchAndRoleRelation ? {
    value: branchAndRoleRelation?.role?.id,
    label: branchAndRoleRelation?.role?.name
  } : null;
  const [selectedRole, setSelectedRole] = useState<Option>(null);
  const [selectedBranch, setSelectedBranch] = useState<Option>(null);
  const [branchError, setBranchError] = useState<string | null>(null);
  const [roleError, setRoleError] = useState<string | null>(null);

  useEffect(() => {
    if (branchAndRoleRelation) {
      setSelectedRole({
        value: branchAndRoleRelation.role?.id,
        label: branchAndRoleRelation.role?.name
      });
      setSelectedBranch({
        value: branchAndRoleRelation.branch?.id,
        label: branchAndRoleRelation.branch?.name
      });
    }
  }, [branchAndRoleRelation]);

  useEffect(() => {
    selectedBranch && setBranchError(null);
  }, [selectedBranch]);

  useEffect(() => {
    selectedRole && setRoleError(null);
  }, [selectedRole]);

  const {data: rolesData, loading: roleLoading} = useGetRolesQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });
  const rolesOptions: Option[] = rolesData?.role?.filter((role: RoleProxy) => role.id !== UserRole.OWNER)
    .map((role: RoleProxy) => {
    return {
      value: role?.id,
      label: role?.name
    }
  }) || [];

  const handleSubmit = () => {
    const branchError = !selectedBranch ? t('Fill this field') : null;
    const roleError = !selectedRole ? t('Fill this field') : null;
    setBranchError(branchError);
    setRoleError(roleError);

    if (branchError || roleError) return;

    const valuesForm: AccessRightsFormData = {
      branch: {
        id: selectedBranch.value,
        name: selectedBranch.label,
      },
      role: {
        id: selectedRole.value,
        name: selectedRole.label,
      }
    }
    onSubmit(valuesForm)
    setSelectedRole(null)
    setSelectedBranch(null)
  }

  const handleCloseModal = () => {
    setSelectedRole(null)
    setSelectedBranch(null)
    onClose();
  };

  if (roleLoading) return <Spinner/>;

  return (
    <>
      <AddEditModal
        isModalOpened={showModal}
        setIsModalOpened={handleCloseModal}
        title={title}
        modalBodyClass='pb-0 px-0'
      >
        <Container className='pb-3 px-3'>
          <Row className="m-0 mb-3">
            <Col
              md={3}
              className='mb-2 mb-md-0 text-start d-flex align-items-center'>
              <label
                className='text-dark fs-7 fw-bold py-1'
                htmlFor='accessRightsModal-branch-field'
              >
                {t('Branch')}{!isUpdateAction && <span className='text-danger ms-1'>*</span>}
              </label>
            </Col>
            {
              isUpdateAction ?
                <Col
                  md={9}
                  className='d-flex align-items-center '
                >
                  <span className='fs-7 py-1'>{branchAndRoleRelation?.branch?.name}</span>
                </Col>
                :
                <Col md={9} className='text-start'>
                  <div>
                    <ReactSelect
                      id='accessRightsModal-branch-field'
                      name='branch'
                      placeholder={t('Select a branch')}
                      options={branchOptions}
                      value={branchOptions.find((option) => option.value === selectedBranch?.value)}
                      onChange={(selectedOption: Option) => setSelectedBranch(selectedOption)}
                      isSearchable={false}
                    />
                    {branchError && <div className="text-danger mt-1 fs-7">{branchError}</div>}
                  </div>
                </Col>
            }
          </Row>
          <Row className='m-0 h-100' style={{minHeight: '49px'}}>
            <Col
              md={3}
              className='mb-2 mb-md-0'>
              <label
                htmlFor='accessRightsModal-role-field'
                className='text-dark fs-7 fw-bold py-1 h-100 d-flex align-items-center'
              >
                {t('Access rights (role)')}&nbsp;<span className='text-danger'>*</span>
              </label>
            </Col>
            <Col md={9} className='text-start'>
              <ReactSelect
                id='accessRightsModal-role-field'
                name='role'
                placeholder={t('Select a role')}
                options={rolesOptions}
                value={rolesOptions.find((option) => option.value === selectedRole?.value) || initTransformedRole}
                onChange={(selectedOption: Option) => setSelectedRole(selectedOption)}
                isSearchable={false}
              />
              {roleError && <div className="text-danger mt-1 fs-7">{roleError}</div>}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className='p-6'>
            <Col lg={6}
                 className='offset-lg-6 d-flex justify-content-end gap-2 px-0 ps-lg-4'>
              <Col xs={6} lg={6}>
                <Button
                  onClick={handleCloseModal}
                  variant="outline-primary" type="button"
                  className="w-100 fw-normal"
                >
                  {t("Cancel")}
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  className="w-100"
                >
                  {buttonName}
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
      </AddEditModal>

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  )
};

export default AccessRightsModal;