import React from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {EmployeeProxy} from '@me-team/host/main/graphql/types';

interface TableRowProps {
  employee: EmployeeProxy;
  selectedEmployeeId?: number;
  onSelect: (employee: EmployeeProxy) => void;
}

const TableRow: React.FC<TableRowProps> = ({employee, selectedEmployeeId, onSelect}) => {
  const basePath = process.env.REACT_APP_POS_URL;
  const imageUrl = employee?.image ? `${basePath}/${employee.image}` : `${basePath}/build/images/person.jpg`;
  const isManyBranches = employee?.userBranchRelations?.length > 1

  return (
    <Row
      onClick={() => onSelect(employee)}
      className={` align-items-center ${selectedEmployeeId === employee.id ? "bg-light rounded-3" : ""} py-3 `}
      style={{cursor: "pointer"}}
    >
      <Col xs={6} className="d-flex align-items-center gap-2">
        <Image
          className="img-employ-list rounded-circle"
          src={imageUrl}
          width="35px"
          height="35px"
          alt={employee.name}
        />
        {employee.name} {employee.surname}
      </Col>
      <Col xs={6}>
        <div className='fs-7 text-start d-flex flex-nowrap align-items-center'>
          <div className='me-1'>
            {employee.userBranchRelations?.length > 0 ?
              employee.userBranchRelations.map((relation) => {
                return (
                  <span key={relation.id}>
                    {relation.branch.name}{isManyBranches && ';'}{' '}
                  </span>
                )
              }) : null}
          </div>
          <div style={{width: '20px', height: '20px'}}>
            {selectedEmployeeId === employee.id ?
              <i className="bi bi-check-lg text-primary fs-20"></i> : null}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TableRow;