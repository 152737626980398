export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Time: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Uuid: { input: any; output: any; }
};

export type BookingHistoryDataProxy = {
  __typename?: 'BookingHistoryDataProxy';
  bookingHistory?: Maybe<BookingHistoryProxy>;
  field?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type BookingHistoryProxy = {
  __typename?: 'BookingHistoryProxy';
  booking?: Maybe<BookingMainProxy>;
  bookingHistoryData?: Maybe<Array<BookingHistoryDataProxy>>;
  changeTime?: Maybe<Scalars['String']['output']>;
  client?: Maybe<ClientProxy>;
  exists: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  user?: Maybe<UserProxy>;
};


export type BookingHistoryProxyBookingHistoryDataArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BookingHistoryProxyChangeTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type BookingMainProxy = {
  __typename?: 'BookingMainProxy';
  bookingHistories?: Maybe<Array<BookingHistoryProxy>>;
  bookingSalary?: Maybe<BookingSalaryProxy>;
  branch?: Maybe<BranchProxy>;
  client?: Maybe<ClientProxy>;
  date?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  endTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  needClientRecall: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  notificationTime?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  review?: Maybe<ReviewProxy>;
  salaryPayments?: Maybe<SalaryPaymentPaginated>;
  service?: Maybe<ServiceProxy>;
  status?: Maybe<BookingStatusProxy>;
  technologicalMaps?: Maybe<Array<TechnologicalMapProxy>>;
  time?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};


export type BookingMainProxyBookingHistoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BookingMainProxyDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BookingMainProxyEndTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BookingMainProxySalaryPaymentsArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  periodEnd?: InputMaybe<Scalars['String']['input']>;
  periodStart?: InputMaybe<Scalars['String']['input']>;
};


export type BookingMainProxyTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type BookingModuleConfigProxy = {
  __typename?: 'BookingModuleConfigProxy';
  company?: Maybe<CompanyProxy>;
  confirmationCall: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  onlineBooking: Scalars['Boolean']['output'];
};

export type BookingPaginated = {
  __typename?: 'BookingPaginated';
  bookings?: Maybe<Array<BookingMainProxy>>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BookingProxy = {
  __typename?: 'BookingProxy';
  branch: BranchProxy;
  client: Client;
  date: Scalars['String']['output'];
  employee: EmployeeBookingProxy;
  endTime: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  notificationTime?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  service: ServiceBookingProxy;
  status: Scalars['String']['output'];
  time: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};


export type BookingProxyDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BookingProxyEndTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BookingProxyNotificationTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BookingProxyTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type BookingSalaryProxy = {
  __typename?: 'BookingSalaryProxy';
  booking?: Maybe<BookingProxy>;
  id: Scalars['Int']['output'];
  salaryType?: Maybe<Scalars['Int']['output']>;
  salaryValue?: Maybe<Scalars['Float']['output']>;
};

export type BookingStatusProxy = {
  __typename?: 'BookingStatusProxy';
  bookings?: Maybe<BookingPaginated>;
  colorCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


export type BookingStatusProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingTime = {
  __typename?: 'BookingTime';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};


export type BookingTimeEndArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BookingTimeStartArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type Branch = {
  __typename?: 'Branch';
  address: Scalars['String']['output'];
  city?: Maybe<City>;
  closestBookingDate?: Maybe<Scalars['String']['output']>;
  closestBookingDateDefaultEmployee?: Maybe<Scalars['String']['output']>;
  closestBookingTimes?: Maybe<Array<BookingTime>>;
  closestBookingTimesDefaultEmployee?: Maybe<Array<BookingTime>>;
  country: Country;
  currencyCode: Scalars['String']['output'];
  dateTimeFormat?: Maybe<DateTimeFormat>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Language>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  phoneCode?: Maybe<Scalars['String']['output']>;
  serviceImages: Array<Scalars['String']['output']>;
  website: Scalars['String']['output'];
  workSchedule?: Maybe<Scalars['String']['output']>;
};


export type BranchClosestBookingDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BranchClosestBookingDateDefaultEmployeeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type BranchAccessProxy = {
  __typename?: 'BranchAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type BranchConfigInput = {
  branch: Scalars['Int']['input'];
  jsConfig?: InputMaybe<Scalars['String']['input']>;
};

export type BranchInput = {
  address: Scalars['String']['input'];
  category: Scalars['String']['input'];
  city?: InputMaybe<Scalars['Int']['input']>;
  country: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
  isUpdateGallery?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateLogo?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  onlineBooking?: InputMaybe<Scalars['Boolean']['input']>;
  otherCity?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<BranchPhoneInput>>;
  socialLink?: InputMaybe<BranchSocialLinkInput>;
  type: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  workSchedule?: InputMaybe<Scalars['String']['input']>;
  workSchedules?: InputMaybe<Array<BranchWorkScheduleInput>>;
};

export type BranchMultipleConfigUpdateInput = {
  configs: Array<BranchConfigInput>;
};

export type BranchPaginated = {
  __typename?: 'BranchPaginated';
  branches?: Maybe<Array<BranchProxy>>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  isCanCreateNewBranch: Scalars['Boolean']['output'];
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BranchPhoneInput = {
  phone: Scalars['String']['input'];
};

export type BranchPhoneProxy = {
  __typename?: 'BranchPhoneProxy';
  branch: BranchProxy;
  id: Scalars['Int']['output'];
  phone: Scalars['String']['output'];
};

export type BranchProxy = {
  __typename?: 'BranchProxy';
  address: Scalars['String']['output'];
  bookingQrLink: Scalars['String']['output'];
  bookings?: Maybe<BookingPaginated>;
  bookingsList?: Maybe<Array<BookingMainProxy>>;
  branchImages?: Maybe<Array<Scalars['String']['output']>>;
  category?: Maybe<CompanyTypeProxy>;
  city?: Maybe<CityProxy>;
  closestBookingDate?: Maybe<Scalars['String']['output']>;
  closestBookingDateDefaultEmployee?: Maybe<Scalars['String']['output']>;
  closestBookingTimes?: Maybe<Array<BookingTime>>;
  closestBookingTimesDefaultEmployee?: Maybe<Array<BookingTime>>;
  company?: Maybe<CompanyProxy>;
  country: Country;
  countryProxy?: Maybe<CountryProxy>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  customDays?: Maybe<Array<CustomDayProxy>>;
  dateTimeFormat?: Maybe<DateTimeFormatProxy>;
  deleted: Scalars['Boolean']['output'];
  deliveryWorkSchedules?: Maybe<Array<BranchWorkScheduleProxy>>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employeeWorkScheduleSetting?: Maybe<EmployeeWorkScheduleSetting>;
  employees?: Maybe<Array<EmployeeProxy>>;
  employeesPaginated?: Maybe<EmployeePaginated>;
  generalWorkSchedules?: Maybe<Array<BranchWorkScheduleProxy>>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isAnyActiveServiceEmployeeRelations: Scalars['Boolean']['output'];
  isAnyActiveServices: Scalars['Boolean']['output'];
  isAnyWorkScheduleForWorkingEmployees: Scalars['Boolean']['output'];
  kitchenWorkSchedules?: Maybe<Array<BranchWorkScheduleProxy>>;
  language?: Maybe<LanguageProxy>;
  logbookSetting?: Maybe<LogbookSetting>;
  meBookingConfig?: Maybe<MeBookingBranchConfigProxy>;
  menus?: Maybe<MenuPaginated>;
  name: Scalars['String']['output'];
  onlineRegistration: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneCode?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<BranchPhoneProxy>>;
  rating?: Maybe<Scalars['Int']['output']>;
  removedOfTariff: Scalars['Boolean']['output'];
  serviceImages?: Maybe<Array<Scalars['String']['output']>>;
  socialLink?: Maybe<BranchSocialLinkProxy>;
  storages?: Maybe<Array<StorageProxy>>;
  suppliers?: Maybe<Array<SupplierProxy>>;
  timeZone: Scalars['String']['output'];
  type?: Maybe<BranchTypeProxy>;
  userBranchRelations?: Maybe<Array<UserBranchRelationProxy>>;
  website?: Maybe<Scalars['String']['output']>;
  workSchedule?: Maybe<Scalars['String']['output']>;
  workSchedules?: Maybe<Array<WorkScheduleProxy>>;
};


export type BranchProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['Uuid']['input']>;
};


export type BranchProxyBookingsListArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['Uuid']['input']>;
};


export type BranchProxyClosestBookingDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BranchProxyClosestBookingDateDefaultEmployeeArgs = {
  format: Scalars['String']['input'];
};


export type BranchProxyCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type BranchProxyCustomDaysArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyDeliveryWorkSchedulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyEmployeesArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type BranchProxyEmployeesPaginatedArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filterByNameAndPosition?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  positionId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyGeneralWorkSchedulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyKitchenWorkSchedulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyMenusArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  menuTypeId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type BranchProxyPhonesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyStoragesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxySuppliersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyUserBranchRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchProxyWorkSchedulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type BranchSocialLinkInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type BranchSocialLinkProxy = {
  __typename?: 'BranchSocialLinkProxy';
  branch: BranchProxy;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type BranchTypeProxy = {
  __typename?: 'BranchTypeProxy';
  branches?: Maybe<Array<BranchProxy>>;
  childTypes?: Maybe<Array<Maybe<BranchTypeProxy>>>;
  company?: Maybe<CompanyProxy>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentType?: Maybe<BranchTypeProxy>;
  slug: Scalars['String']['output'];
};


export type BranchTypeProxyBranchesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchTypeProxyChildTypesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BranchWorkPeriodTypeProxy = {
  __typename?: 'BranchWorkPeriodTypeProxy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  workSchedules?: Maybe<Array<BranchWorkScheduleProxy>>;
};


export type BranchWorkPeriodTypeProxyWorkSchedulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type BranchWorkScheduleInput = {
  day: Scalars['Int']['input'];
  endTime: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type BranchWorkScheduleProxy = {
  __typename?: 'BranchWorkScheduleProxy';
  branch: BranchProxy;
  day: DayProxy;
  id: Scalars['Int']['output'];
  period: Scalars['String']['output'];
  type: BranchWorkPeriodTypeProxy;
};

export type City = {
  __typename?: 'City';
  country: Country;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CityProxy = {
  __typename?: 'CityProxy';
  country?: Maybe<CountryProxy>;
  custom?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Client = {
  __typename?: 'Client';
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCode?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type ClientAccessProxy = {
  __typename?: 'ClientAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type ClientInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['Int']['input'];
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  isUpdateImage?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
};

export type ClientPaginated = {
  __typename?: 'ClientPaginated';
  clients?: Maybe<Array<ClientProxy>>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ClientProxy = {
  __typename?: 'ClientProxy';
  bookingHistories?: Maybe<Array<BookingHistoryProxy>>;
  bookings?: Maybe<BookingPaginated>;
  category?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyProxy>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderProxy>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  loyaltyCard?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};


export type ClientProxyBookingHistoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ClientProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type ClientProxyDateOfBirthArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type ClientQueryInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']['output']>;
  category?: Maybe<CompanyType>;
  city: City;
  confirmationCallRequired: Scalars['Boolean']['output'];
  country: Country;
  dateTimeFormat?: Maybe<DateTimeFormat>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language: Language;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  phoneCode?: Maybe<Scalars['String']['output']>;
  showAds: Scalars['Boolean']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export enum CompanyAccess {
  AdminViewBranch = 'ADMIN_VIEW_BRANCH',
  CheckAllSchedule = 'CHECK_ALL_SCHEDULE',
  CreateBranch = 'CREATE_BRANCH',
  CreateClient = 'CREATE_CLIENT',
  CreateEmployee = 'CREATE_EMPLOYEE',
  CreatePosition = 'CREATE_POSITION',
  CreateProduct = 'CREATE_PRODUCT',
  CreateProductCategory = 'CREATE_PRODUCT_CATEGORY',
  CreateService = 'CREATE_SERVICE',
  CreateServiceCategory = 'CREATE_SERVICE_CATEGORY',
  CreateStorage = 'CREATE_STORAGE',
  CreateTechnologicalMap = 'CREATE_TECHNOLOGICAL_MAP',
  CreateTechnologicalMapCategory = 'CREATE_TECHNOLOGICAL_MAP_CATEGORY',
  EditCompany = 'EDIT_COMPANY',
  EmployeeViewBranch = 'EMPLOYEE_VIEW_BRANCH',
  EmployeeViewClient = 'EMPLOYEE_VIEW_CLIENT',
  EmployeeViewPosition = 'EMPLOYEE_VIEW_POSITION',
  EmployeeViewProduct = 'EMPLOYEE_VIEW_PRODUCT',
  EmployeeViewProductCategory = 'EMPLOYEE_VIEW_PRODUCT_CATEGORY',
  EmployeeViewSelf = 'EMPLOYEE_VIEW_SELF',
  EmployeeViewService = 'EMPLOYEE_VIEW_SERVICE',
  EmployeeViewServiceCategory = 'EMPLOYEE_VIEW_SERVICE_CATEGORY',
  EmployeeViewStorage = 'EMPLOYEE_VIEW_STORAGE',
  EmployeeViewTechnologicalMap = 'EMPLOYEE_VIEW_TECHNOLOGICAL_MAP',
  EmployeeViewTechnologicalMapCategory = 'EMPLOYEE_VIEW_TECHNOLOGICAL_MAP_CATEGORY',
  SelfCheckSchedule = 'SELF_CHECK_SCHEDULE',
  ViewCompany = 'VIEW_COMPANY'
}

export type CompanyAccessProxy = {
  __typename?: 'CompanyAccessProxy';
  action?: Maybe<CompanyAccess>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type CompanyInput = {
  address: Scalars['String']['input'];
  category: Scalars['String']['input'];
  city?: InputMaybe<Scalars['Int']['input']>;
  country: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  deleted: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
  isUpdateLogo?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['Int']['input']>>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  messageLanguage: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  needsClientRecall?: InputMaybe<Scalars['Boolean']['input']>;
  onlineBooking?: InputMaybe<Scalars['Boolean']['input']>;
  otherCity?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  primaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  timezone: Scalars['Int']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  workSchedule?: InputMaybe<Scalars['String']['input']>;
  workSchedules?: InputMaybe<Array<BranchWorkScheduleInput>>;
};

export type CompanyProxy = {
  __typename?: 'CompanyProxy';
  address: Scalars['String']['output'];
  bookingQrLink: Scalars['String']['output'];
  bookings?: Maybe<BookingPaginated>;
  branchTypes?: Maybe<Array<BranchTypeProxy>>;
  branches?: Maybe<Array<BranchProxy>>;
  branchesPaginated?: Maybe<BranchPaginated>;
  category?: Maybe<CompanyTypeProxy>;
  city?: Maybe<CityProxy>;
  clients?: Maybe<ClientPaginated>;
  country?: Maybe<CountryProxy>;
  currency?: Maybe<CurrencyProxy>;
  dateTimeFormat?: Maybe<DateTimeFormatProxy>;
  deleted: Scalars['Boolean']['output'];
  employeePositions?: Maybe<EmployeePositionPaginated>;
  employees?: Maybe<EmployeePaginated>;
  employeesList?: Maybe<Array<EmployeeProxy>>;
  employeesNotInBranch?: Maybe<Array<EmployeeProxy>>;
  expiredPaidFeatures?: Maybe<Array<PaidFeatureProxy>>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isPremiumOn: Scalars['Boolean']['output'];
  language?: Maybe<LanguageProxy>;
  languages?: Maybe<Array<LanguageProxy>>;
  loggedInUser?: Maybe<EmployeeProxy>;
  meBookingModuleConfig?: Maybe<BookingModuleConfigProxy>;
  meBookingSyncStatus?: Maybe<Scalars['Int']['output']>;
  menuCategories?: Maybe<MenuCategoryPaginated>;
  menuItems?: Maybe<MenuItemPaginated>;
  menuSupplements?: Maybe<MenuSupplementPaginated>;
  messageLanguage?: Maybe<LanguageProxy>;
  modules?: Maybe<Array<ModuleProxy>>;
  name: Scalars['String']['output'];
  ownerPosition?: Maybe<EmployeePositionProxy>;
  paidFeatures?: Maybe<Array<PaidFeatureProxy>>;
  paidFeaturesPaginated: PaidFeaturePaginated;
  paymentOrders?: Maybe<Array<PaymentOrderProxy>>;
  phone: Scalars['String']['output'];
  primaryLanguage?: Maybe<LanguageProxy>;
  productCategories?: Maybe<Array<ProductCategoryProxy>>;
  products?: Maybe<Array<ProductProxy>>;
  reviews?: Maybe<ReviewPaginated>;
  salaryPayments?: Maybe<SalaryPaymentPaginated>;
  serviceCategories?: Maybe<ServiceCategoryPaginated>;
  serviceCategoriesNew?: Maybe<Array<ServiceCategoryNewProxy>>;
  services?: Maybe<ServicePaginated>;
  servicesList?: Maybe<Array<ServiceProxy>>;
  technologicalMapCategories?: Maybe<Array<TechnologicalMapCategoryProxy>>;
  technologicalMaps?: Maybe<Array<TechnologicalMapProxy>>;
  timeZone?: Maybe<TimeZoneProxy>;
  userCompanyRelations?: Maybe<Array<UserCompanyRelationProxy>>;
  website?: Maybe<Scalars['String']['output']>;
};


export type CompanyProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  salaryValue?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyBranchTypesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyBranchesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyBranchesPaginatedArgs = {
  branchTypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyClientsArgs = {
  amountOfVisits?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyEmployeePositionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyEmployeesArgs = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filterByNameAndPosition?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  positionId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyEmployeesListArgs = {
  positionId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyEmployeesNotInBranchArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  excludeBranchId: Scalars['Int']['input'];
};


export type CompanyProxyLanguagesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyMenuCategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyMenuItemsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  menuCategoryId?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyMenuSupplementsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  menuCategoryId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyModulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyPaidFeaturesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyPaidFeaturesPaginatedArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyPaymentOrdersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyProductCategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyProductsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyReviewsArgs = {
  employeeEmail?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reviewSortField?: InputMaybe<Scalars['String']['input']>;
  reviewType?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxySalaryPaymentsArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  periodEnd?: InputMaybe<Scalars['String']['input']>;
  periodStart?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyServiceCategoriesArgs = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  subcategoryName?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyServiceCategoriesNewArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyProxyServicesArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onlyFromBookings?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  withoutArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyProxyServicesListArgs = {
  accessibility?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onlineBooking?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyProxyTechnologicalMapCategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyTechnologicalMapsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyProxyUserCompanyRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyType = {
  __typename?: 'CompanyType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CompanyTypeProxy = {
  __typename?: 'CompanyTypeProxy';
  branches?: Maybe<Array<BranchProxy>>;
  companies?: Maybe<Array<CompanyProxy>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


export type CompanyTypeProxyBranchesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeProxyCompaniesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Country = {
  __typename?: 'Country';
  continentName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isInEuropeanUnion: Scalars['Boolean']['output'];
  isoCode: Scalars['String']['output'];
  localeCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CountryGeoProxy = {
  __typename?: 'CountryGeoProxy';
  city?: Maybe<Array<Maybe<CityProxy>>>;
  country?: Maybe<CountryProxy>;
  timezone?: Maybe<Array<Maybe<TimeZoneProxy>>>;
};

export type CountryProxy = {
  __typename?: 'CountryProxy';
  alpha3IsoCode?: Maybe<Scalars['String']['output']>;
  cities?: Maybe<Array<CityProxy>>;
  continentCode?: Maybe<Scalars['String']['output']>;
  continentName?: Maybe<Scalars['String']['output']>;
  currencies?: Maybe<Array<CurrencyProxy>>;
  europeanUnion: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isoCode?: Maybe<Scalars['String']['output']>;
  localeCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneCode?: Maybe<PhoneCodeProxy>;
  timezone?: Maybe<Array<Maybe<TimeZoneProxy>>>;
};


export type CountryProxyCitiesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CountryProxyCurrenciesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum Currency {
  Eur = 'EUR',
  Uah = 'UAH'
}

export type CurrencyProxy = {
  __typename?: 'CurrencyProxy';
  code?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<Array<CountryProxy>>;
  currency?: Maybe<Scalars['String']['output']>;
  currencySign?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};


export type CurrencyProxyCountriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomDayInput = {
  customDayPeriods: Array<CustomDayPeriodInput>;
  date: Scalars['Date']['input'];
};

export type CustomDayPeriodInput = {
  endTime: Scalars['Time']['input'];
  startTime: Scalars['Time']['input'];
};

export type CustomDayPeriodProxy = {
  __typename?: 'CustomDayPeriodProxy';
  customDay?: Maybe<CustomDayProxy>;
  endTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
};


export type CustomDayPeriodProxyEndTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type CustomDayPeriodProxyStartTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type CustomDayProxy = {
  __typename?: 'CustomDayProxy';
  branch?: Maybe<BranchProxy>;
  customDayPeriods?: Maybe<Array<CustomDayPeriodProxy>>;
  date?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
};


export type CustomDayProxyCustomDayPeriodsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomDayProxyDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeFormat = {
  __typename?: 'DateTimeFormat';
  format: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DateTimeFormatProxy = {
  __typename?: 'DateTimeFormatProxy';
  branches?: Maybe<Array<BranchProxy>>;
  companies?: Maybe<Array<CompanyProxy>>;
  format: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};


export type DateTimeFormatProxyBranchesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type DateTimeFormatProxyCompaniesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type DayProxy = {
  __typename?: 'DayProxy';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  shortName?: Maybe<Scalars['String']['output']>;
};

export enum DefaultWeek {
  CurrentWeek = 'CURRENT_WEEK',
  LastViewedWeek = 'LAST_VIEWED_WEEK'
}

export type DismissEmployeeInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  endOfCooperation: Scalars['String']['input'];
  reason: Scalars['Int']['input'];
};

export type DismissalEmployeeProxy = {
  __typename?: 'DismissalEmployeeProxy';
  comment?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  employeeDismissalReason?: Maybe<EmployeeDismissalReasonProxy>;
  endDateCooperation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};


export type DismissalEmployeeProxyEndDateCooperationArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeAccessProxy = {
  __typename?: 'EmployeeAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type EmployeeBookingProxy = {
  __typename?: 'EmployeeBookingProxy';
  aboutMe?: Maybe<Scalars['String']['output']>;
  allowDefault: Scalars['Boolean']['output'];
  avgRating?: Maybe<Scalars['Float']['output']>;
  closestBookingDate?: Maybe<Scalars['String']['output']>;
  closestBookingDateTimes?: Maybe<Array<BookingTime>>;
  employeeServiceRelation?: Maybe<EmployeeServiceRelationProxy>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lastReviews?: Maybe<Array<ReviewProxy>>;
  name: Scalars['String']['output'];
  patronymic?: Maybe<Scalars['String']['output']>;
  position?: Maybe<EmployeePositionProxy>;
  qualificationConfirmations?: Maybe<Array<QualificationConfirmationProxy>>;
  reviewCount: Scalars['Int']['output'];
  specialization?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmployeeStatusProxy>;
  surname: Scalars['String']['output'];
};


export type EmployeeBookingProxyClosestBookingDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeBookingProxyQualificationConfirmationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeDismissalReasonProxy = {
  __typename?: 'EmployeeDismissalReasonProxy';
  dismissalEmployees?: Maybe<Array<DismissalEmployeeProxy>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


export type EmployeeDismissalReasonProxyDismissalEmployeesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['Int']['input'];
  contract?: InputMaybe<Scalars['Upload']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  employeePosition: Scalars['Int']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  isUpdateAccess?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateConfirmations?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateContract?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateImage?: InputMaybe<Scalars['Boolean']['input']>;
  itn?: InputMaybe<Scalars['String']['input']>;
  manualBlocking: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  passportData?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  qualificationConfirmations?: InputMaybe<Array<Scalars['Upload']['input']>>;
  role: Scalars['Int']['input'];
  specialization: Scalars['String']['input'];
  status: Scalars['Int']['input'];
  surname: Scalars['String']['input'];
  tariffBlocking: Scalars['Boolean']['input'];
};

export type EmployeePaginated = {
  __typename?: 'EmployeePaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  employees?: Maybe<Array<EmployeeProxy>>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type EmployeePositionPaginated = {
  __typename?: 'EmployeePositionPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  employeePositions?: Maybe<Array<EmployeePositionProxy>>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type EmployeePositionProxy = {
  __typename?: 'EmployeePositionProxy';
  company?: Maybe<CompanyProxy>;
  description?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Array<EmployeeProxy>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


export type EmployeePositionProxyEmployeesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeProxy = {
  __typename?: 'EmployeeProxy';
  ServiceCategorySalaries?: Maybe<Array<ServiceCategorySalaryProxy>>;
  aboutMe?: Maybe<Scalars['String']['output']>;
  bookings?: Maybe<BookingPaginated>;
  company?: Maybe<CompanyProxy>;
  contract?: Maybe<Scalars['String']['output']>;
  customDays?: Maybe<Array<CustomDayProxy>>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dismissalEmployee?: Maybe<DismissalEmployeeProxy>;
  email: Scalars['String']['output'];
  employeeServiceRelations?: Maybe<Array<EmployeeServiceRelationProxy>>;
  employeeServices?: Maybe<EmployeeServicesPaginated>;
  employeeServicesList?: Maybe<Array<ServiceProxy>>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  itn?: Maybe<Scalars['String']['output']>;
  manualBlocking: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  originalContractName?: Maybe<Scalars['String']['output']>;
  passportData?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  position?: Maybe<EmployeePositionProxy>;
  qualificationConfirmations?: Maybe<Array<QualificationConfirmationProxy>>;
  recordSetting?: Maybe<RecordSettingProxy>;
  reviews?: Maybe<Array<ReviewProxy>>;
  role?: Maybe<RoleProxy>;
  salaryPayments?: Maybe<SalaryPaymentPaginated>;
  specialization?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmployeeStatusProxy>;
  surname?: Maybe<Scalars['String']['output']>;
  tariffBlocking: Scalars['Boolean']['output'];
  user?: Maybe<UserProxy>;
  userBranchRelations?: Maybe<Array<UserBranchRelationProxy>>;
  vacations?: Maybe<Array<VacationProxy>>;
  workSchedules?: Maybe<Array<WorkScheduleProxy>>;
};


export type EmployeeProxyBookingsArgs = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  salaryValue?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxyCustomDaysArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxyDateOfBirthArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeProxyEmployeeServiceRelationsArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxyEmployeeServicesArgs = {
  accessibility?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  filterByEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  onlineBooking?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  serviceName?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeProxyEmployeeServicesListArgs = {
  accessibility?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  filterByEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  onlineBooking?: InputMaybe<Scalars['Boolean']['input']>;
  serviceName?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeProxyQualificationConfirmationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxyReviewsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxySalaryPaymentsArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  periodEnd?: InputMaybe<Scalars['String']['input']>;
  periodStart?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeProxyUserBranchRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxyVacationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeProxyWorkSchedulesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeRecordSettingsInput = {
  isDefaultEmployee: Scalars['Boolean']['input'];
  isOnlineSignUpAvailable: Scalars['Boolean']['input'];
  isReviewsAvailable: Scalars['Boolean']['input'];
};

export type EmployeeServiceRelationInput = {
  duration: Scalars['String']['input'];
  onlineBooking: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  salaryType?: InputMaybe<SalaryType>;
  salaryValue?: InputMaybe<Scalars['Float']['input']>;
};

export type EmployeeServiceRelationInputAll = {
  employee?: InputMaybe<Scalars['Int']['input']>;
  service?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeServiceRelationProxy = {
  __typename?: 'EmployeeServiceRelationProxy';
  deleted: Scalars['Boolean']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  online: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  salaryType?: Maybe<Scalars['Int']['output']>;
  salaryValue?: Maybe<Scalars['Float']['output']>;
  service?: Maybe<ServiceProxy>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type EmployeeServiceRelationProxyDurationArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeServiceRelationProxyUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeServicesPaginated = {
  __typename?: 'EmployeeServicesPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  employeeServices?: Maybe<Array<ServiceProxy>>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type EmployeeSpecializationProxy = {
  __typename?: 'EmployeeSpecializationProxy';
  name: Scalars['String']['output'];
};

export type EmployeeStatusProxy = {
  __typename?: 'EmployeeStatusProxy';
  constId?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Array<EmployeeProxy>>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


export type EmployeeStatusProxyEmployeesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeWorkScheduleSetting = {
  __typename?: 'EmployeeWorkScheduleSetting';
  branch: Branch;
  defaultWeek?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EmployeeWorkScheduleSettingInput = {
  branchId: Scalars['Int']['input'];
  defaultWeek?: InputMaybe<DefaultWeek>;
};

export type ExportJobProxy = {
  __typename?: 'ExportJobProxy';
  company: CompanyProxy;
  createdAt?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  jobType: JobType;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};


export type ExportJobProxyCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type GenderProxy = {
  __typename?: 'GenderProxy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type GeneratePaymentUrlInput = {
  currency: Currency;
  plan: Scalars['Int']['input'];
};

export type ImportJobProxy = {
  __typename?: 'ImportJobProxy';
  company: CompanyProxy;
  createdAt?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  jobType: JobType;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  validationFilename?: Maybe<Scalars['String']['output']>;
};


export type ImportJobProxyCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type JobContainerProxy = {
  __typename?: 'JobContainerProxy';
  exportJobs?: Maybe<Array<ExportJobProxy>>;
  importJobs?: Maybe<Array<ImportJobProxy>>;
};


export type JobContainerProxyExportJobsArgs = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type JobContainerProxyImportJobsArgs = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  validationFilename?: InputMaybe<Scalars['String']['input']>;
};

export enum JobEntity {
  /** All entity jobs */
  All = 'ALL',
  /** Client entity jobs */
  Client = 'CLIENT',
  /** Employee entity jobs */
  Employee = 'EMPLOYEE',
  /** Service entity jobs */
  Service = 'SERVICE',
  /** Service-category entity jobs */
  ServiceCategory = 'SERVICE_CATEGORY'
}

export enum JobStatus {
  Fail = 'FAIL',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  ValidationFail = 'VALIDATION_FAIL'
}

export enum JobType {
  Export = 'EXPORT',
  Import = 'IMPORT'
}

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LanguageProxy = {
  __typename?: 'LanguageProxy';
  id: Scalars['Int']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum LogbookDefaultDay {
  CurrentDay = 'CURRENT_DAY',
  LastViewedDay = 'LAST_VIEWED_DAY'
}

export enum LogbookDefaultView {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export enum LogbookPastCreateStatus {
  Completed = 'COMPLETED',
  Manually = 'MANUALLY'
}

export type LogbookSetting = {
  __typename?: 'LogbookSetting';
  branch: Branch;
  defaultDay?: Maybe<Scalars['String']['output']>;
  defaultView?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isQuickBooking?: Maybe<Scalars['Boolean']['output']>;
  pastCreateStatus?: Maybe<Scalars['String']['output']>;
  useClientData?: Maybe<Scalars['Boolean']['output']>;
};

export type LogbookSettingInput = {
  branchId: Scalars['Int']['input'];
  defaultDay?: InputMaybe<LogbookDefaultDay>;
  defaultView?: InputMaybe<LogbookDefaultView>;
  isQuickBooking?: InputMaybe<Scalars['Boolean']['input']>;
  pastCreateStatus?: InputMaybe<LogbookPastCreateStatus>;
  useClientData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Me = {
  __typename?: 'Me';
  username: Scalars['String']['output'];
};

export type MeBookingBranchConfigProxy = {
  __typename?: 'MeBookingBranchConfigProxy';
  branch?: Maybe<BranchProxy>;
  jsConfig?: Maybe<Scalars['String']['output']>;
};

export type MenuAllergenProxy = {
  __typename?: 'MenuAllergenProxy';
  id: Scalars['Int']['output'];
  items?: Maybe<Array<MenuItemProxy>>;
  label: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type MenuAllergenProxyItemsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  favicon?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuCategoryPaginated = {
  __typename?: 'MenuCategoryPaginated';
  categories?: Maybe<Array<MenuCategoryProxy>>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MenuCategoryProxy = {
  __typename?: 'MenuCategoryProxy';
  company: CompanyProxy;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  favicon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numberOfItems: Scalars['Int']['output'];
  parent?: Maybe<MenuCategoryProxy>;
  subcategories?: Maybe<Array<MenuCategoryProxy>>;
};


export type MenuCategoryProxySubcategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuCategoryRelationInput = {
  category: Scalars['Int']['input'];
  items?: InputMaybe<Array<Scalars['Int']['input']>>;
  subcategoryRelations?: InputMaybe<Array<MenuCategoryRelationInput>>;
};

export type MenuCategoryRelationProxy = {
  __typename?: 'MenuCategoryRelationProxy';
  category: MenuCategoryProxy;
  id: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
  menu: MenuProxy;
  subcategoryRelations?: Maybe<Array<MenuCategoryRelationProxy>>;
};

export type MenuInput = {
  languages: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  status: Scalars['Int']['input'];
  type: Scalars['Int']['input'];
};

export type MenuItemInput = {
  allergens?: InputMaybe<Array<Scalars['Int']['input']>>;
  categories: Array<Scalars['Int']['input']>;
  composition?: InputMaybe<Scalars['String']['input']>;
  cookingTime?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  isLongCookingTime?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateImage?: InputMaybe<Scalars['Boolean']['input']>;
  markers?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  portions: Array<MenuItemPortionInput>;
  supplements?: InputMaybe<Array<Scalars['Int']['input']>>;
  types: Array<Scalars['Int']['input']>;
  unit: Scalars['Int']['input'];
};

export type MenuItemPaginated = {
  __typename?: 'MenuItemPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<MenuItemProxy>>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MenuItemPortionInput = {
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  volume: Scalars['Int']['input'];
};

export type MenuItemPortionProxy = {
  __typename?: 'MenuItemPortionProxy';
  id: Scalars['Int']['output'];
  item: MenuItemProxy;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  volume: Scalars['Int']['output'];
};

export type MenuItemProxy = {
  __typename?: 'MenuItemProxy';
  allergens?: Maybe<Array<MenuAllergenProxy>>;
  categories?: Maybe<Array<MenuCategoryProxy>>;
  company: CompanyProxy;
  composition?: Maybe<Scalars['String']['output']>;
  cookingTime?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  favicon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isLongCookingTime: Scalars['Boolean']['output'];
  markers?: Maybe<Array<MenuMarkerProxy>>;
  name: Scalars['String']['output'];
  portions?: Maybe<Array<MenuItemPortionProxy>>;
  supplements?: Maybe<Array<MenuSupplementProxy>>;
  types?: Maybe<Array<MenuTypeProxy>>;
  unit: UnitProxy;
};


export type MenuItemProxyAllergensArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuItemProxyCategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuItemProxyMarkersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuItemProxyPortionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuItemProxySupplementsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuItemProxyTypesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuItemRelationProxy = {
  __typename?: 'MenuItemRelationProxy';
  categoryRelation: MenuCategoryRelationProxy;
  id: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
  item: MenuItemProxy;
};

export type MenuMarkerProxy = {
  __typename?: 'MenuMarkerProxy';
  favicon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  items?: Maybe<Array<MenuItemProxy>>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type MenuMarkerProxyItemsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuPaginated = {
  __typename?: 'MenuPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  menus?: Maybe<Array<MenuProxy>>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MenuProxy = {
  __typename?: 'MenuProxy';
  branch: BranchProxy;
  categoryRelations?: Maybe<Array<MenuCategoryRelationProxy>>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  itemRelations?: Maybe<Array<MenuItemRelationProxy>>;
  languages?: Maybe<Array<LanguageProxy>>;
  name: Scalars['String']['output'];
  numberOfCategories: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  status: MenuStatusProxy;
  type: MenuTypeProxy;
};


export type MenuProxyItemRelationsArgs = {
  categoryRelationId: Scalars['Int']['input'];
};


export type MenuProxyLanguagesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuRelationsInput = {
  categoryRelations: Array<MenuCategoryRelationInput>;
};

export type MenuStatusProxy = {
  __typename?: 'MenuStatusProxy';
  id: Scalars['Int']['output'];
  menus?: Maybe<Array<MenuProxy>>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type MenuStatusProxyMenusArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuSupplementInput = {
  groupName: Scalars['String']['input'];
  isRequireOnOrder: Scalars['Boolean']['input'];
  items?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxCountOnOrder: Scalars['Int']['input'];
  options: Array<MenuSupplementOptionInput>;
};

export type MenuSupplementOptionInput = {
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type MenuSupplementPaginated = {
  __typename?: 'MenuSupplementPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  supplements?: Maybe<Array<MenuSupplementProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MenuSupplementProxy = {
  __typename?: 'MenuSupplementProxy';
  company: CompanyProxy;
  deleted: Scalars['Boolean']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isRequireOnOrder?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<MenuItemProxy>>;
  maxCountOnOrder?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfOption?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<MenuSupplementProxy>>;
  parent?: Maybe<MenuSupplementProxy>;
  price?: Maybe<Scalars['Float']['output']>;
};


export type MenuSupplementProxyItemsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuSupplementProxyOptionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuTypeProxy = {
  __typename?: 'MenuTypeProxy';
  id: Scalars['Int']['output'];
  items?: Maybe<Array<MenuItemProxy>>;
  menus?: Maybe<Array<MenuProxy>>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type MenuTypeProxyItemsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MenuTypeProxyMenusArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInputType = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModuleProxy = {
  __typename?: 'ModuleProxy';
  companies?: Maybe<Array<CompanyProxy>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type ModuleProxyCompaniesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkMenuRelations: MenuProxy;
  cloneMenu: MenuProxy;
  cloneMenuItem: MenuItemProxy;
  createAllRelations?: Maybe<Array<EmployeeServiceRelationProxy>>;
  createBranch: BranchProxy;
  createClient: ClientProxy;
  createCompany: CompanyProxy;
  createCustomDay: CustomDayProxy;
  createEmployee: EmployeeProxy;
  createEmployeePosition: EmployeePositionProxy;
  createEmployeeServiceRelation: EmployeeServiceRelationProxy;
  createMenu: MenuProxy;
  createMenuCategory: MenuCategoryProxy;
  createMenuItem: MenuItemProxy;
  createMenuSupplement: MenuSupplementProxy;
  createReview: ReviewProxy;
  createRole: RoleProxy;
  createSalaryPayment: SalaryPaymentProxy;
  createService: ServiceProxy;
  /** @deprecated This mutation is deprecated, please use createServiceCategoryNew. */
  createServiceCategory: ServiceCategoryProxy;
  createServiceCategoryNew: ServiceCategoryNewProxy;
  createServiceCategorySalary: ServiceCategorySalaryProxy;
  /** @deprecated This mutation is deprecated, please use createServiceCategoryNew. */
  createServiceSubcategory: ServiceSubcategoryProxy;
  createUserBranchRelation: Array<UserBranchRelationProxy>;
  createVacation: VacationProxy;
  createWorkSchedule: WorkScheduleProxy;
  deleteAllRelations: Scalars['Boolean']['output'];
  deleteBranch: Scalars['Boolean']['output'];
  deleteClient: Scalars['Boolean']['output'];
  deleteDismissEmployee: Scalars['Boolean']['output'];
  deleteEmployeePosition: Scalars['Boolean']['output'];
  deleteEmployeeServiceRelation: Scalars['Boolean']['output'];
  deleteMenu: Scalars['Boolean']['output'];
  deleteMenuCategory: Scalars['Boolean']['output'];
  deleteMenuItem: Scalars['Boolean']['output'];
  deleteMenuSupplement: Scalars['Boolean']['output'];
  deleteReview: Scalars['Boolean']['output'];
  deleteSalaryPayment: Scalars['Boolean']['output'];
  /** @deprecated This mutation is deprecated, please use updateServiceCategoryNew. */
  deleteServiceCategory: Scalars['Boolean']['output'];
  /** @deprecated This mutation is deprecated, please use updateServiceCategoryNew. */
  deleteServiceSubcategory: Scalars['Boolean']['output'];
  deleteUserBranchRelation: Scalars['Boolean']['output'];
  deleteVacation: Scalars['Boolean']['output'];
  deleteWorkSchedule: Scalars['Boolean']['output'];
  dismissEmployee: DismissalEmployeeProxy;
  generateManageUrl: Scalars['String']['output'];
  generatePaymentUrl: Scalars['String']['output'];
  /** @deprecated This mutation is deprecated, please use updateServiceCategoryNew. */
  moveServiceCategory: Scalars['Boolean']['output'];
  /** @deprecated This mutation is deprecated, please use updateServiceCategoryNew. */
  moveServiceSubcategory: Scalars['Boolean']['output'];
  updateBookingBranchConfig: Scalars['Boolean']['output'];
  updateBranch: BranchProxy;
  updateClient: ClientProxy;
  updateCompany: CompanyProxy;
  updateCompanyModuleRelation: CompanyProxy;
  updateCurrentBranch: Scalars['Boolean']['output'];
  updateCustomDay?: Maybe<CustomDayProxy>;
  updateDismissEmployee: DismissalEmployeeProxy;
  updateEmployee: EmployeeProxy;
  updateEmployeePosition: EmployeePositionProxy;
  updateEmployeeRecordSettings: RecordSettingProxy;
  updateEmployeeServiceRelation: EmployeeServiceRelationProxy;
  updateEmployeeWorkScheduleSettings: EmployeeWorkScheduleSetting;
  updateLogbookSettings: LogbookSetting;
  updateMenu: MenuProxy;
  updateMenuCategory: MenuCategoryProxy;
  updateMenuItem: MenuItemProxy;
  updateMenuSupplement: MenuSupplementProxy;
  updateReview: ReviewProxy;
  updateSalaryPayment: SalaryPaymentProxy;
  updateService: ServiceProxy;
  /** @deprecated This mutation is deprecated, please use updateServiceCategoryNew. */
  updateServiceCategory: ServiceCategoryProxy;
  updateServiceCategoryNew: ServiceCategoryNewProxy;
  updateServiceCategorySalary: ServiceCategorySalaryProxy;
  /** @deprecated This mutation is deprecated, please use updateServiceCategoryNew. */
  updateServiceSubcategory: ServiceSubcategoryProxy;
  updateUser: UserProxy;
  updateVacation: VacationProxy;
  updateWorkSchedule: WorkScheduleProxy;
};


export type MutationCheckMenuRelationsArgs = {
  companyId: Scalars['Int']['input'];
  input: MenuRelationsInput;
  menuId: Scalars['Int']['input'];
};


export type MutationCloneMenuArgs = {
  branchId: Scalars['Int']['input'];
  cloneMenuId: Scalars['Int']['input'];
  input: MenuInput;
};


export type MutationCloneMenuItemArgs = {
  cloneMenuItemId: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
  input: MenuItemInput;
};


export type MutationCreateAllRelationsArgs = {
  companyId: Scalars['Int']['input'];
  input: EmployeeServiceRelationInputAll;
};


export type MutationCreateBranchArgs = {
  companyId: Scalars['Int']['input'];
  input: BranchInput;
};


export type MutationCreateClientArgs = {
  companyId: Scalars['Int']['input'];
  input: ClientInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationCreateCustomDayArgs = {
  branchId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: CustomDayInput;
};


export type MutationCreateEmployeeArgs = {
  companyId: Scalars['Int']['input'];
  input: EmployeeInput;
};


export type MutationCreateEmployeePositionArgs = {
  companyId: Scalars['Int']['input'];
  input: PositionInput;
};


export type MutationCreateEmployeeServiceRelationArgs = {
  companyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: EmployeeServiceRelationInput;
  serviceId: Scalars['Int']['input'];
};


export type MutationCreateMenuArgs = {
  branchId: Scalars['Int']['input'];
  input: MenuInput;
};


export type MutationCreateMenuCategoryArgs = {
  companyId: Scalars['Int']['input'];
  input: MenuCategoryInput;
};


export type MutationCreateMenuItemArgs = {
  companyId: Scalars['Int']['input'];
  input: MenuItemInput;
};


export type MutationCreateMenuSupplementArgs = {
  companyId: Scalars['Int']['input'];
  input: MenuSupplementInput;
};


export type MutationCreateReviewArgs = {
  companyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: ReviewAdminInput;
};


export type MutationCreateRoleArgs = {
  companyId: Scalars['Int']['input'];
  input: RoleInput;
};


export type MutationCreateSalaryPaymentArgs = {
  companyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: SalaryPaymentInput;
};


export type MutationCreateServiceArgs = {
  companyId: Scalars['Int']['input'];
  input: ServiceInput;
};


export type MutationCreateServiceCategoryArgs = {
  input: ServiceCategoryInput;
};


export type MutationCreateServiceCategoryNewArgs = {
  companyId: Scalars['Int']['input'];
  input: ServiceCategoryNewInput;
};


export type MutationCreateServiceCategorySalaryArgs = {
  companyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: ServiceCategorySalaryInput;
};


export type MutationCreateServiceSubcategoryArgs = {
  input: ServiceSubcategoryInput;
};


export type MutationCreateUserBranchRelationArgs = {
  companyId: Scalars['Int']['input'];
  input: Array<UserBranchRelationInput>;
};


export type MutationCreateVacationArgs = {
  employeeId: Scalars['Int']['input'];
  input: VacationInput;
};


export type MutationCreateWorkScheduleArgs = {
  companyId: Scalars['Int']['input'];
  input: WorkScheduleInput;
};


export type MutationDeleteAllRelationsArgs = {
  companyId: Scalars['Int']['input'];
  input: EmployeeServiceRelationInputAll;
};


export type MutationDeleteBranchArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteClientArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteDismissEmployeeArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteEmployeePositionArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  newPositionId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteEmployeeServiceRelationArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteMenuArgs = {
  branchId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteMenuCategoryArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteMenuItemArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteMenuSupplementArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteReviewArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteSalaryPaymentArgs = {
  companyId: Scalars['Int']['input'];
  salaryPaymentId: Scalars['Int']['input'];
};


export type MutationDeleteServiceCategoryArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationDeleteServiceSubcategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserBranchRelationArgs = {
  companyId: Scalars['Int']['input'];
  ids: Array<Scalars['Int']['input']>;
};


export type MutationDeleteVacationArgs = {
  vacationId: Scalars['Int']['input'];
};


export type MutationDeleteWorkScheduleArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDismissEmployeeArgs = {
  companyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: DismissEmployeeInput;
};


export type MutationGenerateManageUrlArgs = {
  paidFeatureId: Scalars['Int']['input'];
};


export type MutationGeneratePaymentUrlArgs = {
  companyId: Scalars['Int']['input'];
  input: GeneratePaymentUrlInput;
};


export type MutationMoveServiceCategoryArgs = {
  id: Scalars['Int']['input'];
  newParentCategoryId: Scalars['Int']['input'];
};


export type MutationMoveServiceSubcategoryArgs = {
  id: Scalars['Int']['input'];
  newParentCategoryId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateBookingBranchConfigArgs = {
  companyId: Scalars['Int']['input'];
  configs: BranchMultipleConfigUpdateInput;
};


export type MutationUpdateBranchArgs = {
  id: Scalars['Int']['input'];
  input: BranchInput;
  restore: Scalars['Boolean']['input'];
};


export type MutationUpdateClientArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: ClientInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['Int']['input'];
  input: CompanyInput;
};


export type MutationUpdateCompanyModuleRelationArgs = {
  companyId: Scalars['Int']['input'];
  input: ModuleInputType;
};


export type MutationUpdateCurrentBranchArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};


export type MutationUpdateCustomDayArgs = {
  customDayId: Scalars['Int']['input'];
  input?: InputMaybe<UpdateCustomDayInput>;
};


export type MutationUpdateDismissEmployeeArgs = {
  companyId: Scalars['Int']['input'];
  dismissalId: Scalars['Int']['input'];
  input: DismissEmployeeInput;
};


export type MutationUpdateEmployeeArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: EmployeeInput;
};


export type MutationUpdateEmployeePositionArgs = {
  companyId: Scalars['Int']['input'];
  input: PositionInput;
  positionId: Scalars['Int']['input'];
};


export type MutationUpdateEmployeeRecordSettingsArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: EmployeeRecordSettingsInput;
};


export type MutationUpdateEmployeeServiceRelationArgs = {
  companyId: Scalars['Int']['input'];
  input: EmployeeServiceRelationInput;
  relationId: Scalars['Int']['input'];
};


export type MutationUpdateEmployeeWorkScheduleSettingsArgs = {
  input: EmployeeWorkScheduleSettingInput;
};


export type MutationUpdateLogbookSettingsArgs = {
  input: LogbookSettingInput;
};


export type MutationUpdateMenuArgs = {
  branchId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: MenuInput;
};


export type MutationUpdateMenuCategoryArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: MenuCategoryInput;
};


export type MutationUpdateMenuItemArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: MenuItemInput;
};


export type MutationUpdateMenuSupplementArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: MenuSupplementInput;
};


export type MutationUpdateReviewArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: ReviewAdminInput;
};


export type MutationUpdateSalaryPaymentArgs = {
  companyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: SalaryPaymentInput;
  salaryPaymentId: Scalars['Int']['input'];
};


export type MutationUpdateServiceArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: ServiceInput;
};


export type MutationUpdateServiceCategoryArgs = {
  id: Scalars['Int']['input'];
  input: ServiceCategoryInput;
};


export type MutationUpdateServiceCategoryNewArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: ServiceCategoryNewInput;
};


export type MutationUpdateServiceCategorySalaryArgs = {
  companyId: Scalars['Int']['input'];
  input: ServiceCategorySalaryInput;
  serviceCategorySalaryId: Scalars['Int']['input'];
};


export type MutationUpdateServiceSubcategoryArgs = {
  id: Scalars['Int']['input'];
  input: ServiceSubcategoryInput;
};


export type MutationUpdateUserArgs = {
  companyId: Scalars['Int']['input'];
  input: UserInput;
  userId: Scalars['Int']['input'];
};


export type MutationUpdateVacationArgs = {
  input: VacationInput;
  vacationId: Scalars['Int']['input'];
};


export type MutationUpdateWorkScheduleArgs = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: WorkScheduleInput;
};

export type OffDayProxy = {
  __typename?: 'OffDayProxy';
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  workSchedule?: Maybe<WorkScheduleProxy>;
};


export type OffDayProxyDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type PaidFeaturePaginated = {
  __typename?: 'PaidFeaturePaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  paidFeatures?: Maybe<Array<PaidFeatureProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PaidFeatureProxy = {
  __typename?: 'PaidFeatureProxy';
  company?: Maybe<CompanyProxy>;
  currency?: Maybe<Currency>;
  dateOfEnd?: Maybe<Scalars['String']['output']>;
  dateOfLastPayment: Scalars['String']['output'];
  dateOfStart?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isYearly: Scalars['Boolean']['output'];
  name?: Maybe<Tariff>;
  paymentOrder?: Maybe<PaymentOrderProxy>;
  paymentOrdersByDate?: Maybe<Array<PaymentOrderProxy>>;
  price: Scalars['Float']['output'];
  status?: Maybe<SubscriptionStatus>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionRegistrationDate?: Maybe<Scalars['String']['output']>;
};


export type PaidFeatureProxyDateOfEndArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type PaidFeatureProxyDateOfLastPaymentArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type PaidFeatureProxyDateOfStartArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type PaidFeatureProxySubscriptionRegistrationDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentOrderProxy = {
  __typename?: 'PaymentOrderProxy';
  amount?: Maybe<Scalars['Float']['output']>;
  company?: Maybe<CompanyProxy>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  paidFeatures?: Maybe<PaidFeatureProxy>;
  parentOrder?: Maybe<PaymentOrderProxy>;
  paymentOrders?: Maybe<Array<PaymentOrderProxy>>;
  planId: Scalars['Int']['output'];
  status?: Maybe<PaymentOrderStatus>;
  test: Scalars['Boolean']['output'];
  user?: Maybe<UserProxy>;
};


export type PaymentOrderProxyCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type PaymentOrderProxyPaymentOrdersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum PaymentOrderStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type PhoneCodeProxy = {
  __typename?: 'PhoneCodeProxy';
  code: Scalars['String']['output'];
  country?: Maybe<CountryProxy>;
  id: Scalars['Int']['output'];
};

export type PlanPriceProxy = {
  __typename?: 'PlanPriceProxy';
  eur?: Maybe<Scalars['Float']['output']>;
  planId: Scalars['Int']['output'];
  planName: TariffPlan;
  uah?: Maybe<Scalars['Float']['output']>;
};

export type PositionInput = {
  company: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ProductAccessProxy = {
  __typename?: 'ProductAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type ProductAttributesProxy = {
  __typename?: 'ProductAttributesProxy';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<ProductProxy>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ProductCategoryAccessProxy = {
  __typename?: 'ProductCategoryAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type ProductCategoryProxy = {
  __typename?: 'ProductCategoryProxy';
  company?: Maybe<CompanyProxy>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  productSubcategories?: Maybe<Array<ProductSubcategoryProxy>>;
  suppliers?: Maybe<Array<SupplierProxy>>;
};


export type ProductCategoryProxyProductSubcategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCategoryProxySuppliersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductProxy = {
  __typename?: 'ProductProxy';
  alternativeUnit?: Maybe<UnitProxy>;
  alternativeUnitGroup?: Maybe<UnitGroupProxy>;
  alternativeValue?: Maybe<Scalars['Float']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyProxy>;
  criticalResidue?: Maybe<Scalars['Int']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productAttributes?: Maybe<Array<ProductAttributesProxy>>;
  productStockStatus?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  storageProductRelationHistories?: Maybe<Array<StorageProductRelationProxy>>;
  storageProductRelations?: Maybe<Array<StorageProductRelationProxy>>;
  subcategory?: Maybe<ProductSubcategoryProxy>;
  technologicalMapProductRelations?: Maybe<Array<TechnologicalMapProductRelationProxy>>;
  unit?: Maybe<UnitProxy>;
  unitGroup?: Maybe<UnitGroupProxy>;
};


export type ProductProxyProductAttributesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProxyStorageProductRelationHistoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProxyStorageProductRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProxyTechnologicalMapProductRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductSubcategoryProxy = {
  __typename?: 'ProductSubcategoryProxy';
  category?: Maybe<ProductCategoryProxy>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  products?: Maybe<Array<ProductProxy>>;
  suppliers?: Maybe<Array<SupplierProxy>>;
};


export type ProductSubcategoryProxyProductsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSubcategoryProxySuppliersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type QualificationConfirmationProxy = {
  __typename?: 'QualificationConfirmationProxy';
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  bookingStatus: Array<BookingStatusProxy>;
  branch?: Maybe<BranchProxy>;
  branchTypes: Array<BranchTypeProxy>;
  company?: Maybe<Company>;
  companyBranches: Array<BranchProxy>;
  countryGeo?: Maybe<CountryGeoProxy>;
  day: Array<DayProxy>;
  dismissalReason: Array<EmployeeDismissalReasonProxy>;
  employeeSpecializations: Array<Maybe<EmployeeSpecializationProxy>>;
  employeeStatus: Array<EmployeeStatusProxy>;
  gender: Array<GenderProxy>;
  job: JobContainerProxy;
  me?: Maybe<Me>;
  menuAllergens: Array<MenuAllergenProxy>;
  menuMarkers: Array<MenuMarkerProxy>;
  menuStatuses: Array<MenuStatusProxy>;
  menuTypes: Array<MenuTypeProxy>;
  planPrice: Array<PlanPriceProxy>;
  relations: RelationProxy;
  role: Array<RoleProxy>;
  serviceCategories?: Maybe<ServiceCategoryNewPaginated>;
  test: Scalars['String']['output'];
  user?: Maybe<UserProxy>;
};


export type QueryBranchArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBranchTypesArgs = {
  onlyBarberShop?: InputMaybe<Scalars['Boolean']['input']>;
  onlyChild?: InputMaybe<Scalars['Boolean']['input']>;
  onlyMeMenu?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyBranchesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  entities?: InputMaybe<Array<InputMaybe<JobEntity>>>;
  status?: InputMaybe<JobStatus>;
};


export type QueryPlanPriceArgs = {
  period?: InputMaybe<TariffPeriod>;
};


export type QueryServiceCategoriesArgs = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentCategoryId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTestArgs = {
  name: Scalars['String']['input'];
};

export type RecordSettingProxy = {
  __typename?: 'RecordSettingProxy';
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  isDefaultEmployee: Scalars['Boolean']['output'];
  isOnlineSignUpAvailable: Scalars['Boolean']['output'];
  isReviewsAvailable: Scalars['Boolean']['output'];
};

export type RelationProxy = {
  __typename?: 'RelationProxy';
  cities?: Maybe<Array<CityProxy>>;
  countries?: Maybe<Array<CountryProxy>>;
  currencies?: Maybe<Array<CurrencyProxy>>;
  days?: Maybe<Array<DayProxy>>;
  languages?: Maybe<Array<LanguageProxy>>;
  modules?: Maybe<Array<Maybe<ModuleProxy>>>;
  timezones?: Maybe<Array<TimeZoneProxy>>;
  units?: Maybe<Array<UnitProxy>>;
};


export type RelationProxyCitiesArgs = {
  countryId: Scalars['Int']['input'];
};


export type RelationProxyTimezonesArgs = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReviewAdminInput = {
  name: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewPaginated = {
  __typename?: 'ReviewPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  reviews?: Maybe<Array<ReviewProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ReviewProxy = {
  __typename?: 'ReviewProxy';
  booking?: Maybe<BookingMainProxy>;
  createdAt?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};


export type ReviewProxyCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type RoleInput = {
  companyAccesses?: InputMaybe<Array<CompanyAccess>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type RoleProxy = {
  __typename?: 'RoleProxy';
  branchAccesses?: Maybe<Array<BranchAccessProxy>>;
  clientAccesses?: Maybe<Array<ClientAccessProxy>>;
  companyAccesses?: Maybe<Array<CompanyAccessProxy>>;
  employeeAccesses?: Maybe<Array<EmployeeAccessProxy>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  productAccesses?: Maybe<Array<ProductAccessProxy>>;
  productCategoryAccesses?: Maybe<Array<ProductCategoryAccessProxy>>;
  serviceAccesses?: Maybe<Array<ServiceAccessProxy>>;
  serviceCategoryAccesses?: Maybe<Array<ServiceCategoryAccessProxy>>;
  storageAccesses?: Maybe<Array<StorageAccessProxy>>;
  technologicalMapAccesses?: Maybe<Array<TechnologicalMapAccessProxy>>;
  technologicalMapCategoryAccesses?: Maybe<Array<TechnologicalMapCategoryAccessProxy>>;
  userBranchRelations?: Maybe<Array<UserBranchRelationProxy>>;
  userCompanyRelations?: Maybe<Array<UserCompanyRelationProxy>>;
};


export type RoleProxyUserBranchRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type RoleProxyUserCompanyRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type SalaryPaymentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  periodEnd: Scalars['Date']['input'];
  periodStart: Scalars['Date']['input'];
};

export type SalaryPaymentPaginated = {
  __typename?: 'SalaryPaymentPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  salaryPayments?: Maybe<Array<SalaryPaymentProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SalaryPaymentProxy = {
  __typename?: 'SalaryPaymentProxy';
  bookings?: Maybe<BookingPaginated>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  paymentValue?: Maybe<Scalars['Float']['output']>;
  periodEnd?: Maybe<Scalars['String']['output']>;
  periodStart?: Maybe<Scalars['String']['output']>;
};


export type SalaryPaymentProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  salaryValue?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type SalaryPaymentProxyCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type SalaryPaymentProxyPeriodEndArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type SalaryPaymentProxyPeriodStartArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export enum SalaryType {
  Currency = 'CURRENCY',
  Percent = 'PERCENT'
}

export type ServiceAccessProxy = {
  __typename?: 'ServiceAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type ServiceBookingProxy = {
  __typename?: 'ServiceBookingProxy';
  currencyCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  serviceCategory?: Maybe<ServiceCategoryProxy>;
  serviceCategoryNew?: Maybe<ServiceCategoryNewProxy>;
  serviceSubcategory?: Maybe<ServiceSubcategoryProxy>;
};


export type ServiceBookingProxyDurationArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceCategoryAccessProxy = {
  __typename?: 'ServiceCategoryAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type ServiceCategoryInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  company: CompanyInput;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type ServiceCategoryNewInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parentCategoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceCategoryNewPaginated = {
  __typename?: 'ServiceCategoryNewPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<ServiceCategoryNewProxy>>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ServiceCategoryNewProxy = {
  __typename?: 'ServiceCategoryNewProxy';
  childCategories?: Maybe<Array<Maybe<ServiceCategoryNewProxy>>>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyProxy>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentCategory?: Maybe<ServiceCategoryNewProxy>;
  services?: Maybe<Array<ServiceProxy>>;
};


export type ServiceCategoryNewProxyChildCategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceCategoryNewProxyServicesArgs = {
  bookingUuid?: InputMaybe<Scalars['Uuid']['input']>;
  branchId?: InputMaybe<Scalars['Int']['input']>;
  dateTime?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceCategoryPaginated = {
  __typename?: 'ServiceCategoryPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  serviceCategories?: Maybe<Array<ServiceCategoryProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ServiceCategoryProxy = {
  __typename?: 'ServiceCategoryProxy';
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyProxy>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  serviceSubcategories?: Maybe<Array<ServiceSubcategoryProxy>>;
  services?: Maybe<Array<ServiceProxy>>;
};


export type ServiceCategoryProxyServiceSubcategoriesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceCategoryProxyServicesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceCategorySalaryInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  salaryType: SalaryType;
  salaryValue: Scalars['Float']['input'];
  selectedAllCategories: Scalars['Boolean']['input'];
  serviceCategoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceCategorySalaryProxy = {
  __typename?: 'ServiceCategorySalaryProxy';
  deleted: Scalars['Boolean']['output'];
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  salaryType?: Maybe<Scalars['Int']['output']>;
  salaryValue?: Maybe<Scalars['Float']['output']>;
  selectedAll: Scalars['Boolean']['output'];
  serviceCategory?: Maybe<Array<ServiceCategoryNewProxy>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type ServiceCategorySalaryProxyUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceEmployeesPaginated = {
  __typename?: 'ServiceEmployeesPaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  serviceEmployees?: Maybe<Array<EmployeeProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ServiceInput = {
  allowDeleteDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  category: Scalars['Int']['input'];
  company: Scalars['Int']['input'];
  deleted: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['String']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  isUpdateImage?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  onlineBooking: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  subcategory?: InputMaybe<Scalars['Int']['input']>;
};

export type ServicePaginated = {
  __typename?: 'ServicePaginated';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemsPerPage?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['String']['output']>;
  services?: Maybe<Array<ServiceProxy>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ServiceProxy = {
  __typename?: 'ServiceProxy';
  bookings?: Maybe<BookingPaginated>;
  company?: Maybe<CompanyProxy>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  employeeServiceRelations?: Maybe<Array<EmployeeServiceRelationProxy>>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onlineRegistration: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  serviceCategory?: Maybe<ServiceCategoryProxy>;
  serviceCategoryNew?: Maybe<ServiceCategoryNewProxy>;
  serviceEmployees?: Maybe<ServiceEmployeesPaginated>;
  serviceEmployeesList?: Maybe<Array<EmployeeProxy>>;
  serviceSubcategory?: Maybe<ServiceSubcategoryProxy>;
  technologicalMapServiceRelations?: Maybe<Array<TechnologicalMapServiceRelationProxy>>;
};


export type ServiceProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceProxyDurationArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceProxyEmployeeServiceRelationsArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceProxyServiceEmployeesArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  employeeName?: InputMaybe<Scalars['String']['input']>;
  employeePosition?: InputMaybe<Scalars['Int']['input']>;
  filterByService?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceProxyServiceEmployeesListArgs = {
  employeeName?: InputMaybe<Scalars['String']['input']>;
  employeePosition?: InputMaybe<Scalars['Int']['input']>;
  filterByService?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServiceProxyTechnologicalMapServiceRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceSubcategoryInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  serviceCategoryId: Scalars['Int']['input'];
};

export type ServiceSubcategoryProxy = {
  __typename?: 'ServiceSubcategoryProxy';
  bookings?: Maybe<BookingPaginated>;
  comment?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  serviceCategory?: Maybe<ServiceCategoryProxy>;
  services?: Maybe<Array<ServiceProxy>>;
};


export type ServiceSubcategoryProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceSubcategoryProxyServicesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StorageAccessProxy = {
  __typename?: 'StorageAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type StorageProductRelationHistoryProxy = {
  __typename?: 'StorageProductRelationHistoryProxy';
  action?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  product?: Maybe<ProductProxy>;
  quantity: Scalars['Int']['output'];
  relationStorage?: Maybe<StorageProxy>;
  storage?: Maybe<StorageProxy>;
  unit?: Maybe<UnitProxy>;
  user?: Maybe<UserProxy>;
  writeOffReason?: Maybe<WriteOffReasonProxy>;
};


export type StorageProductRelationHistoryProxyDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type StorageProductRelationProxy = {
  __typename?: 'StorageProductRelationProxy';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  product?: Maybe<ProductProxy>;
  quantity?: Maybe<Scalars['Int']['output']>;
  storage?: Maybe<StorageProxy>;
  unit?: Maybe<UnitProxy>;
  unitGroup?: Maybe<UnitGroupProxy>;
};

export type StorageProxy = {
  __typename?: 'StorageProxy';
  branch?: Maybe<BranchProxy>;
  default: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  relatedStorageProductRelationHistories?: Maybe<Array<StorageProductRelationHistoryProxy>>;
  storageProductRelationHistories?: Maybe<Array<StorageProductRelationHistoryProxy>>;
  storageProductRelations?: Maybe<Array<StorageProductRelationProxy>>;
  type?: Maybe<StorageTypeProxy>;
};


export type StorageProxyRelatedStorageProductRelationHistoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type StorageProxyStorageProductRelationHistoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type StorageProxyStorageProductRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StorageTypeProxy = {
  __typename?: 'StorageTypeProxy';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  storages?: Maybe<Array<StorageProxy>>;
};


export type StorageTypeProxyStoragesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Paused = 'PAUSED'
}

export type SupplierProxy = {
  __typename?: 'SupplierProxy';
  address?: Maybe<Scalars['String']['output']>;
  branch?: Maybe<BranchProxy>;
  comment?: Maybe<Scalars['String']['output']>;
  communicationMethod?: Maybe<Scalars['String']['output']>;
  contactPerson?: Maybe<Scalars['String']['output']>;
  costOfDelivery?: Maybe<Scalars['Int']['output']>;
  deliveryAcceptanceDate?: Maybe<Scalars['String']['output']>;
  deliveryInformation?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  loyaltyProgram?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<ProductCategoryProxy>;
  productSubcategory?: Maybe<ProductSubcategoryProxy>;
  returnPolicy?: Maybe<Scalars['String']['output']>;
};

export enum Tariff {
  BaseModule = 'BASE_MODULE',
  MenuModule = 'MENU_MODULE',
  ServicesModule = 'SERVICES_MODULE',
  TariffLite = 'TARIFF_LITE',
  TariffPremium = 'TARIFF_PREMIUM',
  TariffStarter = 'TARIFF_STARTER',
  WarehousesModule = 'WAREHOUSES_MODULE'
}

export enum TariffPeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export enum TariffPlan {
  LiteMonthly = 'LITE_MONTHLY',
  LiteYearly = 'LITE_YEARLY',
  PremiumMonthly = 'PREMIUM_MONTHLY',
  PremiumYearly = 'PREMIUM_YEARLY',
  WarehouseMonthly = 'WAREHOUSE_MONTHLY',
  WarehouseYearly = 'WAREHOUSE_YEARLY'
}

export type TechnologicalMapAccessProxy = {
  __typename?: 'TechnologicalMapAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type TechnologicalMapCategoryAccessProxy = {
  __typename?: 'TechnologicalMapCategoryAccessProxy';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type TechnologicalMapCategoryProxy = {
  __typename?: 'TechnologicalMapCategoryProxy';
  company?: Maybe<CompanyProxy>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  technologicalMapSubcategories?: Maybe<Array<TechnologicalMapSubcategoryProxy>>;
};


export type TechnologicalMapCategoryProxyTechnologicalMapSubcategoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TechnologicalMapProductRelationProxy = {
  __typename?: 'TechnologicalMapProductRelationProxy';
  id: Scalars['Int']['output'];
  product?: Maybe<ProductProxy>;
  requiredQuantity?: Maybe<Scalars['Int']['output']>;
  technologicalMap?: Maybe<TechnologicalMapProxy>;
  unit?: Maybe<UnitProxy>;
};

export type TechnologicalMapProxy = {
  __typename?: 'TechnologicalMapProxy';
  bookings?: Maybe<BookingPaginated>;
  company?: Maybe<CompanyProxy>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  technologicalMapProductRelations?: Maybe<Array<TechnologicalMapProductRelationProxy>>;
  technologicalMapServiceRelations?: Maybe<Array<TechnologicalMapServiceRelationProxy>>;
  technologicalMapSubcategory?: Maybe<TechnologicalMapSubcategoryProxy>;
};


export type TechnologicalMapProxyBookingsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  clientInputType?: InputMaybe<ClientQueryInput>;
  completedBookings?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  removeCanceledBookings?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};


export type TechnologicalMapProxyTechnologicalMapProductRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type TechnologicalMapProxyTechnologicalMapServiceRelationsArgs = {
  connect?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TechnologicalMapServiceRelationProxy = {
  __typename?: 'TechnologicalMapServiceRelationProxy';
  connect: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  service?: Maybe<ServiceProxy>;
  technologicalMap?: Maybe<TechnologicalMapProxy>;
};

export type TechnologicalMapSubcategoryProxy = {
  __typename?: 'TechnologicalMapSubcategoryProxy';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  technologicalMapCategory?: Maybe<TechnologicalMapCategoryProxy>;
  technologicalMaps?: Maybe<Array<TechnologicalMapProxy>>;
};


export type TechnologicalMapSubcategoryProxyTechnologicalMapsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeZoneProxy = {
  __typename?: 'TimeZoneProxy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type UnitGroupProxy = {
  __typename?: 'UnitGroupProxy';
  alternativeProducts?: Maybe<Array<ProductProxy>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  products?: Maybe<Array<ProductProxy>>;
  storageProductRelations?: Maybe<Array<StorageProductRelationProxy>>;
  units?: Maybe<Array<UnitProxy>>;
};


export type UnitGroupProxyAlternativeProductsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type UnitGroupProxyProductsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type UnitGroupProxyStorageProductRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type UnitGroupProxyUnitsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UnitProxy = {
  __typename?: 'UnitProxy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unitGroup?: Maybe<UnitGroupProxy>;
  unitOrder?: Maybe<Scalars['Int']['output']>;
  unitRelations?: Maybe<Array<UnitRelationProxy>>;
};


export type UnitProxyUnitRelationsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UnitRelationProxy = {
  __typename?: 'UnitRelationProxy';
  finishUnit?: Maybe<UnitProxy>;
  id: Scalars['Int']['output'];
  relation?: Maybe<Scalars['Float']['output']>;
  startUnit?: Maybe<UnitProxy>;
};

export type UpdateCustomDayInput = {
  customDayPeriods?: InputMaybe<Array<InputMaybe<CustomDayPeriodInput>>>;
};

export type UserBranchRelationInput = {
  branchId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
};

export type UserBranchRelationProxy = {
  __typename?: 'UserBranchRelationProxy';
  branch: BranchProxy;
  employee: EmployeeProxy;
  id: Scalars['Int']['output'];
  role: RoleProxy;
};

export type UserCompanyRelationProxy = {
  __typename?: 'UserCompanyRelationProxy';
  company?: Maybe<CompanyProxy>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<RoleProxy>;
};

export type UserInput = {
  currentBranch: Scalars['Int']['input'];
};

export type UserProxy = {
  __typename?: 'UserProxy';
  companies?: Maybe<Array<CompanyProxy>>;
  company?: Maybe<CompanyProxy>;
  currentBranch?: Maybe<BranchProxy>;
  email?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  id: Scalars['Int']['output'];
  isUserActiveWithinTariff?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RoleProxy>;
  surname?: Maybe<Scalars['String']['output']>;
};


export type UserProxyCompanyArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
};


export type UserProxyEmployeeArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type UserProxyIsUserActiveWithinTariffArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type UserProxyNameArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type UserProxyRoleArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type UserProxySurnameArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};

export type VacationInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type VacationProxy = {
  __typename?: 'VacationProxy';
  employee?: Maybe<EmployeeProxy>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};


export type VacationProxyEndDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type VacationProxyStartDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type WorkScheduleInput = {
  branchId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  workingDayPeriods: Array<WorkingDayPeriodInput>;
};

export type WorkScheduleProxy = {
  __typename?: 'WorkScheduleProxy';
  branch?: Maybe<BranchProxy>;
  editUrl?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<EmployeeProxy>;
  employeeLogbookUrl?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  offDays?: Maybe<Array<OffDayProxy>>;
  startDate: Scalars['String']['output'];
  workingDayPeriods: Array<Maybe<WorkingDayPeriodProxy>>;
};


export type WorkScheduleProxyEndDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type WorkScheduleProxyOffDaysArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type WorkScheduleProxyStartDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type WorkScheduleProxyWorkingDayPeriodsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkingDayPeriodInput = {
  dayId: Scalars['Int']['input'];
  endTime: Scalars['Time']['input'];
  startTime: Scalars['Time']['input'];
};

export type WorkingDayPeriodProxy = {
  __typename?: 'WorkingDayPeriodProxy';
  day?: Maybe<DayProxy>;
  endTime: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  startTime: Scalars['String']['output'];
  workSchedule?: Maybe<WorkScheduleProxy>;
};


export type WorkingDayPeriodProxyEndTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};


export type WorkingDayPeriodProxyStartTimeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type WriteOffReasonProxy = {
  __typename?: 'WriteOffReasonProxy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  storageProductRelationHistories?: Maybe<Array<StorageProductRelationHistoryProxy>>;
};


export type WriteOffReasonProxyStorageProductRelationHistoriesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};
