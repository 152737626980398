import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ServiceSubcategoryList} from "../ServiceSubcategoryList/ServiceSubcategoryList";
import style from "../ServiceCategoryList/ServiceListComponent.module.scss";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {SimpleModal} from "@services/ui-components/src/SimpleModal/SimpleModal";
import {delModalCategoryContentType} from "../../../../interfaces/interfaces";
import ServiceValidation from "../../../../services/serviceCategory/ServiceValidation";
import {MoveModalComponent} from "../../MoveModalComponent/MoveModalComponent";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import i18next from "i18next";
import {Link} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {ServiceCategoryNewInput, ServiceCategoryNewProxy} from '@me-team/host/main/graphql/types';
import {useUpdateServiceCategoryMutation} from '@me-team/host/main/serviceCategories/graphql/serviceCategories.hooks';
import {useCreateServCategoryMutation} from "@me-team/host/main/services/graphql/services.hooks";
import ToastComponent from "../../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../../hooks/useToast";
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';


type ServiceListComponentProps = {
    category: ServiceCategoryNewProxy
    categoryList: ServiceCategoryNewProxy[]
    refreshCategoryListPerPage: () => void
    hasAccess: boolean
    toggleShowToast: (text: string) => void
}

export const ServiceComponent: React.FC<ServiceListComponentProps> = (props) => {
    const {t: translate} = useTranslation();
    const [isSubcategoryOpen, setIsSubcategoryOpen] = useState<boolean>(false);
    const [isDelModalOpen, setIsDelModalOpen] = useState(false);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false);
    const [delModalContent, setDelModalContent] = useState<delModalCategoryContentType>({
        title: '',
        description: '',
        withoutSubcatAndServices: true,
        withSubcat: false,
        withServiceInCategory: false,
        withServiceInSubcat: false
    })
    const [isUpdateCategoryModalOpen, setIsUpdateCategoryModalOpen] = useState<boolean>(false);
    const [isCreateAction, setIsCreateAction] = useState<boolean>(false);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const {showToast, toastText, toggleShowToast, setShowToast} = useToast();
    const [updateServiceCategory] = useUpdateServiceCategoryMutation({
        fetchPolicy: 'network-only',
    });

    const [createCategory] = useCreateServCategoryMutation({
        fetchPolicy: 'network-only'
    });

    const openSubcategoryHandler = () => {
        setIsSubcategoryOpen(!isSubcategoryOpen);

    }

    const handleOpenDelModal = () => {
        ServiceValidation.deleteCategoryValidator(props.category, setDelModalContent, translate)
        setIsDelModalOpen(true);
    }

    const handleCloseDelModal = () => {
        setIsDelModalOpen(false)
    }

    const deleteCategoryHandler = () => {
        const input: ServiceCategoryNewInput = RequestInputService.createServiceCategoryInput(props.category.id, null, props.categoryList)
        input.deleted = true;
        updateServiceCategory({
            variables: {id: props.category.id, input: input, companyId: +currentCompanyId},
            onCompleted: () => {
                props.refreshCategoryListPerPage();
                setIsDelModalOpen(false);
                toggleShowToast(translate('Deleted'));
            }
        })
    }

    const onOpenMoveModal = () => {
        setIsMoveModalOpen(true)
    }

    const onCloseMoveModal = () => {
        setIsMoveModalOpen(false)
    }

    const moveCategoryHandler = (inputForReq: ServiceCategoryNewInput) => {
        updateServiceCategory({
            variables: {id: props.category.id, input: inputForReq, companyId: +currentCompanyId},
            onCompleted: () => {
                props.refreshCategoryListPerPage();
                onCloseMoveModal();
                toggleShowToast(translate('Saved'));
            }
        });
    }

    const onSubmitHandlerRestoreCategory = () => {
        props.refreshCategoryListPerPage()
    }

    const onUpdateCategoryHandler = (isCreateAction: boolean) => {
        setIsCreateAction(isCreateAction);
        setIsUpdateCategoryModalOpen(true);
    }

    ////TODO Uncomment move category when all back will be ready
    return (
        <>
            <Container className="border-bottom table-category-width">
                <Row
                    className={`gx-3 ${props.hasAccess ? 'py-3' : 'py-4'} flex-nowrap ps-4 ${props.category.deleted && 'text-secondary'}`}>
                    <Col xs={3} className={"d-flex align-items-center"}>
                        {props.category.childCategories.length !== 0 &&
                            <button className={`btn p-1 me-2 ${props.category.deleted && 'text-secondary'}`}
                                    onClick={openSubcategoryHandler}>
                                <i className={`bi bi-chevron-right ${isSubcategoryOpen ? 'rotated90' : ''} d-flex`}></i>
                            </button>}
                        {props.category.name}
                    </Col>
                    <Col xs={5} className={"d-flex align-items-center"}>
                        {props.category.comment ? props.category.comment : '—'}
                    </Col>
                    <Col xs={2} className={"d-flex align-items-center"}>
                        {props.category.services.length !== 0 ?
                            <Link to={`${currentCompanyId}/service-list/categoryId/${props.category.id}`}
                                  className={"text-decoration-none"}>{props.category.services.length}</Link>
                            : '-'}
                    </Col>
                    {props.hasAccess &&
                        <>
                            {props.category.deleted ?
                                <Col xs={2} className={"d-flex align-items-center justify-content-end pe-0"}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip
                                            id="tooltip-top">{translate('servicesPage.categoryTooltip')}</Tooltip>}
                                    >
                                        <Button variant="secondary" className={"px-3 py-2 me-2 fw-normal rounded-1"}>
                                            {translate('servicesPage.archive')}
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="tooltip-top">{translate('Restore')}</Tooltip>}
                                    >
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(false)
                                                }}>
                                            <i className="bi bi-arrow-counterclockwise d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>

                                </Col> :
                                <Col xs={2}
                                     className={"d-flex align-items-center justify-content-end flex-wrap gap-2 pe-0"}>
                                    {props.category.childCategories.length === 0 &&
                                        <OverlayTrigger placement="bottom" overlay={<Tooltip
                                            className="tooltip-category">{translate('Move category')}</Tooltip>}>
                                            <Button className={"btn rounded text-dark bg-white border"}
                                                    onClick={onOpenMoveModal}>
                                                <i className="bi bi-box-arrow-right d-flex fs-normal"></i>
                                            </Button>
                                        </OverlayTrigger>}
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip
                                        className="tooltip-category">{translate('Add subcategory')}</Tooltip>}>
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(true)
                                                }}>
                                            <i className="bi bi-plus-lg d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip
                                        className="tooltip-category">{translate('Edit category')}</Tooltip>}>
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(false)
                                                }}>
                                            <i className="bi bi-pencil d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip>{translate('Delete category')}</Tooltip>}>
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={handleOpenDelModal}>
                                            <i className="bi bi-trash3 d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </>
                    }
                </Row>
            </Container>
            {props.category.childCategories.length !== 0 &&
                <div className={`${style.servicesSubcategoryList} ${isSubcategoryOpen ? style.open : ''}`}>
                    {props.category.childCategories.map((subcategory: ServiceCategoryNewProxy, index) => (
                        <ServiceSubcategoryList
                            key={index}
                            subcategory={subcategory}
                            parentCategory={props.category}
                            categoryList={props.categoryList}
                            refreshCategoryListPerPage={props.refreshCategoryListPerPage}
                            hasAccess={props.hasAccess}
                        />
                    ))}
                </div>
            }
            <SimpleModal isOpen={isDelModalOpen} onClose={handleCloseDelModal} title={delModalContent.title}
                         description={delModalContent.description}>
                <Container>
                    <Row>
                        {(delModalContent.withoutSubcatAndServices || delModalContent.withSubcat) &&
                            <>
                                <Col xs={6} className={"ps-0"}>
                                    <Button variant="outline-primary" className={"w-100 fw-normal"}
                                            onClick={handleCloseDelModal}>
                                        {translate('servicesPage.deleteModal.cancelBtn')}
                                    </Button>
                                </Col>
                                <Col xs={6} className={"pe-0"}>
                                    <Button variant="primary" className={"w-100 fw-normal"}
                                            onClick={deleteCategoryHandler}>
                                        {translate('servicesPage.deleteModal.applyBtn')}
                                    </Button>
                                </Col>
                            </>
                        }

                        {delModalContent.withServiceInSubcat &&
                            <Col xs={12} className={"px-0"}>
                                <Button variant="primary" className={"w-100 fw-normal"} onClick={handleCloseDelModal}>
                                    {translate('servicesPage.deleteModal.okBtn')}
                                </Button>
                            </Col>
                        }

                        {delModalContent.withServiceInCategory &&
                            <Col xs={12} className={"px-0"}>
                                <Link to={`${currentCompanyId}/service-list/categoryId/${props.category.id}`}
                                      className={"btn btn-primary w-100 fw-normal"}
                                >
                                    {translate('servicesPage.deleteModal.toServiceLink')}
                                </Link>
                            </Col>
                        }
                    </Row>
                </Container>
            </SimpleModal>

            {isMoveModalOpen &&
                <MoveModalComponent isMoveModalOpen={isMoveModalOpen} onCloseMoveModal={onCloseMoveModal}
                                    moveCategoryHandler={moveCategoryHandler} currentCategory={props.category}
                                    categoryList={props.categoryList}/>}
            {isUpdateCategoryModalOpen &&
                <CreateEditModalComponent
                    i18n={i18next}
                    categoryId={props.category.id}
                    subcategoryId={null}
                    isOpen={isUpdateCategoryModalOpen}
                    categoryInput={RequestInputService.createServiceCategoryInput(props.category.id, null, props.categoryList)}
                    categoryList={props.categoryList}
                    isCreateAction={isCreateAction}
                    onClose={() => {
                        setIsUpdateCategoryModalOpen(false)
                    }}
                    onSubmit={() => {

                    }}
                    onSubmitCreate={async (inputVariables) => {
                        await createCategory({
                            variables: {companyId: inputVariables.companyId, input: inputVariables.input},
                            onCompleted: () => {
                                onSubmitHandlerRestoreCategory();
                                toggleShowToast(translate('Created'));
                            }
                        })

                    }}
                    onSubmitUpdate={async (inputVariables) => {
                        await updateServiceCategory({
                            variables: {id: inputVariables.id, input: inputVariables.input, companyId: +currentCompanyId},
                            onCompleted: () => {
                                onSubmitHandlerRestoreCategory();
                                if (props.category.deleted) {
                                    toggleShowToast(translate('Restored'));
                                } else {
                                    toggleShowToast(translate('Saved'));
                                }
                            }
                        })
                    }}
                    requestInputService={RequestInputService}
                />
            }
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}

        </>
    )
}
