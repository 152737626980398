import React from 'react';

interface RenderCompletionIconProps {
  isCompleted: boolean
}

const RenderCompletionIcon: React.FC<RenderCompletionIconProps> = ({isCompleted}) => {
  return (
    <i
      className={`bi ${isCompleted ? "bi-check-circle text-green" : "bi-circle text-secondary"} fs-20 me-3`}
    ></i>
  );
};

export default RenderCompletionIcon;