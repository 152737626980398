import React, { FC, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/main/globalVar/state';
import {MeUserDocument} from '@me-team/host/main/globalVar/graphql/globalVar.hooks';

interface ProtectedRegistrationRouteProps {
    element: React.ComponentType;
}

const ProtectedRegistrationRoute: FC<ProtectedRegistrationRouteProps> = ({ element: Component, ...rest }) => {
    const { loading, error } = useQuery(MeUserDocument);

    const navigate = useNavigate();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    if (loading) return <div>Loading...</div>;

    if (error) {
        console.error('Error fetching user data:', error);
        return <div>Error loading data</div>;
    }

    useEffect(() => {
        if (currentCompanyId && currentBranchId) {
            navigate(`/branches/main/branch/${currentBranchId}`);
        }
    }, [currentCompanyId, currentBranchId]);

    return <Component />;
};

export default ProtectedRegistrationRoute;
