import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";

interface ButtonProps {
  text: string;
  onClick: () => void;
  variant: string;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

interface AddEditModalProps {
  isModalOpened: boolean;
  setIsModalOpened: (isOpened: boolean) => void;
  title: string;
  children: React.ReactNode;
  modalBodyClass?: string;
}

const AddEditModal: React.FC<AddEditModalProps> = ({
                                                     isModalOpened,
                                                     setIsModalOpened,
                                                     children,
                                                     title,
                                                     modalBodyClass
                                                   }) => {
  const {t} = useTranslation();
  const handleModalClose = () => {
    setIsModalOpened(false);
  };
  return (
    <Modal
      show={isModalOpened}
      size="lg"
      onHide={handleModalClose}
      centered>
      <Modal.Header
        closeButton
        className='border-0'>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`text-center ${modalBodyClass ? modalBodyClass : ''}`}>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default AddEditModal;