import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import StarRating from "@services/ui-components/src/StarRating/StarRating";
import {ApolloError, useReactiveVar} from "@apollo/client";
import ErrorService from "../../services/ErrorService";
import {
    useGetOneReviewQuery,
    useUpdateReviewMutation
} from '@me-team/host/main/clientsHistoryVisit/graphql/clientsHistoryVisit.hooks';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

interface FormValuesReview {
    name?: string;
    text?: string;
}

interface inputReview {
    name: string,
    text: string,
    id: number,
    rating: number
}

interface ClientsEditReviewModalProps {
    isModalOpened: boolean;
    setIsModalOpened: (isOpened: boolean) => void;
    reviewId: number;
    setIsErrorModalOpen: (error: number) => void
}

const ClientsEditReviewModal: React.FC<ClientsEditReviewModalProps> = ({ isModalOpened, setIsModalOpened, reviewId, setIsErrorModalOpen }) => {
    const {t} = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const { data: oneReviewData, refetch } = useGetOneReviewQuery({
        variables: {
            id: reviewId,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId || !reviewId,
        context: {
            errorType: "global",
        },
        onError: (error: ApolloError) => {
            setIsModalOpened(false);
            setIsErrorModalOpen(ErrorService.errorHandling(error));
        },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if(isModalOpened) {
            refetch();
        }
    }, [isModalOpened, refetch]);

    const [review] = oneReviewData?.user?.company?.reviews?.reviews || [];

    const [updateReview] = useUpdateReviewMutation();

    const initialValues: FormValuesReview = {
        name: review?.name,
        text: review?.text
    }

    const handleReviewSubmit = async (input: inputReview) => {
        const params = {
            ...input,
            companyId: +currentCompanyId,
            id: review.id,
            rating: review.rating
        }

        try {
            await updateReview({
                variables: params,
            });
        } catch (error) {
            console.error('Error updating review:', error);
        }
    }


    return (
        <Modal
            show={isModalOpened}
            size="lg"
            onHide={() => setIsModalOpened(false)}
            centered>
            <Modal.Header
                closeButton
                className='border-0'>
                <Modal.Title className="h5">{t("Edit Review")}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleReviewSubmit}
            >
                {({ isSubmitting, values, handleChange }) => (
                    <Form>
                        <Modal.Body className='text-center mb-3 pb-0 ps-4'>
                            <Row className="align-items-center mt-3 pe-3">
                                <Col sm={3}>
                                    <p className="fs-7 fw-bold text-start">{t("Employee")}</p>
                                </Col>
                                <Col sm={9}>
                                    <p className="text-start">{review?.employee.name}</p>
                                </Col>
                            </Row>
                            <Row className="mt-3 pe-3">
                                <Col sm={3}>
                                    <p className="fs-7 fw-bold text-start">{t("Rating")}</p>
                                </Col>
                                <Col sm={9} className="text-start">
                                    <StarRating rating={review?.rating}/>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <Row className="align-items-center mt-3 pe-3">
                                    <Col sm={3} className="fs-7 fw-bold text-start">
                                        <label htmlFor="name">{t("Client name")}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                    </Col>
                                    <Col sm={9}>
                                        <Field type="text" name="name" className="fs-7 form-control rounded-1" />
                                        <ErrorMessage name="name" component="div" className="fs-7 text-danger" />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <Row className="mt-3 pe-3">
                                    <Col sm={3} className="fs-7 fw-bold text-start pt-1">
                                        <label htmlFor="review">{t("Text")}</label>
                                    </Col>
                                    <Col sm={9}>
                                        <Field
                                            as="textarea"
                                            name="text"
                                            className="form-control rounded-1"
                                            maxLength={200}
                                            style={{minHeight: "140px"}}
                                        />
                                        <div className="fs-7 text-grey text-start">
                                            {`${values?.text?.length}/200`}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='border-0'>
                            <Row className="w-100">
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        onClick={() => setIsModalOpened(false)}
                                        variant="primary" type="submit" disabled={isSubmitting}
                                        className="rounded-1 custom-btn me-3"
                                    >
                                        {t("Save")}
                                    </Button>
                                    <Button
                                        onClick={() => setIsModalOpened(false)}
                                        variant="outline-primary" type="submit" disabled={isSubmitting}
                                        className="fw-normal rounded-1 custom-btn"
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ClientsEditReviewModal;
