import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {UserBranchRelationProxy} from '@me-team/host/main/graphql/types';
import {useTranslation} from 'react-i18next';

interface RenderBranchesProps {
  userBranchRelations: UserBranchRelationProxy[];
}

const RenderBranches: React.FC<RenderBranchesProps> = ({userBranchRelations}) => {
  const {t} = useTranslation();
  return (
    <Row>
      <Col className='text-start'>
        <span>{t('Branches')}:</span>
        {userBranchRelations.length > 0 ? (
          <ul>
            {userBranchRelations.map((relation) => (
              <li key={relation.id}>
                <span>{relation.branch.name}</span>
              </li>
            ))}
          </ul>
        ) : null}
      </Col>
    </Row>
  );
};

export default RenderBranches;