import {AccordionInterface} from "../../interfaces/interfaces";
import {TFunction} from "i18next";
import {UserRole} from "../../utils/enums";

class SidebarService {
    public static accordionContentService = (
        basePath : string,
        currentBranchId : string,
        currentCompanyId : string,
        userRole : string,
        translate : TFunction,
        hasWarehouseModule: boolean
    ): AccordionInterface[] => {
        const isEmployeeRole = parseInt(userRole) === UserRole.EMPLOYEE;
        const isAdminRole = parseInt(userRole) === UserRole.ADMINISTRATOR;
        return [
            ...(
                hasWarehouseModule
                    ? [{
                        title: translate("Warehouse accounting"),
                        items: [
                            { label: translate('Warehouses'), to: `${basePath}/warehouse/storage/branch/${currentBranchId}` },
                            {
                                label: translate('Products'),
                                to: `${basePath}/warehouse/product/${currentCompanyId}`
                            },
                            !isEmployeeRole && { label: translate('Add product'), to: `${basePath}/warehouse/${currentCompanyId}/new-product` },
                            {
                                label: translate('Technological maps'),
                                to: `${basePath}/warehouse/technologicalMap/${currentCompanyId}`
                            },
                            { label: translate('Reports'), to: `${basePath}/warehouse/reports/branch/${currentBranchId}` },
                            { label: translate('Product categories'), to: `${basePath}/warehouse/goods/${currentCompanyId}/category` },
                            {
                                label: translate('Technological map categories'),
                                to: `${basePath}/warehouse/technologicalMapCategory/${currentCompanyId}` }
                        ],
                        icon: "bi bi-shop-window"
                    }]
                    : []
            ),
            {
                title: translate("Personnel management"),
                items: [
                    { label: translate('Employees'), to: `employees-list` },
                    !isEmployeeRole && {
                        label: translate('Add an employee'),
                        to: `employees-list/add-employee`
                    },
                    { label: translate('Positions'), to: `positions` },
                    {
                        label: translate('Work schedule'),
                        to: `work-schedule`
                    },
                    { label: translate('Reviews'), to: `booking/review/company/${currentCompanyId}` },
                    {label: translate('Salary'), to: `personnel/${currentCompanyId}/salary`},
                    !isEmployeeRole && {label: translate('History of Payments'), to: `personnel/${currentCompanyId}/payment-history`}
                ],
                icon: "bi-layout-text-window-reverse"
            },
            {
                title: translate('Services'),
                items: [
                    { label: translate('Service list'), to: `services/service/${currentCompanyId}/service-list` },
                    !isEmployeeRole && { label: translate('Add a service'), to: `services/service/${currentCompanyId}/new` },
                    { label: translate('Service Categories'), to: 'service-categories' },
                ],
                icon: "bi-clipboard"
            },
            {
                title: translate('Logbook'),
                items: [
                    { label: translate('Logbook'), to: `logbook/journal/${currentBranchId}` },
                    { label: translate('Add record'), to: 'logbook/add-record' },
                    { label: translate('Booking Statistic'), to: `${basePath}/booking/statistic/branch/${currentBranchId}` }
                ],
                icon: "bi-journals"
            },
            {
                title: translate('Clients'),
                items: [
                    { label: translate('List of Clients'), to: `clients/${currentCompanyId}/list` },
                    !isEmployeeRole && { label: translate('Add client'), to: `clients/${currentCompanyId}/new` },
                    !isEmployeeRole && { label: translate('History of visits'), to: 'clients-history-visit' }
                ],
                icon: "bi-people"
            },
            {
                title: translate('Online Booking'),
                items: [
                    { label: translate('Online Booking Config'), to: `booking/company/${currentCompanyId}/config` }
                ],
                icon: "bi-calendar4-event"
            },
            ...(
                !(isEmployeeRole || isAdminRole)
                    ? [{
                        title: translate('Management of Tariffs'),
                        items: [
                            { label: translate('Tariff Plans'), to: `payment/company/${currentCompanyId}/tariff` },
                            { label: translate('Modules'), to: `${basePath}/payment/module/company/${currentCompanyId}` },
                            { label: translate('Payment History'), to: `payment/history/company/${currentCompanyId}` },
                        ],
                        icon: "bi-card-checklist"
                    }]
                    : []
            ),
        ]
    }
}

export default SidebarService