import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EmptyList, Pagination, PeriodSummaryRow, Spinner} from '@services/ui-components';
import LabelStatusPayment from './LabelStatusPayment';
import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {formatDate, formatTime} from '@me-team/host/src/utils/utils';
import SalaryRenderer from './SalaryRenderer';
import {UserRole} from '../../utils/enums';
import {calculateTotal, formatDateInString} from './constans';
import { useGetSalaryQuery } from '@me-team/host/main/salary/graphql/salary.hooks';
import { BookingMainProxy } from '@me-team/host/main/graphql/types';
import {
  currencySingVar,
  currentCompanyIdVar,
  currentUserEmailVar,
  currentUserRoleVar
} from '@me-team/host/main/globalVar/state';

interface filterType {
  employeeFilter: number | null,
  dateRangeFilter: {
    startDate: Date,
    endDate: Date,
  },
}

interface SalaryTableProps {
  filter: filterType,
}

const SalaryTable: React.FC<SalaryTableProps> = ({filter}) => {
  const {t} = useTranslation();
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const currentUserEmail = useReactiveVar(currentUserEmailVar);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currencySing = useReactiveVar(currencySingVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);


  const {data: salaryData, loading} = useGetSalaryQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      completedBookings: true,
      filterByCompany: true,
      employeeId: !isEmployeeRole ? filter?.employeeFilter : null,
      email: isEmployeeRole ? currentUserEmail : null,
      startDate: formatDateInString(filter?.dateRangeFilter?.startDate),
      endDate: formatDateInString(filter?.dateRangeFilter?.endDate),
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId || !currentUserEmail,
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const currency = salaryData?.user?.company?.currency
  const salary = salaryData?.user?.company?.bookings?.bookings
  const pageCount = parseInt(salaryData?.user?.company?.bookings?.pageCount || '0');
  const totalItems = salaryData?.user?.company?.bookings?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalSalary = salary ? calculateTotal(salary) : 0;

  if (loading) return <Spinner/>;

  return (
    <>
      <Container fluid className='table-responsive scrollbar position-relative px-0 pt-1'>
        <PeriodSummaryRow
          isButton={!isEmployeeRole}
          totalCost={totalSalary}
          currency={currency?.code}
          totalItems={totalItems && totalItems}
        />
        {salary?.length > 0 ?
          <table className='table table-borderless'>
            <thead>
            <tr className='fs-7'>
              <th className='py-3 col-2 ps-4 pe-2 align-middle'>{t('Date and Time of Service')}</th>
              <th className='py-3 px-2 col-2 align-middle'>{t('Employee')}</th>
              <th className='py-3 px-2 col-2 align-middle'>{t('service')}</th>
              <th className='py-3 px-2 col-1 align-middle'>{t('Calculated')}</th>
              <th className='py-3 px-2 col-3 align-middle text-center'>{t('Payments')}</th>
            </tr>
            </thead>
            <tbody>
            {salary?.map((salaryItem: BookingMainProxy) => (
              <tr key={salaryItem?.id} className='border-bottom'>
                <td className='align-middle py-4 ps-4 pe-2'>
                  {formatDate(salaryItem?.date)} {formatTime(salaryItem?.time)}
                </td>
                <td className='align-middle py-4 px-2'>
                  <div className='d-flex flex-column'>
                    <Link
                      to={`/employees-list/edit-employee/${salaryItem?.employee?.id}`}
                      className='text-decoration-none'
                    >
                      <span>
                        {salaryItem?.employee?.name} {salaryItem?.employee?.surname}
                      </span>
                    </Link>
                    <span className='fs-7 text-grey'>
                        {salaryItem?.employee?.position?.name}
                    </span>
                  </div>
                </td>
                <td className='align-middle py-4 px-2'>
                  <div className='d-flex flex-column'>
                    <span>{salaryItem?.service?.name}</span>
                    <span className='fs-7 text-grey'>
                        ({salaryItem?.service?.serviceCategoryNew?.name})
                      </span>
                  </div>
                </td>
                <td className='align-middle py-4 px-2'>
                  <SalaryRenderer salaryItem={salaryItem} currencySing={currencySing} />
                </td>
                <td className='align-middle text-center py-4 px-2'>
                  <LabelStatusPayment
                    status={!!salaryItem?.salaryPayments?.salaryPayments?.length ? 'paid' : 'not-paid'}/>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          :
          <EmptyList title='The list of salary is empty'>
          </EmptyList>
        }

        {
          salaryData?.user?.company?.bookings?.totalCount > 0 ?
            <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
              <Pagination
                t={t}
                pages={Number(pageCount)}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                firstItemIndex={firstItemIndex}
                lastItemIndex={lastItemIndex}
                totalItems={totalItems}
              />
            </div>
            : null
        }

        {isErrorModalOpen ?
          <ErrorModalComponent
            i18n={i18next}
            onClose={() => {
              setIsErrorModalOpen(null)
            }}
            isOpen={!!isErrorModalOpen}
            currentError={isErrorModalOpen}
          /> : null
        }
      </Container>

    </>
  );
};

export default SalaryTable
