import React from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col } from "react-bootstrap";


const ContactUs: React.FC = () => {
    const {t} = useTranslation();

    return (
        <div className="mb-5">
            <div className="mb-4 pb-xxl-6">
                <p className="letter-spacing-4 mb-2 text-orange text-uppercase fw-bold text-center">{t("contacts")}</p>
                <Col xs={10} md={12} className="mx-auto">
                    <h2 className="text-dark text-center fw-bold mb-0">{t("Need a Consultation? Contact Us!")}</h2>
                </Col>
            </div>
            <div className="p-4 p-lg-5 border border-secondary contact-us-rounded">
                <Row>
                    <Col xs={12} lg={7} xxl={9}>
                        <p className="text-dark fs-32 text-capitalize fw-bold mb-3">
                            {t("Our contacts")}
                        </p>
                        <p className="text-grey mb-4 mb-lg-0">
                            {t("Technical support service works 24/7. Send us an email if you have questions or need consultation.")}
                        </p>
                    </Col>
                    <Col xs={12} lg={5} xxl={3} className="mt-auto">
                        <a className="btn btn-outline-primary w-100 rounded-3 text-decoration-none p-6"
                           href="mailto:support@me-pos.com"><i
                            className="bi bi-envelope-paper pe-2"></i>support@me-pos.com</a>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ContactUs;
