import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {SimpleModal} from "@services/ui-components/src/SimpleModal/SimpleModal";
import {useTranslation} from "react-i18next";
import {delModalSubcategoryContentType} from "../../../../interfaces/interfaces";
import ServiceValidation from "../../../../services/serviceCategory/ServiceValidation";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import i18next from "i18next";
import {MoveModalComponent} from "../../MoveModalComponent/MoveModalComponent";
import ToastComponent from "../../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../../hooks/useToast";
import {Link} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {ServiceCategoryNewInput, ServiceCategoryNewProxy} from '@me-team/host/main/graphql/types';
import {useUpdateServiceCategoryMutation} from '@me-team/host/main/serviceCategories/graphql/serviceCategories.hooks';
import {useCreateServCategoryMutation} from "@me-team/host/main/services/graphql/services.hooks";
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

type ServiceSubcategoryListProps = {
    subcategory: ServiceCategoryNewProxy
    refreshCategoryListPerPage: () => void
    categoryList: ServiceCategoryNewProxy[]
    parentCategory: ServiceCategoryNewProxy
    hasAccess: boolean
}

export const ServiceSubcategoryList: React.FC<ServiceSubcategoryListProps> = (props) => {
    const {t: translate} = useTranslation();
    const [isDelModalOpen, setIsDelModalOpen] = useState(false);
    const [isUpdateCategoryModalOpen, setIsUpdateCategoryModalOpen] = useState<boolean>(false)
    const [delModalContent, setDelModalContent] = useState<delModalSubcategoryContentType>(
        {
            title: '',
            description: '',
            withServiceInSubcat: false
        })
    const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false);
    const [isCreateAction, setIsCreateAction] = useState<boolean>(false);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const {showToast, toastText, toggleShowToast, setShowToast} = useToast();

    const [updateServiceCategory] = useUpdateServiceCategoryMutation({
        onError: () => {
            setIsDelModalOpen(false);
            setIsMoveModalOpen(false)
        }
    });
    const [createCategory] = useCreateServCategoryMutation({
        fetchPolicy: 'network-only'
    });
    const handleOpenDelModal = () => {
        ServiceValidation.delSubcategoryValidator(props.subcategory, setDelModalContent, translate)
        setIsDelModalOpen(true)
    }
    const handleCloseDelModal = () => {
        setIsDelModalOpen(false)
    }

    const deleteSubcategoryHandler = () => {
        const input = RequestInputService.createServiceCategoryInput(props.subcategory.parentCategory.id, props.subcategory.id, props.categoryList)
        input.deleted = true;
        updateServiceCategory({
            variables: {id: props.subcategory.id, input: input, companyId: +currentCompanyId}, onCompleted: () => {
                handleCloseDelModal();
                props.refreshCategoryListPerPage();
                toggleShowToast(translate('Deleted'));
            }
        })
    }

    const onUpdateCategoryHandler = (isCreateAction: boolean) => {
        setIsCreateAction(isCreateAction);
        setIsUpdateCategoryModalOpen(true);
    }

    const onOpenMoveModal = () => {
        setIsMoveModalOpen(true)
    }

    const onCloseMoveModal = () => {
        setIsMoveModalOpen(false)
    }

    const moveSubcategoryHandler = (inputForReq: ServiceCategoryNewInput) => {
        updateServiceCategory({
            variables: {id: props.subcategory.id, input: inputForReq, companyId: +currentCompanyId}, onCompleted: () => {
                onCloseMoveModal();
                props.refreshCategoryListPerPage();
                toggleShowToast(translate('Saved'));
            }
        });
    }

    return (
        <>
            <Container className="table-category-width">
                <Row className={`gx-3 ms-3 ${props.subcategory.deleted && 'text-secondary'}`}>
                    <Col xs={3}
                         className={`d-flex align-items-center ps-0 border-bottom ${props.hasAccess ? 'py-3' : 'py-4'}`}>
                        <span className={'ps-5 ms-2'}>{props.subcategory.name}</span>
                    </Col>
                    <Col xs={5}
                         className={`d-flex align-items-center border-bottom ${props.hasAccess ? 'py-3' : 'py-4'}`}>
                        {props.subcategory.comment ? props.subcategory.comment : '—'}
                    </Col>
                    <Col xs={2}
                         className={`d-flex align-items-center border-bottom ${props.hasAccess ? 'py-3' : 'py-4'}`}>
                        {props.subcategory.services.length !== 0 ?
                            <Link to={`${currentCompanyId}/service-list/subCategoryId/${props.subcategory.id}`}
                                  className={"text-decoration-none"}>{props.subcategory.services.length}</Link>
                            : '-'}
                    </Col>
                    {props.hasAccess && <Col xs={2}
                                             className={`d-flex align-items-center justify-content-end border-bottom ${props.hasAccess ? 'py-3' : 'py-4'} pe-0`}>
                        {props.subcategory.deleted ?

                            <>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip
                                        id="tooltip-top">{translate('servicesPage.subcategoryTooltip')}</Tooltip>}
                                >
                                    <Button
                                        variant="secondary"
                                        className="px-3 py-2 disabled-btn fw-normal rounded-1"
                                    >
                                        {translate('servicesPage.archive')}
                                    </Button>
                                </OverlayTrigger>
                                {!props.parentCategory.deleted &&
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="tooltip-top">{translate('Restore')}</Tooltip>}
                                    >
                                        <Button className={"btn rounded border ms-2 text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(false)
                                                }}>
                                            <i className="bi bi-arrow-counterclockwise d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>


                                }
                            </>
                            :
                            <>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('Move category')}</Tooltip>}
                                >
                                    <button className={"btn rounded border me-2"} onClick={onOpenMoveModal}>
                                        <i className="bi bi-box-arrow-right fs-normal d-flex"></i>
                                    </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('Edit category')}</Tooltip>}
                                >
                                    <button className={"btn rounded-1 border me-2"} onClick={() => {
                                        onUpdateCategoryHandler(false)
                                    }}>
                                        <i className="bi bi-pencil d-flex fs-normal"></i>
                                    </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('Delete category')}</Tooltip>}
                                >
                                    <button className={"btn rounded-1 border"} onClick={handleOpenDelModal}>
                                        <i className="bi bi-trash3 d-flex fs-normal"></i>
                                    </button>
                                </OverlayTrigger>
                            </>
                        }
                    </Col>}
                </Row>
            </Container>
            <SimpleModal isOpen={isDelModalOpen} onClose={handleCloseDelModal} title={delModalContent.title}
                         description={delModalContent.description}>
                <Container>
                    <Row>
                        {!delModalContent.withServiceInSubcat &&
                            <>
                                <Col xs={6} className={"ps-0"}>
                                    <Button variant="outline-dark" className={"w-100 fw-normal"}
                                            onClick={handleCloseDelModal}>
                                        {translate('servicesPage.deleteModal.cancelBtn')}
                                    </Button>
                                </Col>
                                <Col xs={6} className={"pe-0"}>
                                    <Button variant="primary" className={"w-100 fw-normal"}
                                            onClick={deleteSubcategoryHandler}>
                                        {translate('servicesPage.deleteModal.applyBtn')}
                                    </Button>
                                </Col>
                            </>
                        }
                        {delModalContent.withServiceInSubcat &&
                            <Col xs={12} className={"px-0"}>
                                <Link to={`${currentCompanyId}/service-list/subCategoryId/${props.subcategory.id}`}
                                      className={"btn btn-primary w-100 fw-normal"}
                                >
                                    {translate('servicesPage.deleteModal.toServiceLink')}
                                </Link>
                            </Col>
                        }
                    </Row>
                </Container>
            </SimpleModal>
            {isUpdateCategoryModalOpen && <CreateEditModalComponent
                i18n={i18next}
                categoryId={props.subcategory.parentCategory.id}
                subcategoryId={props.subcategory.id}
                isOpen={isUpdateCategoryModalOpen}
                categoryInput={RequestInputService.createServiceCategoryInput(props.subcategory.parentCategory.id, props.subcategory.id, props.categoryList)}
                categoryList={props.categoryList}
                isCreateAction={isCreateAction}
                onClose={() => {
                    setIsUpdateCategoryModalOpen(false)
                }}
                onSubmit={() => {

                }}
                onSubmitCreate={async (inputVariables) => {
                    await createCategory({
                        variables: {companyId: inputVariables.companyId, input: inputVariables.input},
                        onCompleted: () => {
                            props.refreshCategoryListPerPage();
                            toggleShowToast(translate('Saved'))
                            setIsDelModalOpen(false);
                            setIsMoveModalOpen(false);
                        }
                    })

                }}
                onSubmitUpdate={async (inputVariables) => {
                    await updateServiceCategory({
                        variables: {id: inputVariables.id, input: inputVariables.input, companyId: +currentCompanyId},
                        onCompleted: () => {
                            props.refreshCategoryListPerPage();
                            setIsDelModalOpen(false)
                            setIsMoveModalOpen(false)
                            if (props.subcategory.deleted) {
                                toggleShowToast(translate('Restored'));
                            } else {
                                toggleShowToast(translate('Saved'));
                            }
                        }
                    })
                }}
                requestInputService={RequestInputService}
            />}
            {isMoveModalOpen &&
                <MoveModalComponent isMoveModalOpen={isMoveModalOpen} onCloseMoveModal={onCloseMoveModal}
                                    moveCategoryHandler={moveSubcategoryHandler} currentCategory={props.subcategory}
                                    categoryList={props.categoryList}/>}
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}
        </>
    )
}
