import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';
import {
  ModalDelete,
  Pagination,
  RenderEmptyList,
  TooltipCustom
} from '@services/ui-components';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {DateTime} from 'luxon';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../services/ErrorService';
import {debounce} from '@me-team/host/src/utils/utils';
import SocketConnection from '../../services/SocketConnection';
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";
import {UserRole} from '../../utils/enums';
import {
  useDeleteClientMutation,
  useGetClientsQuery,
  useGetOneClientQuery
} from '@me-team/host/main/clients/graphql/clients.hooks';
import { ClientProxy } from '@me-team/host/main/graphql/types';
import {
  currencySingVar,
  currentCompanyIdVar,
  currentUserRoleVar
} from '@me-team/host/main/globalVar/state';

type filterType = {
  search: string;
  amountOfVisits: string;
};

export type TableServicesPropsType = {
  filter: filterType;
};

const ClientsTable: React.FC<TableServicesPropsType> = ({filter}) => {
  const {t} = useTranslation();
  const ENTITY_IMPORT_TYPE: string = 'client';
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const currencySing = useReactiveVar(currencySingVar);
  const [isLoadingRefetchClients, setIsLoadingRefetchClients] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCannotDelete, setShowCannotDelete] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState(null);
  const { showToast, toggleShowToast, setShowToast, toastText } = useToast();

  const {
    data: clientsData,
    loading,
    error,
    refetch: refetchClientsData,
  } = useGetClientsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      search: filter?.search,
      amountOfVisits: filter?.amountOfVisits,
      filterByCompany: true,
      completedBookings: true,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const {data: oneClientData, refetch: refetchOneClient} = useGetOneClientQuery({
    variables: {
      id: deleteClientId,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId || !deleteClientId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const [deleteClient] = useDeleteClientMutation();

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  const debouncedRefetch = useCallback(
    debounce(() => {
      setIsLoadingRefetchClients(true);
      refetchClientsData().then((data) => {
        setIsLoadingRefetchClients(false);
      });
    }, 1000),
    [refetchClientsData]
  );

  useEffect(() => {
    if (currentCompanyId) {
      new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
        if (data.entity === ENTITY_IMPORT_TYPE) {
          debouncedRefetch()
        }
      })
    }
  }, [currentCompanyId, refetchClientsData]);

  const handleConfirmationClose = () => setShowDeleteConfirmation(false);
  const handleCannotDeleteClose = () => setShowCannotDelete(false);

  const handleDelete = async () => {
    try {
      await deleteClient({
        variables: {id: deleteClientId, companyId: +currentCompanyId},
        context: {
          errorType: 'global'
        },
      });
      setShowDeleteConfirmation(false);
      refetchClientsData();
      toggleShowToast(t('Deleted'))
    } catch (error) {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  };

  const handleShowModalDelete = async (id: number) => {
    id && setDeleteClientId(id);
    const {data} = id && (await refetchOneClient({id: id}));
    const client = data?.user?.company?.clients?.clients[0];
    const countBookings = client?.bookings?.totalCount;

    if (countBookings < 1) {
      // If the client has no visits
      setShowDeleteConfirmation(true);
    } else {
      // If the client has visits
      setShowCannotDelete(true);
    }
  };

  const handleEditClient = (id: number) => {
    navigate(`/clients/${currentCompanyId}/list/${id}/edit`);
  };

  const clients = clientsData?.user?.company?.clients?.clients;
  const pageCount = parseInt(clientsData?.user?.company?.clients?.pageCount || '0');
  const totalItems = clientsData?.user?.company?.clients?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
  const isFilterNotEmpty = Object.values(filter).some(value => value !== '')

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading || isLoadingRefetchClients) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {!!clients?.length ? (
          <table className='table'>
            <thead>
            <tr className='fs-7'>
              <th className='py-3 col-3 ps-4 align-middle'>{t('Name')}</th>
              <th className='py-3 col-2 align-middle'>{t('phone')}</th>
              <th className='py-3 col-1 align-middle'>Email</th>
              <th className='py-3 col-1 align-middle'>{t('Sold Out')}</th>
              <th className='py-3 col-1 align-middle'>{t('Visits')}</th>
              <th className='py-3 col-1 align-middle '>{t('Last Visit')}</th>
              <th className='py-3 col-1 align-middle '>{t('First visit')}</th>
              {!isEmployeeRole ? <th className='col-2'></th> : null}
            </tr>
            </thead>
            <tbody>
            {clients.map((client: ClientProxy) => (
              <tr key={client?.id}>
                <td className='align-middle text-primary py-4 ps-4'>
                  <Link
                    to={`/clients/${currentCompanyId}/list/${client?.id}/edit`}
                    style={{textDecoration: 'none'}}>
                    {client?.name} {client?.surname ? client?.surname : null}
                  </Link>
                </td>
                <td className='align-middle py-4'>{client?.phone ? client?.phone : '-'}</td>
                <td className='align-middle py-4'>{client?.email ? client?.email : '-'}</td>
                <td className='align-middle py-4'>
                  {client.bookings.bookings.reduce((sum, booking) => sum + booking.price, 0)} {currencySing}
                </td>
                <td className='align-middle text-primary py-4'>
                  <Link
                    to={{
                      pathname: '/clients-history-visit',
                      search: `?clientId=${client?.id}`,
                    }}
                  >
                    {client?.bookings?.totalCount}
                  </Link>
                </td>
                <td className='align-middle py-4'>
                  {client?.bookings?.bookings?.length > 0
                    ? DateTime.fromISO(client?.bookings?.bookings[0]?.date).toFormat('dd.MM.yyyy')
                    : '-'}
                </td>
                <td className='align-middle py-4'>
                  {client?.bookings?.bookings?.length > 0
                    ? DateTime.fromISO(
                      client.bookings.bookings
                        .filter((booking) => [6, 7].includes(booking?.status?.id))
                        .map((booking) => booking?.date)
                        .slice(-1)[0]
                    ).toFormat('dd.MM.yyyy')
                    : '-'}
                </td>
                {!isEmployeeRole
                  ?
                    <td className='align-middle text-end pe-0 py-4'>
                      <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                        <TooltipCustom placement='bottom' text={t('Edit client')}>
                          <Button
                              className='btn btn-square btn-sm p-2'
                              style={{width: '39px'}}
                              variant='outline-secondary'
                              onClick={() => handleEditClient(client?.id)}>
                            <i className='bi bi-pencil text-dark'></i>
                          </Button>
                        </TooltipCustom>
                        <TooltipCustom placement='bottom' text={t('Delete the client')}>
                          <Button
                              className='btn btn-sm btn-square p-2'
                              style={{width: '39px'}}
                              variant='outline-secondary'
                              onClick={() => handleShowModalDelete(client?.id)}>
                            <i className='bi bi-trash3 text-dark'></i>
                          </Button>
                        </TooltipCustom>
                      </div>
                    </td>
                  : null
                }
              </tr>
            ))}
            </tbody>
          </table>
          ) :
          <RenderEmptyList
            isFilterNotEmpty={isFilterNotEmpty}
            emptyFilterTitle='No data found for the given values'
            emptyListTitle='Client base is empty'
            onClickButton={() => navigate(`/clients/${currentCompanyId}/new`)}
            buttonName={
              <span>
                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                {t('Add client')}
              </span>
            }
          />
        }
      </div>

      {totalItems > 0 && (
        <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
          <Pagination
            t={t}
            pages={Number(pageCount)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            totalItems={totalItems}
          />
        </div>
      )}

      <ModalDelete
        show={showDeleteConfirmation}
        handleClose={handleConfirmationClose}
        title={t('Deleting a client')}
        body={t('Are you sure you want to delete the client?')}
        buttons={[
          {
            text: t('cancel'),
            onClick: handleConfirmationClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('Delete'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          },
        ]}
      />

      <ModalDelete
        show={showCannotDelete}
        handleClose={handleCannotDeleteClose}
        title={t('Unable to delete client')}
        variantTitle='text-danger'
        body={t(
          'You cannot delete the selected client. This customer is on record for the service'
        )}
        buttons={[
          {
            text: t('ok'),
            onClick: handleCannotDeleteClose,
            variant: 'danger',
            className: 'w-100',
          },
        ]}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
      { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
    </>
  );
};

export default ClientsTable;
