import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form, ErrorMessage, FormikHelpers} from 'formik';
import {useMatch, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Button} from 'react-bootstrap';
import {
  createServiceFormValidationSchema,
  SetFieldValueType
} from './constants';
import {ApolloError, useReactiveVar} from '@apollo/client';
import InputWithCounter from './inputs/TextareaInput';
import TextInputWithCounter from './inputs/TextInputWithCounter';
import CheckBox from './inputs/CheckBox';
import {DurationInput, Spinner} from '@services/ui-components';
import PriceInput from './inputs/PriceInput';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../../services/ErrorService';
import {ServiceModalWrapper} from '@me-pos/service-modal';
import ImageCrop from "@services/ui-components/src/ImageCrop/ImageCrop";
import {
  useCreateServCategoryMutation,
  useGetCategoryServQuery,
  useGetOneServiceFromServicesListLazyQuery,
  useGetOneServiceQuery,
  useUpdateServiceMutation
} from '@me-team/host/main/services/graphql/services.hooks';
import {ServiceCategoryNewProxy, ServiceInput} from '@me-team/host/main/graphql/types';
import CategoryService from './utils/CategoryService';
import RequestInputService from './utils/RequestInputService';
import {CategoryInterface, VariablesForCreateCategoryForm} from './type';
import categoryService from './utils/CategoryService';
import {normalizeCategoryData} from './utils/normalizeCategoryData';
import {currentCompanyIdVar} from '@me-team/host/main/globalVar/state';

export interface InitValues {
  name: string;
  category: number;
  price: number;
  duration: string;
  onlineBooking?: boolean;
  description?: string;
  deleted: boolean;
  image?:  File | null;
  isUpdateImage?: boolean;
}

const UpdateRecoveryServiceForm: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const buttonCategoryRef = useRef<HTMLButtonElement>(null);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const isEdit = useMatch(`/services/service/${currentCompanyId}/service-list/${id}/edit`);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>(null)
  const [categoryInput, setCategoryInput] =
    useState(null);
  const [pickedCategoryId, setPickedCategoryId] = useState<number>(null);

  const {
    data: oneServiceData,
    loading
  } = useGetOneServiceQuery({
    variables: {
      id: +id,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const [getService, {data}] = useGetOneServiceFromServicesListLazyQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const service = oneServiceData?.user?.company?.services?.services[0];

  const startValueCategory = service?.serviceCategoryNew?.parentCategory?.id
    ?
    service?.serviceCategoryNew?.parentCategory?.id
    :
    service?.serviceCategoryNew?.id

  const startValueSubCategory = service?.serviceCategoryNew?.parentCategory?.id
    ?
    service?.serviceCategoryNew?.id
    :
    null

  const categoryName = service?.serviceCategoryNew?.parentCategory?.name
    ?
    service?.serviceCategoryNew?.parentCategory?.name
    :
    service?.serviceCategoryNew?.name

  const SubCategoryName = service?.serviceCategoryNew?.parentCategory?.name
    ?
    service?.serviceCategoryNew?.name
    :
    null

  const [
    currentName,
    setCurrentName
  ] = useState({
    categoryName: '',
    subCategoryName: '',
  });

  const [
    categoryText,
    setCategoryText
  ] = useState([categoryName, SubCategoryName]);

  const [updateService] = useUpdateServiceMutation();
  const [createCategoryService] = useCreateServCategoryMutation()

  const handleSubmitCreate = async (variables: VariablesForCreateCategoryForm) => {
    try {
      await createCategoryService({
        variables,
        context: {
          errorType: 'local',
        },
        onCompleted: (data) => {
          data && setCurrentCategory(data.createServiceCategoryNew);
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error));
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const {data: serviceCategoriesData, refetch: refetchCategories, loading: loadingServiceCategories} = useGetCategoryServQuery( {
    variables: {id: null, categoryName: null, serviceDeleted: true, page: 1, itemsPerPage: 1000},
    context: {
      errorType: 'local',
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);
      setCategoryList(categoryService.categoryModuleCreator(normalizedItems, startValueCategory, startValueSubCategory))
      setCategoryInput(RequestInputService.createServiceCategoryInput(startValueCategory, startValueSubCategory, normalizedItems));
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const categoryListData: ServiceCategoryNewProxy[] = normalizeCategoryData(serviceCategoriesData)

  const handlePhoto = (setFieldValue: SetFieldValueType) => {
    setFieldValue('isUpdateImage', true);
  }

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (buttonCategoryRef.current) {
      buttonCategoryRef.current.focus();
    }
  };

  const handleCategoryNameChange = (categoryName: string) => {
    setCurrentName(prevState => ({
      ...prevState,
      categoryName: categoryName,
      subCategoryName: '',
    }));
    setCategoryText([categoryName]);
  };

  const handleSubCategoryNameChange = (  subCategoryName: string, categoryName: string ) => {
    setCurrentName((prevState) => ({
      ...prevState,
      categoryName: categoryName,
      subCategoryName: subCategoryName,
    }));
    setCategoryText([categoryName, subCategoryName]);
  };

  const initialValues: InitValues = {
    name: service?.name ? service?.name : '',
    category: service?.serviceCategoryNew?.id && service?.serviceCategoryNew?.id,
    duration: service?.duration ? service?.duration : '',
    price: service?.price ? service?.price : 0,
    onlineBooking: service?.onlineRegistration ? service?.onlineRegistration : false,
    description: service?.description ? service?.description : '',
    deleted: service?.deleted ? service?.deleted : false,
  };

  const handleUpdateSubmit = async (
    values: InitValues,
    {setSubmitting, setErrors}: FormikHelpers<any>
  ) => {
    const categoryIdValue = values?.category
    const input: ServiceInput = {
      company: +currentCompanyId,
      name: values?.name,
      category: categoryIdValue && categoryIdValue,
      duration: values?.duration,
      price: values?.price,
      onlineBooking: values?.onlineBooking,
      deleted: false,
      description: values?.description,
      isUpdateImage: true,
      image: values?.image ? values?.image : null,
    };
    await updateService({
      variables: {id: +id, input, companyId: +currentCompanyId},
      skip: !currentCompanyId,
      context: {
        errorType: 'local',
      },
      onCompleted: (data) => {
        data && navigate(`/services/service/${currentCompanyId}/service-list`, {
          state: {
            text: isEdit ? t('Saved') : t('Restored'),
            toast: true
          }
        });
        setSubmitting(false);
      },
      onError: (error: ApolloError) => {
        const graphQLErrors = error?.graphQLErrors[0]?.extensions?.category
        if (graphQLErrors === "service.exists") {
          setErrors({
            name: t('A service with this name and category already exists.'),
          });
        }
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    });
  };

  if (loading || loadingServiceCategories) return <Spinner/>;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={createServiceFormValidationSchema(t)}
        onSubmit={handleUpdateSubmit}>
        {({
            isSubmitting,
            setFieldValue,
            touched,
            errors
          }) => {
          useEffect(() => {
            pickedCategoryId && setFieldValue('category', pickedCategoryId);
          }, [pickedCategoryId]);

          return (
            <Form>
              <Row className='flex-column-reverse flex-lg-row mb-5'>
                <Col
                  lg={8} xxl={9}
                  className='pe-xl-5'>
                  <h4 className='mb-3'>{t('Information')}</h4>
                  <Row className='mb-4'>
                    <TextInputWithCounter
                      id='updateService-name-field'
                      label={t('Service name')}
                      name='name'
                      placeholder={t('Enter a service name')}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <Col
                      md={2}
                      className='mb-2 mb-md-0'>
                      <label
                        htmlFor='updateService-category-field'
                        className='text-dark fs-7 fw-bold py-1'
                        onClick={handleLabelClick}
                      >
                        {t('category')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <div>
                        <span
                          className={`${categoryText[0] ? 'me-4' : 'd-none'}  mb-2 mb-lg-0 text-dark`}
                        >
                          {categoryText[0]} {categoryText[1] && (
                            <>
                              <i className="bi bi-arrow-right"></i> {categoryText[1]}
                            </>
                          )}
                        </span>
                        <Button
                          ref={buttonCategoryRef}
                          variant={`${touched.category && errors.category ? 'outline-danger' : 'outline-primary'}`}
                          id='updateService-category-field'                          className='w-100 fw-normal py-1'
                          style={{maxWidth: '149px'}}
                          onClick={() => {setIsServiceModalOpen(true)}}
                        >
                          {categoryText[0] ? t('Change Category') : t('Select Category')}
                        </Button>
                        <ErrorMessage name="category">{msg => <div
                          className='text-danger fs-7 mt-1'>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <InputWithCounter
                      id='updateService-description-field'
                      label={t('description')}
                      name='description'
                      placeholder={t('Add a short description of the service')}
                    />
                  </Row>
                  <Row className='mb-4 durationWrapper'>
                    <DurationInput
                      id='updateService-duration-field'
                      name={'duration'}
                      label={t('Duration')}
                      prevValue={service?.duration && service?.duration.slice(0, 5)}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <PriceInput
                      id='updateService-price-field'
                      name='price'
                      placeholder='0.00'
                      initialValue={service?.price && service?.price}
                    />
                  </Row>
                  <Row className='flex align-items-center'>
                    <CheckBox
                      id='updateService-onlineBooking-field'
                      name='onlineBooking'
                      label={t('Online Registration')}
                      value={service?.onlineRegistration && service?.onlineRegistration}
                    />
                  </Row>
                </Col>
                <Col lg={4} xxl={3}>
                  <div
                    className='d-flex mb-3 w-100 flex-column justify-content-lg-end'>
                    <h4 className='mb-3 w-100'>
                      {t('Services Photo')}
                    </h4>
                    <ImageCrop
                      previewPhoto={service?.image}
                      setFieldValue={setFieldValue}
                      aspectProp={2.1 / 1}
                      fieldSetter={() => handlePhoto(setFieldValue)}
                      fieldName="image"
                      dismissImage={() => setFieldValue('isUpdateImage', true)}
                      modalTitle={t('Services Photo')}
                      notBGinfo={true}
                    />
                    <ErrorMessage
                      name='image'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
              </Row>
              <Row className='mt-2 mt-lg-5'>
                <Col lg={4} className='d-flex gap-4'>
                  <Button
                    variant='outline-primary'
                    className='custom-btn'
                    onClick={() => navigate(`/services/service/${currentCompanyId}/service-list`)}>{t('cancel')}
                  </Button>
                  {isEdit ?
                    <Button
                      variant='primary'
                      type='submit'
                      className='custom-btn'
                    >
                      {t('Save')}
                    </Button>
                    :
                    <Button
                      variant='primary'
                      type='submit'
                      className='custom-btn'
                    >
                      {t('Restore')}
                    </Button>
                  }
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      {
        isServiceModalOpen && serviceCategoriesData && !loadingServiceCategories ?
          <ServiceModalWrapper
            i18n={i18next}
            selectedCategoryId={startValueCategory}
            selectedSubcategoryId={startValueSubCategory}
            apolloUrl={process.env.REACT_APP_API_URL}
            isOpen={isServiceModalOpen}
            onClose={() => {
              setIsServiceModalOpen(!isServiceModalOpen)
            }}
            onCategorySelect={(selectedCategory) => {
              handleCategoryNameChange(selectedCategory?.name);
              setPickedCategoryId(selectedCategory?.id)
              setIsServiceModalOpen(!isServiceModalOpen)
            }}
            onSubcategorySelect={(selectedSubcategory) => {
              handleSubCategoryNameChange(
                selectedSubcategory?.name,
                selectedSubcategory?.parentCategory?.name
              );
              setPickedCategoryId(selectedSubcategory?.id)
              setIsServiceModalOpen(!isServiceModalOpen)

            }}
            categoryList={categoryList}
            categoryInput={categoryInput}
            categoryListData={categoryListData}
            refetchCategories={refetchCategories}
            onSubmitCreate={handleSubmitCreate}
            currentCategory={currentCategory}
            categoryService={CategoryService}
            requestInputService={RequestInputService}
            normalizeCategoryData={normalizeCategoryData}
          />
          : null
      }
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default UpdateRecoveryServiceForm;
