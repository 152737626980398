import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import {useReactiveVar} from "@apollo/client";
import {TooltipPopover} from "@services/ui-components";
import {useTranslation} from "react-i18next";
import {EmployeeServiceRelationProxy, ServiceProxy} from '@me-team/host/main/graphql/types';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/main/globalVar/state';

type PriceComponentProps = {
    service: ServiceProxy;
    updateEmployeeServices: (updatedService: EmployeeServiceRelationProxy) => void;
    currencySign: string;
    index?: number
};

const PriceComponent: React.FC<PriceComponentProps> = ({ service, updateEmployeeServices, currencySign, index }) => {
    const userRole = useReactiveVar(currentUserRoleVar);
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const [price, setPrice] = useState<string | number>(service.employeeServiceRelations[0]?.price || '');
    const [error, setError] = useState<string | null>(null);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const updatePrice = (i: ServiceProxy, target: string) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            const updatedPrice = parseFloat(target);
            if (!isNaN(updatedPrice)) {
                updateEmployeeServices({ ...i.employeeServiceRelations[0], price: updatedPrice });
            }
        }, 1300);
    };
    const validateSalary = (salary: string): boolean => {
        if (+salary < service.employeeServiceRelations[0]?.salaryValue && service?.employeeServiceRelations[0]?.salaryType === 2 ) {
            setError(t('The price cannot be less than the salary'))
            return false
        } else {
            setError(null)
            return true
        }
    }
    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(',', '.');
        const regex = /^\d{0,6}(\.\d{0,2})?$/;
        if (regex.test(inputValue) || inputValue === '') {
            let numericValue = parseFloat(inputValue);
            if (inputValue === '' || numericValue < 0.01) {
                inputValue = '0.01';
                numericValue = 0.01;
            }
            if (numericValue > 999999.99) {
                inputValue = '999999.99';
                numericValue = 999999.99;
            }
            setPrice(inputValue);
            const validation = validateSalary(inputValue)
            if (validation && numericValue >= 0.01 && numericValue <= 999999.99) {
                updatePrice(service, inputValue);
            }
        }
    }

    const getMaxWidth = (value: string) => {
        return value.length > 6 ? '100px' : '90px';
    };

    return (
        <>
            <div className="position-relative" style={{ width: getMaxWidth(price.toString()) }}>
                <TooltipPopover
                    placement="bottom"
                    text={
                        <div className="d-flex align-items-center">
                            <i className="bi bi-exclamation-square-fill text-orange me-2 fs-5"></i>
                            <span className="fs-7 text-dark">{error}</span>
                        </div>
                    }
                    styleBdy="py-1 px-2"
                    show={!!error}
                    maxWidth="500px"
                    trigger="click"
                    zIndex={1}
                >

                    <Form.Group className="mb-3" >
                        <Form.Control
                            id={`priceComponent-${index}-field`}
                            size="sm"
                            onChange={handlePriceChange}
                            placeholder=""
                            value={price}
                            disabled={userRole === 5}
                            contenteditable="true"
                        />
                    </Form.Group>
                </TooltipPopover>

                <div className='position-absolute top-50 end-0 translate-middle-y '>
                    <i style={{ paddingRight: '12px' }}>
                        {currencySign}
                    </i>
                </div>
            </div>
        </>
    );
};

export default PriceComponent;