import React, {useCallback, useEffect, useState} from 'react';
import {InfoBanner, TableHeader} from '@services/ui-components';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import OnlineBookingSettingsTable from './OnlineBookingSettingsTable';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useGetModulesListQuery} from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import {useUpdateCompanyModuleRelationMutation} from '@me-team/host/main/clients/graphql/clients.hooks';
import {UserRole} from '../../utils/enums';
import Button from "react-bootstrap/Button";
import FacebookPixel from "./FacebookPixel/FacebookPixel";
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/main/globalVar/state';

const OnlineBookingSettings: React.FC = () => {
    const {t} = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userRole = useReactiveVar(currentUserRoleVar);
    const isEmployeeRole = +userRole === UserRole.EMPLOYEE
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const {data: modulesList} = useGetModulesListQuery()
    const [updateCompanyModuleRelation] = useUpdateCompanyModuleRelationMutation()
    const onlineBookingModuleId = modulesList?.relations?.modules.find(module => module.name === "Online Booking");
    const [isPixelModalOpen, setIsPixelModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (onlineBookingModuleId && !isEmployeeRole) {
            updateCompanyModuleRelation({
                variables: {
                    companyId: currentCompanyId && +currentCompanyId,
                    input: {
                        id: onlineBookingModuleId && +onlineBookingModuleId?.id,
                        isActive: true
                    }
                },
                context: {
                    errorType: 'local'
                },
                onError: (error: ApolloError) => {
                    setIsErrorModalOpen(ErrorService.errorHandling(error))
                },
            })
        }
    }, [onlineBookingModuleId, currentCompanyId]);

    const onOpenPixelModalHandler = () => {
        setIsPixelModalOpen(true)
    }
    const onClosePixelModalHandler = useCallback(() => {
        setIsPixelModalOpen(false);
    },[])

    return (
        <div className='mb-5 px-2 px-xl-0 pb-5'>
            <InfoBanner>
                <p
                    className='fs-7 mb-0'>{t('On this page, you can enable or disable online registration for your company as a whole, or set it up separately for each of the branches, as well as copy the URL or download the QR code to post it for your customers.')}
                </p>
            </InfoBanner>
            <TableHeader
                title={t('Online Booking Settings')}
                paths={[
                    {path: `/booking/company/${currentCompanyId}/config`, label: t('Online Booking')},
                    {
                        path: `/booking/company/${currentCompanyId}/config`, label: t('Online Booking Settings')
                    },
                ]}
                colSize={7}
                btnColSize={5}
                buttons={
                    userRole !== UserRole.EMPLOYEE && <Row className='gx-3 justify-content-end mt-4 mb-2'>
                        <Col xs={12} sm={12} lg={7} xl={6} xxl={5}>
                            <Button className="btn btn-primary w-100 fw-bold px-2 text-truncate"
                                    onClick={onOpenPixelModalHandler}>
                                {t('Facebook Pixel Code')}
                            </Button>
                        </Col>
                    </Row>
                }
            >
            </TableHeader>
            <Row>
                <Col>
                    <span className='fs-4'>{t('Main Settings')}</span>
                </Col>
            </Row>
            <OnlineBookingSettingsTable/>
            {
                isErrorModalOpen ?
                    <ErrorModalComponent
                        i18n={i18next}
                        onClose={() => {
                            setIsErrorModalOpen(null)
                        }}
                        isOpen={!!isErrorModalOpen}
                        currentError={isErrorModalOpen}
                    /> : null
            }

            {isPixelModalOpen && <FacebookPixel
                isPixelModalOpen={isPixelModalOpen}
                onClose={onClosePixelModalHandler}
            />}

        </div>

    );
};

export default OnlineBookingSettings;
