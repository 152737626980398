import { makeVar } from '@apollo/client';

export const userIdVar = makeVar<string | null>(null);
export const userUsernameVar = makeVar<string | null>(null);
export const currentBranchIdVar = makeVar<string | number | null>(null);
export const currentCompanyIdVar = makeVar<string | null>(null);
export const currentUserRoleVar = makeVar<string | number | null>(null);
export const currentUserEmailVar = makeVar<string | null>(null);
export const currencySingVar = makeVar<string>("");
export const userCompaniesCountVar = makeVar<number>(0);
export const currentBranchEmployeeIdVar = makeVar<number | undefined>(undefined);